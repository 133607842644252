import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HealthCheckService {
    uri = environment.baseUrl + '/quickcheck';

    constructor(private readonly http: HttpClient) { }

    public checkHealth() {
        return this.http.get(this.uri,
            { responseType: 'text' });
    }
}
