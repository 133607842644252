import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Injectable } from '@angular/core';
import { ApplicationFieldCompare } from '../../../../core/models/application-field/application-field-сompare.model';
import {
    selectApplicationFieldItemCompareById,
    selectApplicationFieldItemCompares
} from './application-field-item-compares.selector';
import { ApplicationFieldItemComparesActions } from '../../../../core/state/action-types/action-types';
import { ApplicationFieldCompareItem } from '../../../../core/models/application-field/application-field-compare-item.model';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFieldItemComparesFacade {
    public applicationFieldItemCompares$: Observable<Array<ApplicationFieldCompare>>;

    constructor(private store: Store<AppState>) {
        this.applicationFieldItemCompares$ = store.select(selectApplicationFieldItemCompares);
        this.loadApplicationFieldItemCompares();
    }

    private loadApplicationFieldItemCompares(): void {
        this.store.dispatch(ApplicationFieldItemComparesActions.loadApplicationFieldItemCompares());
    }

    public createApplicationFieldItemCompare(
        applicationFieldItemCompare: ApplicationFieldCompare,
        applicationFieldItemCompareItems: Array<ApplicationFieldCompareItem>
    ): void {
        this.store.dispatch(
            ApplicationFieldItemComparesActions.createApplicationFieldItemCompare({
                applicationFieldItemCompare,
                applicationFieldItemCompareItems
            })
        );
    }

    public updateApplicationFieldItemCompare(applicationFieldItemCompare: ApplicationFieldCompare): void {
        this.store.dispatch(
            ApplicationFieldItemComparesActions.updateApplicationFieldItemCompare({ applicationFieldItemCompare })
        );
    }

    public selectApplicationFieldItemComparesById(id: number): Observable<ApplicationFieldCompare> {
        return this.store.select(selectApplicationFieldItemCompareById(id));
    }
}
