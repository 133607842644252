import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  DoCheck,
  Input,
  OnDestroy,
  OnInit,
  Self,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounce, filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: 'ng-template[appAutocompleteOption]'
})
export class AutocompleteOptionDirective<T> {
  constructor(public ref: TemplateRef<{ $implicit: T }>) {
  }
}

type DisplayFn<T> = (obj: T) => string;

export type AutocompleteDataGetter<T> = (searchValue: string) => Observable<T[]>;

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent<T> implements OnInit, OnDestroy, DoCheck, ControlValueAccessor {

  @ContentChild(AutocompleteOptionDirective)
  public autocompleteOption?: AutocompleteOptionDirective<T>;

  @Input()
  public label?: string;

  @Input()
  public placeholder?: string;

  @Input()
  public defaultValue?: boolean;

  @Input()
  public debounceTime = 500;

  @Input()
  public dataGetter: AutocompleteDataGetter<T>;

  @Input()
  public optionDisplay?: keyof T | DisplayFn<T>;

  @Input()
  public inputDisplay?: keyof T | DisplayFn<T>;

  public autocompleteFilterControl = new FormControl<string | null>(null);
  public options$: Observable<T[]> = this.autocompleteFilterControl.valueChanges.pipe(
    startWith(this.autocompleteFilterControl.value),
    filter(val => val === null || typeof val === 'string'),
    debounce(() => timer(this.debounceTime)),
    tap(() => this.loading$.next(true)),
    switchMap(val => this.dataGetter(val)),
    tap(() => this.loading$.next(false)),
  );
  public loading$ = new BehaviorSubject(false);

  private selectedOptionControl = new FormControl<T | null>(null);

  private onTouched: () => void = () => null;

  private destroyed$ = new Subject();

  constructor(
    @Self() private ngControl: NgControl,
  ) {
    this.ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    const control = this.ngControl.control;
    this.selectedOptionControl.setValidators(control.validator);
    this.selectedOptionControl.updateValueAndValidity();
  }

  public ngDoCheck(): void {
    this.autocompleteFilterControl.setErrors(this.selectedOptionControl.errors);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public writeValue(obj: any): void {
    if (obj === null) {
      this.selectedOptionControl.reset();
    } else if (obj !== undefined) {
      this.selectedOptionControl.setValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.selectedOptionControl.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.autocompleteFilterControl.disable({ emitEvent: false })
      : this.autocompleteFilterControl.enable({ emitEvent: false });
  }

  public displayOption(option: T): string {
    if (this.optionDisplay == null) {
      return option.toString();
    }

    if (typeof this.optionDisplay === 'function') {
      return this.optionDisplay(option);
    }

    return option[this.optionDisplay].toString();
  }

  public displayInput = (value: T | null) => {
    if (value == null) {
      if (this.defaultValue && this.selectedOptionControl?.value) {
        return this.selectedOptionControl?.value[this.inputDisplay?.toString()].toString();
      }
      return null;
    }

    if (this.inputDisplay == null) {
      return value.toString();
    }

    if (typeof this.inputDisplay === 'function') {
      return this.inputDisplay(value);
    }

    return value[this.inputDisplay].toString();
  };

  public onOptionSelected(option: T): void {
    this.selectedOptionControl.setValue(option);
  }

  public onBlur(): void {
    this.onTouched();
  }

  public onInputChange(): void {
    this.selectedOptionControl.setValue(null);
  }
}
