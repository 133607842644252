import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user/user-profile.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private refreshTokenTimeout;

    constructor(private http: HttpClient) {}

    public login(email: string, password: string): Observable<UserProfile> {
        return this.http.post<UserProfile>(
            `${environment.apiUrl}/userProfile/login`,
            { email, password },
            { withCredentials: true }
        );
    }

    public logout(): Observable<void> {
        return this.http.post<any>(`${environment.apiUrl}/userProfile/revoke-token`, {}, { withCredentials: true });
    }

    public refreshToken(): Observable<UserProfile> {
        return this.http.post<UserProfile>(`${environment.apiUrl}/userProfile/refresh-token`, {}, { withCredentials: true });
    }

    // helper methods
    public startRefreshTokenTimer(user: UserProfile): void {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    public stopRefreshTokenTimer(): void {
        clearTimeout(this.refreshTokenTimeout);
    }
}
