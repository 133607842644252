import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AppState } from '../../store';
import { select, Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { UserProfile } from '../../core/models/user/user-profile.model';
import { Permissions } from '../../core/authorization/permissions';
import { selectCurrentUser } from '../../components/user-profile/state/current-user.selectors';
import { SelectionModel } from '@angular/cdk/collections';
import { MenuType } from '../../core/enums/menu-type';
import { AppConstants } from 'src/app/core/app-constants';
import { SnackBarDataService } from 'src/app/core/services/snack-bar-data/snack-bar-data.service';
import { MatDialog } from '@angular/material/dialog';
import { IFrameDialogComponent } from 'src/app/shared/components/i-frame-dialog/i-frame-dialog.component';
import { first, shareReplay, takeUntil } from 'rxjs/operators';
import { AdminControlCode } from 'src/app/core/enums/admin-control-code.enum';
import { AdminControlsFacade } from 'src/app/components/admin-panel/state/admin-controls/admin-controls.facade';
import { PartnerEngagementPagesService } from 'src/app/core/services/partner-engagement-pages/partner-engagement-pages.service';
import { PartnerEngagementPage } from 'src/app/core/models/partner-engagement-page/partner-engagement-page.model';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit {
    public user$: Observable<UserProfile>;
    public menuSelection = new SelectionModel<MenuType>(false, [MenuType.User]);
    public pages$: Observable<Array<PartnerEngagementPage>>;
    public newOI: string;
    @Input() public expandMainSidenav: boolean;

    public get permissions(): typeof Permissions {
        return Permissions;
    }

    public get menuType(): typeof MenuType {
        return MenuType;
    }

    constructor(private store: Store<AppState>,
        private snackBarDataService: SnackBarDataService,
        private adminControlsFacade: AdminControlsFacade,
        private partnerEngagementPagesService: PartnerEngagementPagesService, 
        private dialog: MatDialog) { }

    ngOnInit(): void {
        this.adminControlsFacade
            .selectAdminControlValueByCode(AdminControlCode.NewOI)
            .pipe(first((adminControl) => !!adminControl))
            .subscribe((newOI) => {
                this.newOI = newOI
            });
        this.user$ = this.store.pipe(select(selectCurrentUser));
        this.pages$ = this.partnerEngagementPagesService.getAvailablePartnerEngagementPages().pipe(
            shareReplay(1),
        )
        this.pages$.subscribe()
    }

    public showMenuItem(user: UserProfile, requiredPermission: string): boolean {
        return user?.roles.some((role) => role.permissions.some((permissions) => permissions === requiredPermission));
    }

    public openIFrameDialog(title: string, subtitle: string = ''): void {
        if (!this.newOI) {
            this.snackBarDataService.showSnackBar('highlight_off', `There is no URL for "${title}"`, AppConstants.ERROR_COLOR);
            return;
        }
        const src = this.newOI;
        this.dialog.open(IFrameDialogComponent, {
            width: '90%',
            height: '90vh',
            panelClass: 'custom-dialog-container',
            data: {
                title,
                subtitle,
                src
            }
        });
    }
}
