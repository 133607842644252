import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Injectable } from '@angular/core';
import { selectApplicationFieldItemXlatItems, selectLoaded, selectLoading } from './application-field-item-xlat-items.selector';
import { ApplicationFieldXlatItem } from '../../../../core/models/application-field/application-field-xlat-item.model';
import { ApplicationFieldItemXlatItemsActions } from '../../../../core/state/action-types/action-types';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFieldItemXlatItemsFacade {
    public applicationFieldItemXlatItems$: Observable<Array<ApplicationFieldXlatItem>>;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;

    constructor(private store: Store<AppState>) {
        this.loading$ = this.store.select(selectLoading);
        this.loaded$ = this.store.select(selectLoaded);
    }

    public loadApplicationFieldItemXlatItems(id: number): void {
        this.applicationFieldItemXlatItems$ = this.store.select(selectApplicationFieldItemXlatItems(id));
        this.store.dispatch(ApplicationFieldItemXlatItemsActions.loadApplicationFieldItemXlatItems({ id }));
    }

    public upsertApplicationFieldItemXlatItems(applicationFieldItemXlatItems: Array<ApplicationFieldXlatItem>): void {
        this.store.dispatch(
            ApplicationFieldItemXlatItemsActions.upsertApplicationFieldItemXlatItems({ applicationFieldItemXlatItems })
        );
    }

    public deleteApplicationFieldItemXlatItems(id: number): void {
        this.store.dispatch(ApplicationFieldItemXlatItemsActions.deleteApplicationFieldItemXlatItems({ id }));
    }
}
