import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { AuthGuard } from './core/authentication/auth.guard';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { CurrentIntegrationGuard } from './core/guards/current-integration.guard';
import { IntegrationQuickStartGuard } from './core/guards/integration-quick-start.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'integration-search',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
    },
    {
        path: 'service-unavailable',
        loadChildren: () => import('./error-pages/error-pages.module').then((m) => m.ErrorPagesModule)
    },
    {
        path: '',
        component: ShellComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [AuthGuard],
        children: [
            {
                path: 'admin-panel',
                loadChildren: () => import('./components/admin-panel/admin-panel.module').then((m) => m.AdminPanelModule)
            },
            {
                path: 'data-map-fields',
                loadChildren: () =>
                    import('./components/data-map-fields/data-map-fields.module').then((m) => m.DataMapFieldsModule)
            },
            {
                path: 'dashboards',
                canActivate: [CurrentIntegrationGuard],
                loadChildren: () => import('./components/dashboards/dashboards.module').then((m) => m.DashboardsModule)
            },
            {
                path: 'partner-dashboard',
                loadChildren: () => import('./components/partner-dashboard/partner-dashboard.module').then(m => m.PartnerDashboardModule)
            },
            {
                path: 'partner-users',
                loadChildren: () => import('./components/partner-users/partner-users.module').then(m => m.PartnerUsersModule)
            },
            {
                path: 'event-notifications',
                loadChildren: () => import('./components/portal-event-notification/portal-event-notification.module').then(m => m.PortalEventNotificationModule)
            },
            {
                path: 'quick-start',
                canActivate: [IntegrationQuickStartGuard],
                loadChildren: () => import('./components/integration-quick-start/integration-quick-start.module')
                    .then(m => m.IntegrationQuickStartModule)
            },
            {
                path: 'change-logs',
                loadChildren: () => import('./components/change-logs/change-logs.module')
                    .then(m => m.ChangeLogsModule)
            },
            {
                path: 'application-fields',
                loadChildren: () =>
                    import('./components/application-fields/application-fields.module').then((m) => m.ApplicationFieldsModule)
            },
            {
                path: 'application-field-items',
                loadChildren: () =>
                    import('./components/application-field-items/application-field-items.module').then(
                        (m) => m.ApplicationFieldItemsModule
                    )
            },
            {
                path: 'integration-schedule',
                loadChildren: () =>
                    import('./components/integration-schedule/integration-schedule.module').then(
                        (m) => m.IntegrationScheduleModule
                    )
            },
            {
                path: 'connector-templates',
                loadChildren: () => import('./components/connectors/connectors.module').then((m) => m.ConnectorsModule)
            },
            {
                path: 'connector-clients',
                loadChildren: () =>
                    import('./components/connector-tenants/connector-tenants.module').then((m) => m.ConnectorTenantsModule)
            },
            {
                path: 'connector-user',
                loadChildren: () => import('./components/connector-user/connector-user.module').then(m => m.ConnectorUserModule)
            },
            {
                path: 'cloud-normals',
                loadChildren: () => import('./components/cloud-normals/cloud-normals.module').then((m) => m.CloudNormalsModule)
            },
            {
                path: 'integration-info',
                loadChildren: () =>
                    import('./components/integration-info/integration-info.module').then((m) => m.IntegrationInfoModule)
            },
            {
                path: 'integration-search',
                loadChildren: () =>
                    import('./components/integration-search/integration-search.module').then((m) => m.IntegrationSearchModule)
            },
            {
                path: 'triggers',
                loadChildren: () => import('./components/triggers/triggers.module').then((m) => m.TriggersModule)
            },
            {
                path: 'users',
                // canActivate: [AuthGuardService, AdminGuardService],
                loadChildren: () => import('./components/users/users.module').then((m) => m.UsersModule)
            },
            {
                path: 'user-profile',
                loadChildren: () => import('./components/user-profile/user-profile.module').then((m) => m.UserProfileModule)
            },
            {
                path: 'create-new-integration',
                loadChildren: () =>
                    import('./components/create-new-integration/create-new-integration.module').then(
                        (m) => m.CreateNewIntegrationModule
                    )
            },
            {
                path: 'create-partner-integration',
                loadChildren: () => import('./components/create-partner-integration/create-partner-integration.module').then(m => m.CreatePartnerIntegrationModule)
            },
            {
                path: 'rpp-master-records',
                loadChildren: () =>
                    import('./components/rpp-master-records/rpp-master-records.module').then(
                        (m) => m.RPPMasterRecordsModule
                    )
            },
            {
                path: 'provision-tenants',
                loadChildren: () =>
                    import('./components/provision-tenants/provision-tenants.module').then(
                        (m) => m.ProvisionTenantsModule
                    )
            },
            {
                path: 'partner-page',
                loadChildren: () =>
                    import('./components/partner-engagement-pages/partner-engagement-pages.module').then(
                        (m) => m.PartnerEngagementPagesModule
                    )
            },
            {
                path: 'status-marked-delivered',
                loadChildren: () =>
                    import('./components/status-marked-delivered/status-marked-delivered.module').then(
                        (m) => m.StatusMarkedDeliveredModule
                    )
            },
            {
                path: 'help-topics',
                loadChildren: () => import('./components/help-topics/help-topics.module').then((m) => m.HelpTopicsModule)
            },
            {
                path: 'help-topics-user',
                loadChildren: () => import('./components/help-topics-user/help-topics-user.module').then((m) => m.HelpTopicsUserModule)
            },
            {
                path: 'question-master',
                loadChildren: () =>
                    import('./components/question-master/question-master.module').then((m) => m.QuestionMasterModule)
            },
            {
                path: 'question-master-items',
                loadChildren: () =>
                    import('./components/question-master-items/question-master-items.module').then(
                        (m) => m.QuestionMasterItemsModule
                    )
            },
            {
                path: 'question-client',
                loadChildren: () =>
                    import('./components/question-client/question-client.module').then((m) => m.QuestionClientModule)
            },
            {
                path: 'question-client-items',
                loadChildren: () =>
                    import('./components/question-client-items/question-client-items.module').then(
                        (m) => m.QuestionClientItemsModule
                    )
            },
            {
                path: 'setup-questions',
                loadChildren: () =>
                    import('./components/setup-questions/setup-questions.module').then((m) => m.SetupQuestionsModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'integration-search'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
