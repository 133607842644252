import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

@Pipe({
    name: 'safeHTML'
})
export class SafeHTMLPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string): SafeResourceUrl {
        if (!value) {
            return value;
        }

        const modifiedHtml = value.replace(/<a(.*?)>/g, '<a$1 target="_blank">');
        return this.sanitizer.bypassSecurityTrustHtml(modifiedHtml);
    }
}
