import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { flatMap } from 'rxjs/internal/operators';
import { CurrentUserFacade } from '../../components/user-profile/state/current-user.facade';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private currentUserFacade: CurrentUserFacade) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.currentUserFacade.currentUser$.pipe(
            first(),
            flatMap((user) => {
                // add auth header with jwt if user is logged in and request is to the api url
                const isLoggedIn = user && user.token;
                const isApiUrl = request.url.startsWith(environment.apiUrl);

                if (isLoggedIn && isApiUrl) {
                    request = request.clone({
                        setHeaders: { Authorization: `Bearer ${user.token}` }
                    });
                }

                return next.handle(request);
            })
        );
    }
}
