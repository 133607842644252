import { createAction, props } from '@ngrx/store';
import { UserProfile } from '../../../core/models/user/user-profile.model';

export const logout = createAction('[Current User Facade] Logout');
export const logoutSuccess = createAction('[Current User Effect] Logout Success');

export const login = createAction(
  '[Current User Facade] User Login',
  props<{ email: string; password: string; returnUrl: string }>()
);
export const loginSuccess = createAction(
  '[Current User Effect] User Login Success',
  props<{ user: UserProfile; returnUrl: string }>()
);

export const updateUser = createAction('[Current User Facade] Update User', props<{ user: UserProfile }>());
export const updateUserSuccess = createAction('[Current User Effect] Update User Success', props<{ user: UserProfile }>());

export const changeEmail = createAction(
  '[Current User Facade] Change Email',
  props<{ currentEmail: string; newEmail: string; currentPassword: string }>()
);
export const changeEmailSuccess = createAction('[Current User Effect] Change Email Success', props<{ email: string }>());

export const changePassword = createAction(
  '[Current User Facade] Change Password',
  props<{ currentEmail: string; currentPassword: string; newPassword: string }>()
);
export const changePasswordSuccess = createAction('[Current User Effect] Change Password Success');

export const uploadUserImage = createAction(
  '[Current User Facade] Upload User Image',
  props<{ formFile: FormData; fileName: string; userId: string }>()
);

export const sendResetPasswordByEmail = createAction(
  '[Current User Facade] Send Reset Password By Email',
  props<{ email: string }>()
);
export const sendResetPasswordByEmailSuccess = createAction('[Current User Effect] Send Reset Password By Email Success');

export const resetPassword = createAction(
  '[Current User Facade] Reset Password',
  props<{ email: string; newPassword: string; token: string }>()
);
export const resetPasswordSuccess = createAction('[Current User Effect] Reset Password Success');

export const refreshToken = createAction('[Application Startup] Refresh Token');
export const refreshTokenSuccess = createAction('[Current User Effect] Refresh Token Success', props<{ user: UserProfile }>());
export const refreshTokenError = createAction('[Current User Effect] Refresh Token Error');

export const startRefreshTokenTimer = createAction(
  '[Current User Effect] Start Refresh Token Timer',
  props<{ user: UserProfile }>()
);

export const stopRefreshTokenTimer = createAction('[Current User Effect] Stop Refresh Token Timer');
