import { createAction, props } from '@ngrx/store';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { ConnectorTenantItem } from 'src/app/core/models/connector-tenant/connector-tenant-item/connector-tenant-item.model';

export const loadConnectorTenantItems = createAction(
    '[Connector Tenant Items Facade] Load Connector Tenant Items',
    props<{ id: number }>()
);
export const loadConnectorTenantItemsSuccess = createAction(
    '[Connector Tenant Items Effect] Load Connector Tenant Items Success',
    props<{ connectorTenantItems: Array<ConnectorTenantItem>; connectorTenantId: number }>()
);

export const upsertConnectorTenantItem = createAction(
    '[Connector Tenant Items Facade] Upsert Connector Tenant Item',
    props<{ connectorTenantItem: ConnectorTenantItem; entityType: EntityType }>()
);
export const upsertConnectorTenantItemSuccess = createAction(
    '[Connector Tenant Items Effect] Upsert Connector Tenant Item Success',
    props<{ connectorTenantItem: ConnectorTenantItem }>()
);

export const deleteConnectorTenantItem = createAction(
    '[Connector Tenant Items Facade] Delete Connector Tenant Item',
    props<{ connectorTenantItemId: number; entityType: EntityType}>()
);
export const deleteConnectorTenantItemSuccess = createAction(
    '[Connector Tenant Items Effects] Delete Connector Tenant Item Success',
    props<{ connectorTenantItemId: number }>()
);

export const updateConnectorTenantItemStatus = createAction(
    '[Connector Tenant Items Effects] Change status',
    props<{ connectorTenantItemId: number; entityType: EntityType}>()
);
