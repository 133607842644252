import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { DataMapField } from '../../../core/models/data-map-field/data-map-field.model';

export const loadDataMapFields = createAction('[Data Map Fields Facade] Load Data Map Fields', props<{ integrationId: number }>());
export const loadDataMapFieldsSuccess = createAction(
    '[Data Map Fields Effects] Load Data Map Fields  Success',
    props<{ dataMapFields: Array<DataMapField> }>()
);

export const loadDataMapFieldById = createAction('[Data Map Fields Facade] Load Data Map Field By Id', props<{ id: number }>());
export const loadDataMapFieldByIdSuccess = createAction(
    '[Data Map Fields Effect] Load Data Map Field By Id Success',
    props<{ dataMapField: DataMapField }>()
);

export const updateDataMapField = createAction(
    '[Data Map Fields Facade] Update Data Map Field',
    props<{ dataMapField: DataMapField }>()
);
export const updateDataMapFieldSuccess = createAction(
    '[Data Map Fields Effect] Update Data Map Field Success',
    props<{ dataMapField: DataMapField }>()
);

export const updateDataMapFields = createAction(
    '[Data Map Fields Facade] Update Data Map Fields',
    props<{ dataMapFields: Array<DataMapField> }>()
);
export const updateDataMapFieldsSuccess = createAction(
    '[Data Map Fields Effect] Update Data Map Fields Success',
    props<{ dataMapFields: Array<DataMapField> }>()
);

export const updateDataMapFieldFromTable = createAction(
    '[Data Map Fields Facade] Update Data Map Field From Table',
    props<{ dataMapField: DataMapField }>()
);
export const updateDataMapFieldFromTableSuccess = createAction(
    '[Data Map Fields Effect] Update Data Map Field From Table Success',
    props<{ dataMapField: Update<DataMapField> }>()
);

export const createDataMapField = createAction(
    '[Data Map Fields Effect] Create Data Map Field',
    props<{ dataMapField: DataMapField }>()
);
export const createDataMapFieldSuccess = createAction(
    '[Data Map Fields Effect] Create Data Map Field Success',
    props<{ dataMapField: DataMapField }>()
);

export const createDataMapFields = createAction(
    '[Integrations Effect] Create Data Map Fields',
    props<{ dataMapFields: Array<DataMapField>; redirect: boolean }>()
);
export const createDataMapFieldsSuccess = createAction(
    '[Data Map Fields Effect] Create Data Map Fields Success',
    props<{ redirect: boolean }>()
);
