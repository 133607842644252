<span
  cdkOverlayOrigin
  class="edit-value"
  #trigger="cdkOverlayOrigin"
>
  <span *ngIf="item | get:columnDef.field as value else empty">
    {{ value }}
  </span>

  <ng-template #empty>_</ng-template>
</span>

<button
  *ngIf="editIconVisible && !overlayOpen"
  mat-icon-button
  color="accent"
  (click)="openOverlay()"
>
  <mat-icon>border_color</mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="overlayOpen"
  (overlayOutsideClick)="closeOverlay()"
>
  <mat-card class="editable-card">
    <mat-card-content>
      <mat-form-field
        class="edit-field"
        appearance="fill"
      >
        <textarea
          matInput
          appHotkeyComment
          cdkTextareaAutosize
          cdkAutosizeMaxRows="5"
          [formControl]="control"
          (keydown.enter)="$event.preventDefault(); handleSave()"
        ></textarea>
        <mat-spinner
          matSuffix
          *ngIf="(control.statusChanges | async) === 'PENDING'"
          diameter="18"
        ></mat-spinner>
        <mat-hint>Hit enter to apply changes</mat-hint>
        <mat-error [errorFor]="control"></mat-error>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-template>
