import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationFieldCompareItem } from '../../../core/models/application-field/application-field-compare-item.model';
import { ApplicationFieldsService } from '../../../core/services/application-fields/application-fields.service';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { AppConstants } from '../../../core/app-constants';
import { generateRandomString } from '../../../core/pure-functions/generate-random-string.fn';
import { ApplicationFieldItemComparesFacade } from '../state/application-field-item-compares/application-field-item-compares.facade';
import { ApplicationFieldCompare } from '../../../core/models/application-field/application-field-сompare.model';
import { generateRandomNumber } from '../../../core/pure-functions/generate-random-number.fn';

@Component({
    selector: 'app-create-application-field-item-compare',
    templateUrl: './create-application-field-item-compare.component.html',
    styleUrls: ['./create-application-field-item-compare.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateApplicationFieldItemCompareComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();

    public selection = new SelectionModel<ApplicationFieldCompareItem>(true);
    public inputPlaceholder = AppConstants.INPUT_PLACEHOLDER;
    public maxLength = 128;
    public applicationFieldItemCompare: ApplicationFieldCompare = {} as ApplicationFieldCompare;

    @Input() public readonlySection: boolean;
    @Input() public sidenav: MatSidenav;

    @Output() public closeSidenavClick = new EventEmitter();

    @ViewChild('table') table;

    public dataSource = new MatTableDataSource<ApplicationFieldCompareItem>();
    public displayedColumns: string[] = ['selected', 'valueThen', 'valueElse'];

    constructor(
        private applicationFieldsService: ApplicationFieldsService,
        private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private applicationFieldItemComparesFacade: ApplicationFieldItemComparesFacade
    ) {}

    ngOnInit(): void {
        this.applicationFieldItemCompare.tenantId = AppConstants.DEFAULT_TENANT_ID;
        this.applicationFieldItemCompare.name = '';
        this.applicationFieldItemCompare.valueCase = generateRandomString(5);
        this.applicationFieldItemCompare.valueDefault = generateRandomString(5);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public saveApplicationFieldCompareItems(): void {
        this.createApplicationFieldCompareItems(this.dataSource.data);
        this.closeSidenavClick.emit();
    }

    public createApplicationFieldCompareItems(itemsToUpsert: Array<ApplicationFieldCompareItem>): void {
        this.applicationFieldItemComparesFacade.createApplicationFieldItemCompare(
            this.applicationFieldItemCompare,
            itemsToUpsert
        );
    }

    public deleteCompareRows(): void {
        this.dataSource.data = this.dataSource.data.filter((x) => !this.selection.isSelected(x));
        this.selection.clear();
    }

    public addApplicationFieldCompareItem(): void {
        const newRow = {
            valueThen: '',
            valueElse: '',
            valueInput: generateRandomString(5),
            valueOperat: generateRandomString(5),
            sequence: generateRandomNumber(),
            notation: '',
            tenantId: AppConstants.DEFAULT_TENANT_ID
        } as ApplicationFieldCompareItem;

        this.dataSource.data.push(newRow);
        this.dataSource._updateChangeSubscription();
        this.focusLastRow();
    }

    public showSavePanel(): boolean {
        return !(this.dataSource.data.length === 0) || this.applicationFieldItemCompare.name !== '';
    }

    private focusLastRow(): void {
        this.table?._elementRef.nativeElement
            .getElementsByTagName('tr')
            [this.dataSource.data.length].getElementsByTagName('input')[1]
            .focus();
    }
}
