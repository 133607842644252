import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Self,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';

interface OptionContext<T> {
  $implicit: T;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'ng-template[optionTemplate]'
})
export class OptionTemplateDirective<T extends object> {
  constructor(
    public ref: TemplateRef<OptionContext<T>>
  ) {
  }

  static ngTemplateContextGuard<TContext extends object>(
    directive: OptionTemplateDirective<TContext>,
    context: unknown
  ): context is OptionContext<TContext> {
    return true;
  }
}

@Component({
  selector: 'app-editable-select',
  templateUrl: './editable-select.component.html',
  styleUrls: ['./editable-select.component.scss']
})
export class EditableSelectComponent<T extends object> implements OnDestroy, ControlValueAccessor {

  @Input()
  public label?: string;

  @Input()
  public orderBy?: keyof T;

  @Input()
  public displayProperty?: keyof T;

  @Input()
  public options: Observable<T[]> = of([]);

  @Output()
  public edit = new EventEmitter();

  @ContentChild(OptionTemplateDirective)
  public optionTemplate?: OptionTemplateDirective<T>;

  public control = this.fb.control<number | null>(null);

  private destroyed$ = new Subject();

  constructor(
    @Self() private ngControl: NgControl,
    private fb: FormBuilder
  ) {
    this.ngControl.valueAccessor = this;
    this.control.valueChanges.pipe(
      startWith(this.control.value),
      pairwise(),
      takeUntil(this.destroyed$)
    ).subscribe(([prev, next]) => {
      if (next === undefined) {
        this.control.setValue(prev);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onEdit(): void {
    this.edit.emit();
  }

  public writeValue(obj: any): void {
    if (obj === null) {
      this.control.reset();
    } else if (obj !== undefined) {
      this.control.setValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.pipe(
      filter(next => next !== undefined),
      takeUntil(this.destroyed$)
    ).subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
}
