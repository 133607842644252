import { createAction, props } from '@ngrx/store';
import { Category } from '../../models/category/category.model';

export const loadCategories = createAction('[Category Facade] Load Categories');
export const loadCategoriesSuccess = createAction(
    '[Category Effect] Load Categories Success',
    props<{ categories: Array<Category> }>()
);

export const loadCategoryByCode = createAction('[Categories Facade] Load Category By Code', props<{ code: string }>());
export const loadCategoryByCodeSuccess = createAction(
    '[Categories Effect] Load Category By Code Success',
    props<{ category: Category }>()
);

export const updateCategory = createAction('[Categories Facade] Update Category', props<{ category: Category }>());
export const updateCategorySuccess = createAction('[Categories Effect] Update Category Success', props<{ category: Category }>());

export const createCategory = createAction('[Categories Facade] Create Category', props<{ category: Category }>());
export const createCategorySuccess = createAction('[Categories Effect] Create Category Success', props<{ category: Category }>());

export const deleteCategory = createAction('[Categories Facade] Delete Category', props<{ code: string }>());
export const deleteCategorySuccess = createAction('[Categories Effect] Delete Category Success', props<{ code: string }>());

export const updateCategoryStatus = createAction('[Categories Effects] Change status', props<{ categoryCode: string }>());
