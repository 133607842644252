<mat-form-field appearance="fill" class="w-full" >
  <input
    matInput
    type="text"
    [formControl]="control"
    [placeholder]="placeholder"
  >
  <mat-icon matPrefix color="accent">search</mat-icon>
  <button
    *ngIf="control.value"
    mat-icon-button
    color="accent"
    matSuffix
    (click)="this.clearInput(); $event.stopPropagation();">
    <mat-icon>backspace</mat-icon>
  </button>
</mat-form-field>

<ng-content></ng-content>
