<header class="header header--sidebar">
  <div class="header__items mr-auto">
    <div class="header__title">XLAT</div>
  </div>
  <div class="flex items-center gap-x-4">
    <button
      mat-button
      color="primary"
      (click)="saveApplicationFieldXlatItems()">
      SAVE
    </button>

    <button
      mat-stroked-button
      color="accent"
      *ngIf="!readonlySection"
      (click)="deleteXlatRows()">
      Delete
    </button>

    <button mat-icon-button (click)="closeSidenavClick.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</header>

<div class="main-sidenav-container">
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>XLAT name</mat-label>
    <input
      [disabled]="readonlySection"
      [(ngModel)]="applicationFieldItemXlat.name"
      matInput
    />
  </mat-form-field>

  <table
    *ngIf="dataSource.data.length"
    #table
    class="mat-elevation-z8 main-table"
    mat-table
    matSort
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="selected">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef></th>
      <td class="main-table__cell text-center" mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="readonlySection"
          (change)="selection.toggle(element)"
          [checked]="selection.isSelected(element)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="valueSource">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>SOURCE VALUE</th>
      <td
        appFieldLengthLimit
        [maxLength]="maxLength"
        appRequiredField
        class="main-table__cell main-table__cell--black"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueSource"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="valueTarget">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>TARGET VALUE</th>
      <td
        appFieldLengthLimit
        [maxLength]="maxLength"
        appRequiredField
        class="main-table__cell main-table__cell--black"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueTarget"
        />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.highlight-row]="selection.isSelected(row)"></tr>
  </table>

  <button
    *ngIf="!readonlySection"
    mat-button
    color="primary"
    class="mx-auto mt-5"
    (click)="addApplicationFieldXlatItem()"
  >
    Add New
    <mat-icon>add</mat-icon>
  </button>
</div>
