import { createReducer, on } from '@ngrx/store';
import { CurrentUserActions, IntegrationsActions } from '../../../core/state/action-types/action-types';
import { UserProfile } from '../../../core/models/user/user-profile.model';

export const currentUserFeatureKey = 'current-user';

export interface CurrentUserState {
    user: UserProfile;
    refreshTokenRequestCompleted: boolean;
    passwordResetSent: boolean;
    passwordReset: boolean;
}
export const initialCurrentUserState: CurrentUserState = {
    user: null,
    refreshTokenRequestCompleted: false,
    passwordResetSent: false,
    passwordReset: false
};

export const currentUserReducer = createReducer(
    initialCurrentUserState,
    on(CurrentUserActions.updateUserSuccess, (state, action) => {
        return { ...state, user: action.user };
    }),
    on(IntegrationsActions.selectIntegrationSuccess, (state, action) => {
        return { ...state, user: { ...state.user, selectedIntegrationId: action.integrationId } };
    }),
    on(CurrentUserActions.changeEmailSuccess, (state, action) => {
        return { ...state, user: { ...state.user, email: action.email } };
    }),
    on(CurrentUserActions.loginSuccess, (state, action) => {
        return { ...state, user: action.user };
    }),
    on(CurrentUserActions.logoutSuccess, () => {
        return initialCurrentUserState;
    }),
    on(CurrentUserActions.refreshTokenSuccess, (state, action) => {
        return { ...state, user: action.user, refreshTokenRequestCompleted: true };
    }),
    on(CurrentUserActions.refreshTokenError, (state) => {
        return { ...state, refreshTokenRequestCompleted: true };
    }),
    on(CurrentUserActions.sendResetPasswordByEmailSuccess, (state) => {
        return { ...state, passwordResetSent: true };
    }),
    on(CurrentUserActions.resetPasswordSuccess, (state) => {
        return { ...state, passwordReset: true };
    })
);
