import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { IntegrationsFacade } from '../../../components/dashboards/state/integrations.facade';
import { CurrentUserFacade } from '../../../components/user-profile/state/current-user.facade';
import { AppConstants } from '../../../core/app-constants';
import { UserProfile } from '../../../core/models/user/user-profile.model';
import { DiscardDataService } from '../../../core/services/discard-data/discard-data.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { SnackBarDataService } from '../../../core/services/snack-bar-data/snack-bar-data.service';
import { TeamMembersFacade } from '../../../core/state/team-members/team-members.facade';
import { SendNotificationData } from './send-notification-data.ts';
import * as _ from 'lodash';
import { EmailNotification } from 'src/app/core/models/email-notification/email-notification.model';
import { RecipientCodeValidator } from './recipient-validator';
import { ContactModel } from '../../../core/models/email-notification/contact-model';

// From src of Validators.Email
const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const EmailsStringValidator = (separator: ';' | ',') => {
  return (control: FormControl<string>): ValidationErrors => {
    if (control.value.length === 0) {
      return null;
    }

    const invalidEmails = control.value
      .split(separator)
      .map(mail => EMAIL_REGEXP.test(mail) ? null : mail)
      .filter(error => error != null);

    if (invalidEmails.length === 0) {
      return null;
    }

    return { email: true };
  };
};

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit, AfterViewInit {

  public readonly EMAIL_SEPARATOR = ',';

  @ViewChild('bodyElement') textArea: ElementRef;

  public emails$: Observable<ContactModel[]>;
  public user$: Observable<UserProfile>;

  public manualInput = new BehaviorSubject<boolean>(false);
  public form = this.fb.group({
    recipientSelect: this.fb.control<string[]>({
      value: [],
      disabled: this.manualInput.value
    }),
    recipientInput: this.fb.control({
      value: '',
      disabled: !this.manualInput.value
    }, {
      validators: [EmailsStringValidator(this.EMAIL_SEPARATOR)]
    }),
    subject: this.fb.control('', Validators.required),
    body: this.fb.control('', Validators.required),
    slackNotification: this.fb.control(false)
  }, { validator: RecipientCodeValidator.createValidator(this.manualInput) });

  public loading = false;
  public slackNotificationEmail: string;
  public defaultRecipients$ = new BehaviorSubject<Array<ContactModel>>([])

  constructor(
    private fb: FormBuilder,
    private notificationsService: NotificationsService,
    private integrationsFacade: IntegrationsFacade,
    private teamMembersFacade: TeamMembersFacade,
    private currentUserFacade: CurrentUserFacade,
    private snackBarDataService: SnackBarDataService,
    private discardDataService: DiscardDataService,
    private dialogRef: MatDialogRef<SendNotificationComponent>,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: SendNotificationData
  ) {
  }

  public ngOnInit(): void {
    if (!this.data.defaultRecipients?.length) {
      this.emails$ = combineLatest([
        this.integrationsFacade.currentIntegration$,
        this.teamMembersFacade.integrationManagers$,
        this.teamMembersFacade.salespersons$,
        this.teamMembersFacade.mechanics$,
      ]).pipe(
        map(([integration, integrationManagers, salespersons, mechanics]) => {
          this.slackNotificationEmail = integration?.slackChannel;
          this.form.controls.slackNotification.setValue(!!this.slackNotificationEmail);

          return _.uniqBy([
            {
              email: integration.clientProjectLeadEmail, name: integration.clientProjectLeadName ? integration.clientProjectLeadName : integration.clientProjectLeadEmail
            } as ContactModel,
            {
              email: integration.partnerProjectLeadEmail, name: integration.partnerProjectLeadName ? integration.partnerProjectLeadName : integration.partnerProjectLeadEmail
            } as ContactModel,
            {
              email: integration.sourceContactEmail, name: integration.sourceContactEmail ? integration.sourceContactEmail : integration.sourceContactEmail
            } as ContactModel,
            {
              email: integration.targetContactEmail, name: integration.targetContactName ? integration.targetContactName : integration.targetContactEmail
            } as ContactModel,
            ...integrationManagers.map((item) => { return { email: item.email, name: item.fullName } as ContactModel }),
            ...salespersons.map((item) => { return { email: item.email, name: item.fullName } as ContactModel }),
            ...mechanics.map((item) => { return { email: item.email, name: item.fullName } as ContactModel }),
          ].filter((i) => i.email), "email")
        }),
        tap((emails: ContactModel[]) => {
          if (!!emails && this.data.assignedIM) {
            const im = emails.find(i => i.name == this.data.assignedIM);
            this.form.controls.recipientSelect.setValue([im?.email]);
          }
        }),
      );
    } else {
      this.emails$ = this.defaultRecipients$.asObservable();
    }
    this.initializeFormMembers();
    this.user$ = this.currentUserFacade.currentUser$;
    this.dialogRef.disableClose = true;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.textArea.nativeElement.focus();
      this.textArea.nativeElement.setSelectionRange(0, 0);
      this.changeDetector.detectChanges();
    }, 200);
  }

  public sendNotification(user: UserProfile): void {
    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.value;
    const recipientSelect = this.form.controls.recipientSelect.valid ? formValue.recipientSelect : [];
    const recipientInput = this.form.controls.recipientInput.valid ? new Set(formValue.recipientInput.split(this.EMAIL_SEPARATOR)) : []
    const emailNotification = {
      sender: user.email,
      recipients: [...recipientSelect, ...recipientInput],
      subject: formValue.subject,
      body: formValue.body,
      isBlindCopy: this.data.isBlindCopy,
      slackNotificationEmail: formValue.slackNotification ? this.slackNotificationEmail : null
    } as EmailNotification;

    this.loading = true;
    this.notificationsService
      .sendEmailNotification(emailNotification)
      .pipe(
        tap(() => {
          this.snackBarDataService.showSnackBar(
            'check_circle',
            'The notification was successfully sent.',
            AppConstants.SUCCESS_COLOR
          );
          this.dialogRef.close(true);
        }),
        finalize(() => this.loading = false)
      ).subscribe();
  }

  public getTooltipForSlackCheckbox(): string {
    if (!this.slackNotificationEmail) {
      return "To use this checkbox insert Slack Channel email first"
    }
    return "Send email to Slack Channel connected to current integration"
  }

  public close(): void {
    if (this.form.pristine) {
      this.dialogRef.close();
      return;
    }

    const dialogRef = this.discardDataService.openConfirmationDialog();

    dialogRef
      .afterClosed()
      .pipe(
        tap((res) => {
          if (res) {
            this.dialogRef.close();
          }
        })
      )
      .subscribe();
  }

  public toggleManualInput(): void {
    this.manualInput.next(!this.manualInput.value);

    const { recipientInput, recipientSelect } = this.form.controls;

    if (this.manualInput) {
      recipientInput.enable();
    } else {
      recipientSelect.enable();
    }
  }

  private initializeFormMembers(): void {
    this.form.controls.subject.setValue(this.data.subject);
    this.form.controls.body.setValue(this.data.emailBody);
    if (this.data?.isRecipientDisabled) {
      this.manualInput.next(true);
      this.form.controls.subject.setValue(this.data.subject);
      this.form.controls.recipientInput.setValue(this.data.recipient);
      this.form.controls.recipientInput.enable();
      this.form.controls.recipientSelect.disable();
      this.form.controls.body.setValue(this.data.emailBody);
    }
    if (this.data.assignedIM) {
      this.manualInput.next(false);
    }
    if (!!this.data.defaultRecipients?.length) {
      this.defaultRecipients$.next(this.data.defaultRecipients);
      this.form.controls.recipientSelect.setValue(this.data.defaultRecipients.map(i => i.email));
    }
  }
}
