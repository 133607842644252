import { TemplateRef, Type } from '@angular/core';
import { SortOptions } from './sort-options';

export interface ColumnDef<T> {
  field: string;
  title?: string;
  hidden?: boolean;
  template?: TemplateRef<{ $implicit: T }>;
  component?: Type<any>;
  componentData?: Record<string, any>;
  displayFn?: (el: T) => string;
  sort?: SortOptions;
  cssClass?: string[] | string;
  required?: boolean;
}

export type ColumnDefs<T> = ColumnDef<T>[];

export type ColumnProps<T> = Pick<ColumnDef<T>, 'title' | 'hidden' | 'displayFn' | 'sort' | 'cssClass' | 'required'>;

export class Column<T> implements ColumnDef<T> {

  public title?: string;
  public hidden?: boolean;
  public displayFn?: (el: T) => string;
  public sort?: SortOptions;
  public cssClass?: string[] | string;
  public required?: boolean;

  constructor(
    public field: string,
    props: ColumnProps<T> = {}
  ) {
    this.title = props.title;
    this.hidden = props.hidden;
    this.displayFn = props.displayFn;
    this.sort = props.sort;
    this.cssClass = props.cssClass;
    this.required = props.required;
  }
}

export class TemplateColumn<T> extends Column<T> {
  constructor(
    field: string,
    public template: TemplateRef<{ $implicit: T }>,
    props?: ColumnProps<T>
  ) {
    super(field, props);
  }
}

export class ComponentColumn<T, TData> extends Column<T> {
  constructor(
    field: string,
    public component: Type<any>,
    public componentData: TData,
    props?: ColumnProps<T>
  ) {
    super(field, props);
  }
}

export class HeaderTemplateColumn<T> extends Column<T> {
  constructor(
    field: string,
    public template: TemplateRef<{ $implicit: T }>,
    public headerTemplate: TemplateRef<{ $implicit: T }>,
    props?: ColumnProps<T>
  ) {
    super(field, props);
  }
}
