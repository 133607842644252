export class AppConstants {
    public static readonly DEFAULT_PARTNER_CLIENT_TIMEZONE = "ET"
    public static readonly DEFAULT_PACKAGES_FIELD_NAME = "Packages"
    public static readonly DEFAULT_TENANT_ID = '_MASTER';
    public static readonly STORE_EMISSION_COUNT = 2;
    public static readonly PAGE_SIZE = 100;
    public static readonly CODE_LENGTH = 8;
    public static readonly SELECT_TRIGGER_LENGTH = 15;
    public static readonly MAX_DIALOG_HEIGHT = '90vh';
    public static readonly SNACK_BAR_DURATION = 2500;
    public static readonly TOOLTIP_SHOW_DELAY = 200;
    public static readonly TOOLTIP_POSITION = 'above';
    public static readonly INPUT_PLACEHOLDER = 'Edit field...';
    public static readonly SELECT_PLACEHOLDER = 'Choose field...';
    public static readonly SUCCESS_COLOR = '#34d399';
    public static readonly ERROR_COLOR = '#ee0004';
    public static readonly WARNING_COLOR = '#edb95e';
    public static readonly STATUSES = ['A', 'I'];
    public static readonly DEFAULT_RECORD_STATUS = 'A';
    public static readonly SOURCE_TARGET = ['Source', 'Target', 'Both'];
    public static readonly TYPES = ['Client', 'Template', 'Master'];
    public static readonly QUESTION_TYPES = ['Client', 'Master'];
    public static readonly SALES_PERSON_ROLE = 'Salesperson';
    public static readonly INTEGRATION_MANAGER_ROLE = 'Integration Mgr';
    public static readonly PARTNER_ROLE = 'Partner';
    public static readonly MECHANIC_ROLE = 'Mechanic';
    public static readonly MECHANIC_REVIEW_FILTER = 'Mechanic Review';

    // validation
    public static readonly CONNECTOR_NAME_MAX_LENGTH = 32;
    public static readonly HELP_TOPIC_NAME_MAX_LENGTH = 64;
    public static readonly CONNECTOR_ITEM_CODE_NAME_MAX_LENGTH = 64;
    public static readonly NAME_MAX_LENGTH = 128;
    public static readonly CLOUD_NORMAL_NAME_MAX_LENGTH = 128;
    public static readonly SCREEN_NAME_MAX_LENGTH = 256;
    public static readonly API_FIELD_NAME_MAX_LENGTH = 256;
    public static readonly API_FIELD_LOCATION_MAX_LENGTH = 512;
    public static readonly API_FIELD_REFERENCE = 2048;
    public static readonly DESCRIPTION_MAX_LENGTH = 128;
    public static readonly DESCRIPTION_SHORT_MAX_LENGTH = 64;
    public static readonly COMMENTS_MAX_LENGTH = 2048;
    public static readonly RULE_MAX_LENGTH = 2048;
    public static readonly BUSINESS_UNIT_NAME = 64;
    public static readonly BUSINESS_UNIT_SHORT_NAME = 20;
    public static readonly TENANT_ID = 10;
    public static readonly JOYND_DOMAIN_NAME = "^[A-Za-z0-9._%+-]+@joynd\.io$";

    public static readonly OAI_PHASES = {
        NEW: "New",
        PRODUCTION: "Production",
        INACTIVE: "Inactive",
    }

    public static readonly VALIDATION = {
        RPP_MASTER: {
            DESCRIPTION: 128,
            COMMENTS: 2048,
        },
        RPP_TYPE: {
            CODE: 32,
            DESCRIPTION: 128,
            RULE: 256,
            COMMENTS: 2048,
        }
    }

    // Excel column names
    public static readonly NAME = 'Name';
    public static readonly GROUP_NAME = 'Group Name';
    public static readonly INTEGRATION_NAME = 'Integration Name';
    public static readonly CLIENT_NAME = 'Client Name';
    public static readonly DESCRIPTION = 'Description';
    public static readonly TYPE = 'Type';
    public static readonly PATTERN = 'Pattern';
    public static readonly APPLICATION = 'Application';
    public static readonly AFFILIATE = 'Affiliate';
    public static readonly SOURCE_OR_TARGET = 'Source or Target';
    public static readonly CLOUD_NORMAL = 'CloudNormal Name';
    public static readonly SOURCE_SCREEN_NAME = 'Source Screen Name';
    public static readonly TARGET_SCREEN_NAME = 'Target Screen Name';
    public static readonly TECHNICAL_NAME = 'Tech Name';

    public static readonly ACTION = 'Action';
    public static readonly USED = 'Used';
    public static readonly RULE = 'Rule';
    public static readonly REVIEW = 'Review';
    public static readonly XLAT_NAME = 'XLAT Name';
    public static readonly COMPARE_NAME = 'Compare Name';
    public static readonly CATEGORY = 'Category';
    public static readonly SOURCE_DATA_TYPE = 'Source Data Type';
    public static readonly TARGET_DATA_TYPE = 'Target Data Type';
    public static readonly SOURCE_FORMAT = 'Source Format';
    public static readonly TARGET_FORMAT = 'Target Format';
    public static readonly COMMON_FIELD = 'Common Field';
    public static readonly CUSTOM_FIELD = 'Custom Field';
    public static readonly HIDDEN_FIELD = 'Hidden Field';
    public static readonly SOURCE_API_FIELD_NAME = 'Source API Field Name';
    public static readonly TARGET_API_FIELD_NAME = 'Target API Field Name';
    public static readonly SOURCE_API_FIELD_LOCATION = 'Source API Field Location';
    public static readonly TARGET_API_FIELD_LOCATION = 'Target API Field Location';
    public static readonly SOURCE_API_REFERENCE = 'Source API Reference';
    public static readonly TARGET_API_REFERENCE = 'Target API Reference';
    public static readonly ORDER_NUMBER = 'Order Number';
    public static readonly QUESTION = 'Question';
    public static readonly SPECIAL_HANDLING = 'Special Handling';
    public static readonly FIELD = 'Field';
    public static readonly VALIDATION_NAME = 'Validation';
    public static readonly STATUS = 'Status';
    public static readonly COMMENTS = 'Comments';
    public static readonly CREATED_DATE = 'Created Date';
    public static readonly LAST_UPDATED_DATE = 'Last Updated Date';
    public static readonly LAST_UPDATED_BY = 'Last Updated By';
    public static readonly SECTIONS = 'Sections';

    public static readonly XLAT_TO_EXCEL = {
        XLAT_EXCEL_COLUMN: 'XLAT Name',
        SOURCE_VALUE_EXCEL_COLUMN: 'Source Value',
        TARGET_VALUE_EXCEL_COLUMN: 'Target Value',
    }

    public static readonly SENSITIVITY = {
        NORMAL: "Normal",
        MASK: "Mask"
    }

    public static readonly APPLICATION_FIELD_TO_EXCEL = {
        CLOUDNORMAL_NAME: "CloudNormal Name",
        APP_SCREEN_NAME: "App Screen Name",
        CATEGORY: "Category",
        DATA_TYPE: "Data Type",
        FORMAT: "Format",
        COMMON_FIELD: "Common Field",
        CUSTOM_FIELD: "Custom Field",
        HIDE_FIELD: "Hide Field",
        REVIEW: "Review",
        XLAT_NAME: "XLAT",
        COMPARE: "Compare",
        COMMENTS: "Comments",
        OBSOLETE: "Obsolete",
        STATUS: "Status",
        API_FIELD_NAME: "API Field Name",
        API_FIELD_LOCATION: "API Field Location",
        API_REFERENCE: "API Reference"
    }

    public static readonly ROUTING = {
        RPP_MASTER: {
            EDIT: "selectedRppMasterId",
        },
    }
}
