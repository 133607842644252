import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { AdminControlsActions, SnackbarActions } from '../../../../core/state/action-types/action-types';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';
import { AdminPanelService } from '../../../../core/services/admin-panel/admin-panel.service';
import { selectAdminControls } from './admin-controls.selectors';
import { AppConstants } from '../../../../core/app-constants';

@Injectable()
export class AdminControlsEffects {
    constructor(private actions$: Actions, private store: Store<AppState>, private adminPanelService: AdminPanelService) {}

    loadAdminControls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminControlsActions.loadAdminControls),
            concatLatestFrom(() => this.store.pipe(select(selectAdminControls))),
            filter(([, adminControls]) => !adminControls.length),
            switchMap(() =>
                this.adminPanelService
                    .getAdminControls()
                    .pipe(map((adminControls) => AdminControlsActions.loadAdminControlsSuccess({ adminControls })))
            )
        )
    );

    upsertAdminControl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminControlsActions.upsertAdminControl),
            concatMap((action) =>
                this.adminPanelService.upsertAdminControls([action.adminControl]).pipe(
                    concatMap(() => {
                        const actions: Array<Action> = [
                            AdminControlsActions.upsertAdminControlSuccess({
                                adminControl: action.adminControl
                            })
                        ];

                        if (action.showSnackbar) {
                            actions.push(
                                SnackbarActions.showSnackbar({
                                    iconName: 'check_circle',
                                    text: 'Changes were saved successfully',
                                    iconColor: AppConstants.SUCCESS_COLOR
                                })
                            );
                        }
                        return actions;
                    })
                )
            )
        )
    );
}
