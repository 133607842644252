import { createAction, props } from '@ngrx/store';
import { QuestionClientItem } from '../../../core/models/question-client/question-client-item.model';

export const loadQuestionClientItems = createAction(
    '[Question Client Items Facade] Load Question Client Items',
    props<{ id: number }>()
);
export const loadQuestionClientItemsSuccess = createAction(
    '[Question Client Items Effects] Load Question Client Items Success',
    props<{ questionClientItems: Array<QuestionClientItem>; questionClientId: number }>()
);

export const loadQuestionClientItemById = createAction(
    '[Question Client Items Facade] Load Question Client Item By Id',
    props<{ id: number }>()
);
export const loadQuestionClientItemByIdSuccess = createAction(
    '[Question Client Items Effect] Load Question Client Item By Id Success',
    props<{ questionClientItem: QuestionClientItem }>()
);
