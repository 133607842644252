import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogData } from 'src/app/shared/components/shared-dialog/models/shared-dialog-data';
import { SharedDialogComponent } from 'src/app/shared/components/shared-dialog/shared-dialog.component';
import { SnackBarDataService } from '../snack-bar-data/snack-bar-data.service';
import { AppConstants } from '../../app-constants';
import { EditableTextareaDialogComponent } from 'src/app/shared/components/editable-textarea-dialog/editable-textarea-dialog.component';
import { EditableTextareaData } from 'src/app/shared/components/editable-textarea-dialog/models/editable-textarea-data';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog, private snackBarDataService: SnackBarDataService) { }

  public openConfirmationDialog(data: SharedDialogData) {
    return this.dialog.open(SharedDialogComponent, {
      width: '25%',
      data: data
    });
  }

  public openEditableTextareaDialog(data: EditableTextareaData): MatDialogRef<EditableTextareaDialogComponent> {
    return this.dialog.open(EditableTextareaDialogComponent, {
      width: '35%',
      data: data
    });
  }

  public showSuccessMessage(message: string = 'Changes saved successfully', iconName: string = 'check_circle', color: string = AppConstants.SUCCESS_COLOR) {
    this.snackBarDataService.showSnackBar(iconName, message, color)
  }

  public showErrorMessage(message: string = 'Something went wrong', iconName: string = 'highlight_off', color: string = AppConstants.ERROR_COLOR) {
    this.snackBarDataService.showSnackBar(iconName, message, color);
  }

  public showWarningMessage(message: string = 'Something went wrong', iconName: string = 'warning', color: string = AppConstants.WARNING_COLOR) {
    this.snackBarDataService.showSnackBar(iconName, message, color);
  }
}
