import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './application-field-item-compare-items.reducer';
import {
    applicationFieldItemCompareItemFeatureKey,
    ApplicationFieldItemCompareItemsState
} from './application-field-item-compare-items.reducer';

const selectApplicationFieldItemCompareItemsState = createFeatureSelector<ApplicationFieldItemCompareItemsState>(
    applicationFieldItemCompareItemFeatureKey
);

export const selectLoading = createSelector(selectApplicationFieldItemCompareItemsState, (state) => state.loading);

export const selectLoaded = createSelector(selectApplicationFieldItemCompareItemsState, (state) => state.loaded);

export const selectApplicationFieldItemCompareItemsAll = createSelector(
    selectApplicationFieldItemCompareItemsState,
    fromReducer.selectAll
);

export const selectApplicationFieldItemCompareItems = (id: number) =>
    createSelector(selectApplicationFieldItemCompareItemsState, (state) =>
        state.ids.map((x) => state.entities[x]).filter((x) => x.applicationFieldCompare.id === id)
    );

export const selectApplicationFieldItemCompareItemById = (id: number) =>
    createSelector(
        selectApplicationFieldItemCompareItemsState,
        selectApplicationFieldItemCompareItemsAll,
        (state, applicationFieldItemCompareItems) => applicationFieldItemCompareItems.find((item) => item.id === id)
    );

export const selectLoadedEntitiesById = createSelector(
    selectApplicationFieldItemCompareItemsState,
    (state) => state.loadedEntitiesById
);
