import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../../models/user/user-profile.model';
import { Integration } from '../../models/integration/integration.model';
import { IntegrationQuickStartStatus } from '../../enums/integration-quick-start-status.enum';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {
    uri = environment.apiUrl + '/userProfile';

    constructor(private readonly http: HttpClient) { }

    public updateUser(user: UserProfile): Observable<void> {
        return this.http.put<void>(`${this.uri}`, user);
    }

    public selectIntegration(userId: string, integrationId: number): Observable<void> {
        return this.http.put<void>(`${this.uri}/selected-integration`, {
            userId,
            integrationId
        });
    }

    public getSelectedIntegration(): Observable<Integration> {
        return this.http.get<Integration>(`${this.uri}/selected-integration`);
    }

    public updatePassword(currentEmail: string, currentPassword: string, newPassword: string): Observable<void> {
        return this.http.put<void>(`${this.uri}/change-password`, {
            currentEmail,
            currentPassword,
            newPassword
        });
    }

    public updateEmail(currentEmail: string, newEmail: string, currentPassword: string): Observable<void> {
        return this.http.put<void>(`${this.uri}/change-email`, {
            currentEmail,
            newEmail,
            currentPassword
        });
    }

    public uploadImage(formFile: FormData, fileName: string, userId: string): Observable<string> {
        return this.http.post<string>(`${this.uri}/upload-image?fileName=${fileName}&userId=${userId}`, formFile);
    }

    public sendResetPasswordByEmail(email: string): Observable<void> {
        return this.http.post<void>(`${this.uri}/forgot-password`, { email });
    }

    public resetPassword(email: string, newPassword: string, token: string): Observable<void> {
        return this.http.post<void>(`${this.uri}/reset-password`, {
            email,
            newPassword,
            token
        });
    }

    public updateUserIntegrationStatus(status: IntegrationQuickStartStatus, integrationId: number) {
        return this.http.put<void>(`${this.uri}/userStatus?status=${status}&integrationId=${integrationId}`, {});
    }
}
