import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectItemData } from './select-item-data.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-select-item-dialog',
    templateUrl: './select-item-dialog.component.html',
    styleUrls: ['./select-item-dialog.component.scss']
})
export class SelectItemDialogComponent implements OnInit, OnDestroy {
    public destroy$ = new Subject();

    public form = this.fb.group({
        itemsControl: this.fb.control<any>(null),
    });

    public filterItemsData$ = (search: string) => this.data.items.pipe(
        map(items => this.filterFunction(search, "name", items))
    );

    private filterFunction = (search: string, property: string, array: Array<any>) =>
        array.filter(teamMember => teamMember[property]?.toLowerCase().includes(search ? search.toLowerCase() : ''));

    constructor(
        public dialogRef: MatDialogRef<SelectItemDialogComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: SelectItemData,
    ) { }

    ngOnInit(): void {
        this.data.items
            .pipe(
                tap((items) => {
                    this.form.controls.itemsControl = new FormControl(items[0]);
                }, takeUntil(this.destroy$))
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }
}
