<mat-form-field class="w-full" appearance="fill">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [formControl]="control">
    <mat-option (click)="onEdit()">
      <div class="edit-text">{{ 'edit' | uppercase }}</div>
    </mat-option>
    <mat-option
      *ngFor="let option of options | async | orderBy: orderBy; let i = index"
      [value]="i"
    >
      <ng-container
        *ngIf="optionTemplate?.ref as templateRef else defaultTemplate"
        [ngTemplateOutlet]="templateRef"
        [ngTemplateOutletContext]="{ $implicit: option }"
      ></ng-container>

      <ng-template #defaultTemplate>
        {{ displayProperty ? option[displayProperty] : option.toString() }}
      </ng-template>
    </mat-option>
  </mat-select>
</mat-form-field>
