import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

export function getPropertyByPath(obj: Record<string, any>, path: string): any {
  return get(obj, path);
}

@Pipe({
  name: 'get',
  pure: true
})
export class GetPipe implements PipeTransform {

  transform(value: any, path: string): any {
    return getPropertyByPath(value, path);
  }

}
