import { createAction, props } from '@ngrx/store';
import { PasswordResetDuration } from '../../../../core/models/admin-panel/password-reset.model';

export const loadPasswordResetDurations = createAction('[Password Reset Durations Facade] Load Password Reset Durations');

export const loadPasswordResetDurationsSuccess = createAction(
    '[Password Reset Durations Effects] Load Password Reset Durations Success',
    props<{ passwordResetDurations: Array<PasswordResetDuration> }>()
);

export const updatePasswordResetDuration = createAction(
    '[Password Reset Durations Facade] Update Password Reset Duration',
    props<{ passwordResetDuration: PasswordResetDuration }>()
);
export const updatePasswordResetDurationSuccess = createAction(
    '[Password Reset Durations Effect] Update Password Reset Duration Success',
    props<{ passwordResetDuration: PasswordResetDuration }>()
);

export const createPasswordResetDuration = createAction(
    '[Password Reset Durations Facade] Create Password Reset Duration',
    props<{ passwordResetDuration: PasswordResetDuration }>()
);
export const createPasswordResetDurationSuccess = createAction(
    '[Password Reset Durations Effect] Create Password Reset Duration Success',
    props<{ passwordResetDuration: PasswordResetDuration }>()
);

export const deletePasswordResetDuration = createAction(
    '[Password Reset Durations Facade] Delete Password Reset Duration',
    props<{ id: number }>()
);
export const deletePasswordResetDurationSuccess = createAction(
    '[Password Reset Durations Effect] Delete Password Reset Duration Success',
    props<{ id: number }>()
);
