<header class="header header--sidebar">
  <div class="header__items mr-auto">
    <div class="header__title">Compare</div>
  </div>
  <div class="flex items-center gap-x-4">
    <button
      mat-button
      color="primary"
      (click)="saveApplicationFieldCompareItems()"
    >
      SAVE
    </button>
    <button
      *ngIf="!readonlySection"
      mat-stroked-button
      color="accent"
      (click)="deleteCompareRows()">
      Delete
    </button>
    <button mat-icon-button (click)="closeSidenavClick.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</header>

<div class="main-sidenav-container">
  <div class="jp-form">
    <mat-form-field appearance="fill">
      <mat-label>Compare name</mat-label>
      <input
        [disabled]="readonlySection"
        [(ngModel)]="applicationFieldItemCompare.name"
        matInput/>
    </mat-form-field>

    <div class="form-section__group grid-cols-2">
      <mat-form-field appearance="fill">
        <mat-label>Input value</mat-label>
        <input [disabled]="readonlySection" matInput/>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Compare value</mat-label>
        <input [disabled]="readonlySection" matInput/>
      </mat-form-field>
    </div>
  </div>

  <table
    *ngIf="dataSource.data.length"
    #table
    class="mat-elevation-z8 main-table"
    mat-table
    matSort
    [dataSource]="dataSource">
    <ng-container matColumnDef="selected">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef></th>
      <td class="main-table__cell text-center" mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="readonlySection"
          (change)="selection.toggle(element)"
          [checked]="selection.isSelected(element)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="valueThen">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>DO VALUE</th>
      <td
        appFieldLengthLimit
        [maxLength]="maxLength"
        appRequiredField
        class="main-table__cell main-table__cell--black"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueThen"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="valueElse">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>ELSE VALUE</th>
      <td
        appFieldLengthLimit
        [maxLength]="maxLength"
        appRequiredField
        class="main-table__cell main-table__cell--black"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueElse"
        />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.highlight-row]="selection.isSelected(row)"></tr>
  </table>

  <button
    *ngIf="!readonlySection"
    mat-button
    color="primary"
    (click)="addApplicationFieldCompareItem()" class="mx-auto mt-5">
    Add New
    <mat-icon>add</mat-icon>
  </button>

  <app-save-panel
    *ngIf="showSavePanel()"
    (cancel)="closeSidenavClick.emit()"
    (save)="saveApplicationFieldCompareItems()"
  ></app-save-panel>
</div>
