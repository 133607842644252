<div mat-dialog-title class="flex justify-between items-center">
  <h2>{{ data?.title }}</h2>
  <span class="flex-auto"></span>
  <button mat-icon-button color="accent" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<form class="jp-form" *ngLet="user$ | async as user" [formGroup]="form" (ngSubmit)="sendNotification(user)">
  <mat-dialog-content>
    <div class="jp-form">
      <div class="form-section__group">
        <mat-form-field *ngIf="!(manualInput | async)" appearance="fill">
          <mat-label>Recipient</mat-label>
          <mat-select formControlName="recipientSelect" multiple>
            <mat-option *ngFor="let option of emails$ | async | orderBy: 'name'" [value]="option.email">
              {{ option.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.invalid && form.controls.subject.valid">Either Recipient or Slack Notification is
            required</mat-error>
          <mat-hint class="email-mode-switch" (click)="toggleManualInput()">
            enter manually
          </mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="(manualInput | async)" appearance="fill">
          <mat-label>Recipient</mat-label>
          <input formControlName="recipientInput" [readonly]="data.isRecipientDisabled" matInput />
          <mat-error *ngIf="form.invalid && form.controls.subject.valid">Either Recipient or Slack Notification is
            required</mat-error>
          <mat-hint class="email-mode-switch" (click)="toggleManualInput()">
            <span>select existing</span>
          </mat-hint>
        </mat-form-field>

        <div class="flex flex-col col-span-2">
          <mat-form-field appearance="fill">
            <mat-label>Subject</mat-label>
            <input formControlName="subject" matInput />
            <mat-error errorFor="subject"></mat-error>
          </mat-form-field>
          <mat-checkbox [matTooltip]="getTooltipForSlackCheckbox()" [disabled]="!slackNotificationEmail" class="-mt-4"
            formControlName="slackNotification">Slack</mat-checkbox>
        </div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Body</mat-label>
        <textarea #bodyElement class="overflow-hidden" cdkTextareaAutosize cdkAutosizeMinRows="3" formControlName="body"
          matInput></textarea>
        <mat-error errorFor="body"></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flex gap-x-1" align="end">
    <button mat-flat-button color="primary" type="submit" [disabled]="loading">
      Send Notification
    </button>
    <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
  </mat-dialog-actions>
</form>