import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogData } from './models/shared-dialog-data';
import { SharedDialogType } from './models/shared-dialog-type';

@Component({
  selector: 'app-shared-dialog',
  templateUrl: './shared-dialog.component.html',
  styleUrls: ['./shared-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedDialogComponent implements OnInit {
  public get sharedDialogType(): typeof SharedDialogType {
    return SharedDialogType;
  }

  constructor(
    public dialogRef: MatDialogRef<SharedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SharedDialogData) {
  }

  public ngOnInit(): void {
    if (!this.data.confirmButtonText) {
      this.data.confirmButtonText = 'OK';
    }
  }
}
