import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ApplicationFieldItemXlatsActions } from '../../../../core/state/action-types/action-types';
import { ApplicationFieldXlat } from '../../../../core/models/application-field/application-field-xlat.model';

export const applicationFieldItemXlatFeatureKey = 'applicationFieldItemXlat';

export interface ApplicationFieldItemXlatsState extends EntityState<ApplicationFieldXlat> {}

export const adapter = createEntityAdapter<ApplicationFieldXlat>({
    selectId: (applicationFieldItemXlat: ApplicationFieldXlat) => applicationFieldItemXlat.id
});

export const initialApplicationFieldItemXlatState = adapter.getInitialState({});

export const applicationFieldItemXlatsReducer = createReducer(
    initialApplicationFieldItemXlatState,
    on(ApplicationFieldItemXlatsActions.loadApplicationFieldItemXlatsSuccess, (state, action) =>
        adapter.upsertMany(action.applicationFieldItemXlats, {
            ...state
        })
    ),
    on(ApplicationFieldItemXlatsActions.createApplicationFieldItemXlatSuccess, (state, action) => {
        return adapter.upsertOne(action.applicationFieldItemXlat, state);
    }),
    on(ApplicationFieldItemXlatsActions.updateApplicationFieldItemXlatSuccess, (state, action) => {
        return adapter.upsertOne(action.applicationFieldItemXlat, state);
    })
);

export const { selectAll } = adapter.getSelectors();
