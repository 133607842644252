import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ApplicationFieldItemXlatItemsActions } from '../../../../core/state/action-types/action-types';
import { ApplicationFieldXlatItem } from '../../../../core/models/application-field/application-field-xlat-item.model';

export const applicationFieldItemXlatItemFeatureKey = 'applicationFieldItemXlatItem';

export interface ApplicationFieldItemXlatItemsState extends EntityState<ApplicationFieldXlatItem> {
    loading: boolean;
    loaded: boolean;
    loadedEntitiesById: Array<number>;
}

export const adapter = createEntityAdapter<ApplicationFieldXlatItem>({
    selectId: (applicationFieldItemXlatItem: ApplicationFieldXlatItem) => applicationFieldItemXlatItem.id
});

export const initialApplicationFieldItemXlatItemsState = adapter.getInitialState({
    loading: false,
    loaded: false,
    loadedEntitiesById: []
});

export const applicationFieldItemXlatItemsReducer = createReducer(
    initialApplicationFieldItemXlatItemsState,
    on(ApplicationFieldItemXlatItemsActions.loadApplicationFieldItemXlatItems, (state, action) => {
        if (state.loadedEntitiesById.some((x) => x === action.id)) {
            return {
                ...state,
                loading: false
            };
        }
        return {
            ...state,
            loading: true
        };
    }),
    on(ApplicationFieldItemXlatItemsActions.loadApplicationFieldItemXlatItemsSuccess, (state, action) =>
        adapter.upsertMany(action.applicationFieldItemXlatItems, {
            ...state,
            loading: false,
            loaded: true,
            loadedEntitiesById: [...state.loadedEntitiesById, action.id]
        })
    ),
    on(ApplicationFieldItemXlatItemsActions.upsertApplicationFieldItemXlatItemsSuccess, (state, action) => {
        return adapter.upsertMany(action.applicationFieldItemXlatItems, state);
    }),
    on(ApplicationFieldItemXlatItemsActions.deleteApplicationFieldItemXlatItemsSuccess, (state, action) =>
        adapter.removeOne(action.id, state)
    )
);

export const { selectAll } = adapter.getSelectors();
