<div class="flex items-center">
    <mat-select class="list-select" [placeholder]="placeholder" [formControl]="formControl">
        <mat-option (click)="onAddNewItem.emit()"
            [value]="addNewOption">
            <button mat-icon-button color="primary" class="w-full">
                Add New
                <mat-icon>add</mat-icon>
            </button>
        </mat-option>
        <mat-option (click)="clearSelection()">
        </mat-option>
        <mat-option *ngFor="let option of options | orderBy: 'name'" [value]="option.id">
            {{ option[displayProperty] }}
        </mat-option>
    </mat-select>
    <button mat-icon-button *ngIf="formControl.valid" color="accent" [matTooltip]="message" (click)="clearSelection()">
        <mat-icon>cancel</mat-icon>
    </button>
</div>