import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserFacade } from '../../components/user-profile/state/current-user.facade';
import { map } from 'rxjs/operators';
import { Permissions } from '../authorization/permissions';

@Injectable({
    providedIn: 'root'
})
export class IntegrationQuickStartGuard implements CanActivate {
    constructor(private currentUserFacade: CurrentUserFacade, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.currentUserFacade.currentUser$.pipe(
            map((user) => {
                if (user.roles.some((role) => role.permissions.some((permission) => permission === Permissions.IntegrationQuickStartView))) {
                    return true;
                }

                this.router.navigate(['/integration-search']);
                return false;
            })
        );
    }
}
