import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminControl } from '../../models/admin-control/admin-control.model';

@Injectable({
    providedIn: 'root'
})
export class AdminPanelService {
    private uri = environment.apiUrl + '/adminPanel';

    constructor(private readonly http: HttpClient) {}

    public getAdminControls(): Observable<Array<AdminControl>> {
        return this.http.get<Array<AdminControl>>(`${this.uri}/admin-controls`);
    }

    public upsertAdminControls(items: Array<AdminControl>): Observable<void> {
        return this.http.put<void>(`${this.uri}/admin-controls`, { items });
    }
}
