import { createAction, props } from '@ngrx/store';
import { ApplicationFieldCompareItem } from '../../../../core/models/application-field/application-field-compare-item.model';

export const loadApplicationFieldItemCompareItems = createAction(
    '[Application Field Item Compare Items Facade] Load Application Field Item Compare Items',
    props<{ id: number }>()
);

export const loadApplicationFieldItemCompareItemsSuccess = createAction(
    '[Application Field Item Compare Items Effects] Load Application Field Item Compare Items Success',
    props<{ applicationFieldItemCompareItems: Array<ApplicationFieldCompareItem>; id: number }>()
);

export const upsertApplicationFieldItemCompareItems = createAction(
    '[Application Field Item Compare Items Facade] Upsert Application Field Item Compare Items',
    props<{ applicationFieldItemCompareItems: Array<ApplicationFieldCompareItem> }>()
);
export const upsertApplicationFieldItemCompareItemsSuccess = createAction(
    '[Application Field Item Compare Items Effects] Upsert Application Field Item Compare Items Success',
    props<{ applicationFieldItemCompareItems: Array<ApplicationFieldCompareItem> }>()
);

export const deleteApplicationFieldItemCompareItems = createAction(
    '[Application Field Item Compare Items Facade] Delete Application Field Item Compare Items',
    props<{ id: number }>()
);
export const deleteApplicationFieldItemCompareItemsSuccess = createAction(
    '[Application Field Item Compare Items Facade] Delete Application Field Item Compare Items Success',
    props<{ id: number }>()
);

export const updateApplicationFieldItemCompareItemStatus = createAction(
    '[Application Field Item Compare Items Effects] Change status',
    props<{ applicationFieldItemCompareItemId: number }>()
);
