<div class="iframe-wrapper">
  <header class="header">
    <div class="header__items mr-auto">
      <div class="header__title">
        {{ data.title }}
      </div>
    </div>
    <div class="header__items">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </header>
  <iframe class="iframe" [src]="data.src | safe"></iframe>
</div>
