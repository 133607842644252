import { createAction, props } from '@ngrx/store';
import { ApplicationFieldCompare } from '../../../../core/models/application-field/application-field-сompare.model';
import { ApplicationFieldCompareItem } from '../../../../core/models/application-field/application-field-compare-item.model';

export const loadApplicationFieldItemCompares = createAction(
    '[Application Field Item Compares Facade] Load Application Field Item Compares'
);
export const loadApplicationFieldItemComparesSuccess = createAction(
    '[Application Field Item Compares Effects] Load Application Field Item Compares Success',
    props<{ applicationFieldItemCompares: Array<ApplicationFieldCompare> }>()
);

export const createApplicationFieldItemCompare = createAction(
    '[Application Field Item Compares Facade] Create Application Field Item Compare',
    props<{
        applicationFieldItemCompare: ApplicationFieldCompare;
        applicationFieldItemCompareItems: Array<ApplicationFieldCompareItem>;
    }>()
);
export const createApplicationFieldItemCompareSuccess = createAction(
    '[Application Field Item Compares Effects] Create Application Field Item Compare Success',
    props<{ applicationFieldItemCompare: ApplicationFieldCompare }>()
);

export const updateApplicationFieldItemCompare = createAction(
    '[Application Field Item Compares Facade] Update Application Field Item Compare',
    props<{ applicationFieldItemCompare: ApplicationFieldCompare }>()
);
export const updateApplicationFieldItemCompareSuccess = createAction(
    '[Application Field Item Compares Effects] Update Application Field Item Compare Success',
    props<{ applicationFieldItemCompare: ApplicationFieldCompare }>()
);
