import { createAction, props } from '@ngrx/store';
import { ProvisionTenant } from '../../models/provision-tenant/provision-tenant.model';

export const loadProvisionTenants = createAction('[Provision Tenants Facade] Load Provision Tenants');
export const loadProvisionTenantsSuccess = createAction(
    '[Load Provision Tenants Effect] Provision Tenants Loaded',
    props<{ provisionTenants: Array<ProvisionTenant> }>()
);

export const loadProvisionTenantById = createAction(
    '[Provision Tenant Facade] Load Provision Tenant By Id',
    props<{ id: number }>()
);
export const loadProvisionTenantByIdSuccess = createAction(
    '[Provision Tenant Effect] Load Provision Tenant By Id Success',
    props<{ provisionTenant: ProvisionTenant }>()
);
