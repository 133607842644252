import { createAction, props } from '@ngrx/store';
import { ApplicationFieldQuestion } from '../../../../core/models/application-field/application-field-question.model';

export const loadApplicationFieldItemQuestions = createAction(
    '[Application Field Item Questions Facade] Load Application Field Item Questions',
    props<{ id: number }>()
);
export const loadApplicationFieldItemQuestionsSuccess = createAction(
    '[Application Field Item Questions Effects] Load Application Field Item Questions Success',
    props<{ applicationFieldItemQuestions: Array<ApplicationFieldQuestion>; id: number }>()
);

export const upsertApplicationFieldItemQuestions = createAction(
    '[Application Field Item Questions Facade] Upsert Application Field Item Questions',
    props<{ applicationFieldItemQuestions: Array<ApplicationFieldQuestion> }>()
);
export const upsertApplicationFieldItemQuestionsSuccess = createAction(
    '[Application Field Item Questions Effects] Upsert Application Field Item Questions Success',
    props<{ applicationFieldItemQuestions: Array<ApplicationFieldQuestion> }>()
);
