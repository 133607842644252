import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ApplicationFieldItemCompareItemsActions } from '../../../../core/state/action-types/action-types';
import { ApplicationFieldCompareItem } from '../../../../core/models/application-field/application-field-compare-item.model';

export const applicationFieldItemCompareItemFeatureKey = 'applicationFieldItemCompareItem';

export interface ApplicationFieldItemCompareItemsState extends EntityState<ApplicationFieldCompareItem> {
    loading: boolean;
    loaded: boolean;
    loadedEntitiesById: Array<number>;
}

export const adapter = createEntityAdapter<ApplicationFieldCompareItem>({
    selectId: (applicationFieldItemCompareItem: ApplicationFieldCompareItem) => applicationFieldItemCompareItem.id
});

export const initialApplicationFieldItemCompareItemsState = adapter.getInitialState({
    loading: false,
    loaded: false,
    loadedEntitiesById: []
});

export const applicationFieldItemCompareItemsReducer = createReducer(
    initialApplicationFieldItemCompareItemsState,
    on(ApplicationFieldItemCompareItemsActions.loadApplicationFieldItemCompareItems, (state, action) => {
        if (state.loadedEntitiesById.some((x) => x === action.id)) {
            return {
                ...state,
                loading: false
            };
        }
        return {
            ...state,
            loading: true
        };
    }),
    on(ApplicationFieldItemCompareItemsActions.loadApplicationFieldItemCompareItemsSuccess, (state, action) =>
        adapter.upsertMany(action.applicationFieldItemCompareItems, {
            ...state,
            loading: false,
            loaded: true,
            loadedEntitiesById: [...state.loadedEntitiesById, action.id]
        })
    ),
    on(ApplicationFieldItemCompareItemsActions.upsertApplicationFieldItemCompareItemsSuccess, (state, action) => {
        return adapter.upsertMany(action.applicationFieldItemCompareItems, state);
    }),
    on(ApplicationFieldItemCompareItemsActions.deleteApplicationFieldItemCompareItemsSuccess, (state, action) =>
        adapter.removeOne(action.id, state)
    )
);

export const { selectAll } = adapter.getSelectors();
