<mat-form-field class="w-full" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [formControl]="autocompleteFilterControl"
    [matAutocomplete]="auto"
    (change)="onInputChange()"
    (blur)="onBlur()">

  <mat-autocomplete
    #auto
    [displayWith]="displayInput"
    (optionSelected)="onOptionSelected($event.option.value)"
  >
    <ng-container *ngLet="options$ | async | orderBy: inputDisplay as options ">
      <mat-option *ngIf="options?.length == 0 && !(loading$ | async)" disabled>
        No items found...
      </mat-option>

      <mat-option *ngIf="loading$ | async else items" disabled>
        <div class="flex items-center gap-x-2">
          <mat-spinner mode="indeterminate" diameter="25"></mat-spinner>
          <span>Loading...</span>
        </div>
      </mat-option>

      <ng-template #items>
        <mat-option
          *ngFor="let option of options"
          [value]="option"
        >
          <ng-container
            [ngTemplateOutlet]="autocompleteOption?.ref ?? defaultTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        </mat-option>
      </ng-template>

    </ng-container>
  </mat-autocomplete>

  <mat-error [errorFor]="autocompleteFilterControl"></mat-error>
</mat-form-field>

<ng-template #defaultTemplate let-option>
  {{ displayOption(option) }}
</ng-template>
