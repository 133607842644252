import { createAction, props } from '@ngrx/store';
import { Affiliate } from '../../models/affiliate/affiliate.model';

export const loadAffiliates = createAction('[Affiliates Facade] Load Affiliates');
export const loadAffiliatesSuccess = createAction(
    '[Load Affiliates Effect] Affiliates Loaded',
    props<{ affiliates: Array<Affiliate> }>()
);

export const loadAffiliateById = createAction('[Affiliates Facade] Load Affiliate By Id', props<{ id: number }>());
export const loadAffiliateByIdSuccess = createAction(
    '[Affiliate Effect] Load Affiliate By Id Success',
    props<{ affiliate: Affiliate }>()
);

export const upsertAffiliate = createAction('[Affiliates Facade] Upsert Affiliate', props<{ affiliate: Affiliate }>());
export const upsertAffiliateSuccess = createAction(
    '[Affiliates Effect] Upsert Affiliate Success',
    props<{ affiliate: Affiliate }>()
);

export const deleteAffiliate = createAction('[Affiliates Facade] Delete Affiliate', props<{ id: number }>());
export const deleteAffiliateSuccess = createAction('[Affiliates Effect] Delete Affiliate Success', props<{ id: number }>());

export const updateAffiliateStatus = createAction('[Affiliates Effects] Change status', props<{ affiliateId: number }>());
