import { createAction, props } from '@ngrx/store';
import { Connector } from '../../../core/models/connector/connector/connector.model';

export const loadConnectors = createAction('[Connectors Facade] Load Connectors');
export const loadConnectorsSuccess = createAction(
  '[Load Connectors Effect] Connectors Loaded',
  props<{ connectors: Array<Connector> }>()
);

export const loadConnectorById = createAction('[Connectors Facade] Load Connector By Id', props<{ id: number }>());
export const loadConnectorByIdSuccess = createAction(
  '[Connectors Effect] Load Connector By Id Loaded',
  props<{ connector: Connector }>()
);

export const upsertConnector = createAction('[Connectors Facade] Create Connector', props<{ connector: Connector }>());
export const upsertConnectorSuccess = createAction(
  '[Connectors Effect] Create Connector Success',
  props<{ connector: Connector }>()
);

export const cloneConnector = createAction(
  '[Connectors Effect] Clone Connector',
  props<{ connector: Connector }>()
);

export const deleteConnector = createAction('[Connectors Facade] Delete Connector', props<{ connectorId: number }>());
export const deleteConnectorSuccess = createAction(
  '[Connectors Effects] Delete Connector Success',
  props<{ connectorId: number }>()
);

export const updateConnectorStatus = createAction('[Connectors Effects] Change status', props<{ connectorId: number }>());
