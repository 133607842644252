import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { selectCurrentUser } from '../../user-profile/state/current-user.selectors';
import { map, switchMap, tap } from 'rxjs/operators';
import { Permissions } from '../../../core/authorization/permissions';
import { DataMapFieldsActions } from '../../../core/state/action-types/action-types';
import { DataMapField } from '../../../core/models/data-map-field/data-map-field.model';
import {
    selectCurrentIntegrationDataMapFields,
    selectDataMapFieldById,
    selectFilteredDataMapFields,
    selectLoaded,
    selectLoading
} from './data-map-fields.selectors';

@Injectable()
export class DataMapFieldsFacade {
    public readonlySection$: Observable<boolean>;
    public dataMapFields$: Observable<Array<DataMapField>>;
    public selectedDataMapField$: Observable<DataMapField>;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;

    constructor(private store: Store<AppState>) {
        this.readonlySection$ = this.store.pipe(
            select(selectCurrentUser),
            map(
                (user) =>
                    !user.roles.some((role) =>
                        role.permissions.some((permission) => permission === Permissions.DataMapFieldsEdit || permission === Permissions.PartnerIntegrationUserEdit || permission === Permissions.PartnerIntegrationClientEdit)
                    )
            )
        );

        this.dataMapFields$ = this.store.select(selectCurrentIntegrationDataMapFields());

        this.loading$ = store.select(selectLoading);
        this.loaded$ = store.select(selectLoaded);

        store
            .select(selectCurrentUser)
            .pipe(tap((user) => this.store.dispatch(DataMapFieldsActions.loadDataMapFields({ integrationId: user?.selectedIntegrationId }))))
            .subscribe();
    }

    public selectFilteredDataMapFields(filteredDataMapFieldsIds: Array<number>): Observable<Array<DataMapField>> {
        return this.store.select(selectFilteredDataMapFields(filteredDataMapFieldsIds));
    }

    public loadDataMapFieldById(id: number): void {
        this.selectedDataMapField$ = this.store.select(selectDataMapFieldById(id));
        this.store.dispatch(DataMapFieldsActions.loadDataMapFieldById({ id }));
    }

    public updateDataMapField(dataMapField: DataMapField): void {
        this.store.dispatch(DataMapFieldsActions.updateDataMapField({ dataMapField }));
    }

    public updateDataMapFields(dataMapFields: Array<DataMapField>): void {
        this.store.dispatch(DataMapFieldsActions.updateDataMapFields({ dataMapFields }));
    }
    public updateDataMapFieldFromTable(dataMapField: DataMapField): void {
        this.store.dispatch(DataMapFieldsActions.updateDataMapFieldFromTable({ dataMapField }));
    }

    public createDataMapField(dataMapField: DataMapField): void {
        this.store.dispatch(DataMapFieldsActions.createDataMapField({ dataMapField }));
    }

    public createDataMapFields(dataMapFields: Array<DataMapField>, redirect: boolean): void {
        this.store.dispatch(DataMapFieldsActions.createDataMapFields({ dataMapFields, redirect }));
    }
}
