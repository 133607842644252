import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-readonly-textarea-with-popup',
  templateUrl: './readonly-textarea-with-popup.component.html',
  styleUrls: ['./readonly-textarea-with-popup.component.scss']
})
export class ReadonlyTextareaWithPopupComponent implements OnInit {

  @Input()
  public value: string;

  @Input()
  public isInput: boolean = false;

  @Output()
  public openSaveDialog = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }
}
