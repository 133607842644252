import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpTopic } from 'src/app/core/models/help-topic/help-topic.model';

@Component({
  selector: 'app-help-detail-popup',
  templateUrl: './help-detail-popup.component.html',
  styleUrls: ['./help-detail-popup.component.scss'],
})
export class HelpDetailPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public helpTopic: HelpTopic
  ) {
  }

}
