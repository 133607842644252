import { createAction, props } from '@ngrx/store';
import { ConnectorTenant } from 'src/app/core/models/connector-tenant/connector-tenant/connector-tenant.model';

export const loadConnectorTenants = createAction('[Connector Tenants Facade] Load Connector Tenants');
export const loadConnectorTenantsSuccess = createAction(
    '[Connector Tenants Effects] Load Connector Tenants Success',
    props<{ connectorTenants: Array<ConnectorTenant> }>()
);

export const upsertConnectorTenant = createAction(
    '[Connector Tenants Facade] Upsert Connector Tenant',
    props<{ connectorTenant: ConnectorTenant }>()
);
export const upsertConnectorTenantSuccess = createAction(
    '[Connector Tenants Effects] Upsert Connector Tenant Success',
    props<{ connectorTenant: ConnectorTenant }>()
);

export const deleteConnectorTenant = createAction('[Connector Tenants Facade] Delete Connector Tenant', props<{ id: number }>());
export const deleteConnectorTenantSuccess = createAction(
    '[Connector Tenants Effects] Delete Connector Tenant Success',
    props<{ id: number }>()
);

export const updateConnectorTenantStatus = createAction('[Connectors Tenant Effects] Change status', props<{ id: number }>());
