import { filter, first, tap } from 'rxjs/operators';
import { CurrentUserFacade } from '../../components/user-profile/state/current-user.facade';
import { AppState } from '../../store';
import { selectRefreshTokenRequestCompleted } from '../../components/user-profile/state/current-user.selectors';
import { select, Store } from '@ngrx/store';

export function appInitializer(currentUserFacade: CurrentUserFacade, store: Store<AppState>): () => Promise<unknown> {
  return () =>
    new Promise((resolve) => {
      currentUserFacade.refreshToken();
      store
        .pipe(
          select(selectRefreshTokenRequestCompleted),
          filter((completed) => completed),
          first(),
          tap(() => {
            resolve(true);
          })
        )
        .subscribe();
    });
}
