import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  ExpandedContentDirective,
  NoDataTemplateDirective,
  JpTableComponent,
} from './components';
import { CreateCellInjectorPipe, GetPipe } from './pipes';

@NgModule({
  declarations: [
    JpTableComponent,
    NoDataTemplateDirective,
    ExpandedContentDirective,
    CreateCellInjectorPipe,
    GetPipe
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCheckboxModule
  ],
  exports: [
    JpTableComponent,
    NoDataTemplateDirective,
    ExpandedContentDirective,
    GetPipe
  ]
})
export class TableModule {
}
