import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './application-field-item-xlat-items.reducer';
import {
    applicationFieldItemXlatItemFeatureKey,
    ApplicationFieldItemXlatItemsState
} from './application-field-item-xlat-items.reducer';

const selectApplicationFieldItemXlatItemsState = createFeatureSelector<ApplicationFieldItemXlatItemsState>(
    applicationFieldItemXlatItemFeatureKey
);

export const selectLoading = createSelector(selectApplicationFieldItemXlatItemsState, (state) => state.loading);

export const selectLoaded = createSelector(selectApplicationFieldItemXlatItemsState, (state) => state.loaded);

export const selectApplicationFieldItemXlatItem = createSelector(selectApplicationFieldItemXlatItemsState, fromReducer.selectAll);

export const selectApplicationFieldItemXlatItemById = (id: number) =>
    createSelector(selectApplicationFieldItemXlatItem, (applicationFieldItemXlatItems) =>
        applicationFieldItemXlatItems.find((item) => item.id === id)
    );

export const selectApplicationFieldItemXlatItems = (id: number) =>
    createSelector(selectApplicationFieldItemXlatItemsState, (state) =>
        state.ids.map((x) => state.entities[x]).filter((x) => x.applicationFieldXlat.id === id)
    );

export const selectLoadedEntitiesById = createSelector(
    selectApplicationFieldItemXlatItemsState,
    (state) => state.loadedEntitiesById
);
