import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { HelpTopic } from '../../../../core/models/help-topic/help-topic.model';

export const loadHelpTopics = createAction('[Help Topics Facade] Load Help Topics');
export const loadHelpTopicsSuccess = createAction(
    '[Load Help Topics Effect] Help Topics Loaded',
    props<{ helpTopics: Array<HelpTopic> }>()
);

export const loadHelpTopicById = createAction('[Help Topics Facade] Load Help Topic By Id', props<{ id: number }>());
export const loadHelpTopicByIdSuccess = createAction(
    '[Help Topics Effect] Load Help Topic By Id Success',
    props<{ helpTopic: HelpTopic }>()
);

export const upsertHelpTopic = createAction('[Help Topics Facade] Upsert Help Topic', props<{ helpTopic: HelpTopic }>());
export const upsertHelpTopicSuccess = createAction(
    '[Help Topics Effect] Upsert Help Topic Success',
    props<{ helpTopic: HelpTopic }>()
);

export const updateHelpTopicFromTable = createAction('[Help Topics Facade] Update Help Topic', props<{ helpTopic: HelpTopic }>());
export const updateHelpTopicFromTableSuccess = createAction(
    '[Help Topics Effects] Update Help Topic',
    props<{ update: Update<HelpTopic> }>()
);

export const deleteHelpTopic = createAction('[Help Topics Facade] Delete Help Topic', props<{ id: number }>());
export const deleteHelpTopicSuccess = createAction('[Help Topics Effect] Delete Help Topic Success', props<{ id: number }>());
