import { User } from '../../core/models/user/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/core/app-constants';

export const newUpdateUserForm = (user: User, readonlySection: boolean) => {
    const form = new FormGroup({
        id: new FormControl(user.id),
        userName: new FormControl(user.userName, [Validators.required, Validators.maxLength(AppConstants.NAME_MAX_LENGTH),
            Validators.pattern(/^[a-zA-Z0-9\-._@+]+$/)
        ]),
        firstName: new FormControl(user.firstName, [Validators.required, Validators.maxLength(AppConstants.NAME_MAX_LENGTH)]),
        lastName: new FormControl(user.lastName, [Validators.required, Validators.maxLength(AppConstants.NAME_MAX_LENGTH)]),
        companyName: new FormControl(user.companyName, [Validators.required, Validators.maxLength(AppConstants.NAME_MAX_LENGTH)]),
        title: new FormControl(user.title, [Validators.maxLength(AppConstants.NAME_MAX_LENGTH)]),
        email: new FormControl(user.email, [Validators.required, Validators.email]),
        roles: new FormControl(user.roles, [Validators.required]),
        client: new FormControl(user.client, [Validators.maxLength(AppConstants.NAME_MAX_LENGTH)]),
        partner: new FormControl(user.partner, [Validators.maxLength(AppConstants.NAME_MAX_LENGTH)]),
        type: new FormControl(user.type),
        resource: new FormControl(0),
        comment: new FormControl(user.comment, [Validators.maxLength(AppConstants.COMMENTS_MAX_LENGTH)]),
        status: new FormControl(user.status),
        tenantId: new FormControl<string | null>(user.tenantId, [Validators.required]),
        lastLogin: new FormControl<Date>(user.lastLogin),
    });

    if (readonlySection) {
        form.disable();
    }

    return form;
};

export const mapFormToUser = (form: FormGroup): User => {
    return {
        id: form.controls.id.value,
        userName: form.controls.userName.value,
        firstName: form.controls.firstName.value,
        lastName: form.controls.lastName.value,
        companyName: form.controls.companyName.value,
        title: form.controls.title.value,
        email: form.controls.email.value,
        client: form.controls.client.value,
        partner: form.controls.partner.value,
        comment: form.controls.comment.value,
        roles: form.controls.roles.value,
        status: form.controls.status.value,
        tenantId: form.controls.tenantId.value?.tenantId ? form.controls.tenantId.value.tenantId : form.controls.tenantId.value,
        type: form.controls.type.value,
        lastLogin: form.controls.lastLogin.value
    } as User;
};
