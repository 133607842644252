import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DataMapFieldsActions } from '../../../core/state/action-types/action-types';
import { Status } from '../../../core/enums/status';
import { DataMapField } from '../../../core/models/data-map-field/data-map-field.model';

export const dataMapFieldsFeatureKey = 'dataMapFields';

export interface DataMapFieldsState extends EntityState<DataMapField> {
    loading: boolean;
    loaded: boolean;
    loadedEntitiesById: Array<number>;
}

export const adapter = createEntityAdapter<DataMapField>();

export const initialDataMapFieldsState = adapter.getInitialState({ loading: false, loaded: false, loadedEntitiesById: [] });

export const dataMapFieldsReducer = createReducer(
    initialDataMapFieldsState,
    on(DataMapFieldsActions.loadDataMapFields, (state) => {
        if (state.loaded) {
            return { ...state, loading: false };
        }
        return { ...state, loading: true };
    }),
    on(DataMapFieldsActions.loadDataMapFieldsSuccess, (state, action) => {
        return adapter.setAll(action.dataMapFields, { ...state, loading: false, loaded: true });
    }),
    on(DataMapFieldsActions.loadDataMapFieldByIdSuccess, (state, action) =>
        adapter.setOne(action.dataMapField, {
            ...state,
            loadedEntitiesById: [...state.loadedEntitiesById, action.dataMapField.id]
        })
    ),
    on(DataMapFieldsActions.updateDataMapFieldSuccess, (state, action) => {
        if (action.dataMapField.status === Status.Inactive) {
            return adapter.removeOne(action.dataMapField.id, state);
        }
        return adapter.upsertOne(action.dataMapField, state);
    }),
    on(DataMapFieldsActions.createDataMapFields, () => ({
        ...initialDataMapFieldsState
    })),
    on(DataMapFieldsActions.createDataMapFieldSuccess, (state, action) => {
        if (action.dataMapField.status === Status.Inactive) {
            return;
        }
        return adapter.upsertOne(action.dataMapField, state);
    }),
    on(DataMapFieldsActions.updateDataMapFieldsSuccess, (state, action) => {
        return adapter.upsertMany(action.dataMapFields, state);
    }),
    on(DataMapFieldsActions.updateDataMapFieldFromTableSuccess, (state, action) => {
        if (action.dataMapField.changes.status === Status.Inactive) {
            return adapter.removeOne(action.dataMapField.id as number, state);
        }
        return adapter.updateOne(action.dataMapField, state);
    })
);

export const { selectAll } = adapter.getSelectors();
