import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { AppConstants } from '../../app-constants';

@Injectable({
    providedIn: 'root'
})
export class SnackBarDataService {
    constructor(private snackBar: MatSnackBar) {}

    public showSnackBar(iconName: string, text: string, iconColor: string = ''): void {
        const snackBarConfig: MatSnackBarConfig = {
            horizontalPosition: 'right',
            data: { iconName, iconColor, text }
        };

        snackBarConfig.duration = AppConstants.SNACK_BAR_DURATION;
        this.snackBar.openFromComponent(SnackBarComponent, snackBarConfig);
    }
}
