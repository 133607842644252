import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CurrentUserFacade } from 'src/app/components/user-profile/state/current-user.facade';
import { HelpTopic } from 'src/app/core/models/help-topic/help-topic.model';
import { UserProfile } from 'src/app/core/models/user/user-profile.model';
import { HelpTopicsService } from 'src/app/core/services/help-topics/help-topics.service';
import { HelpDetailPopupNosearchComponent } from '../help-detail-popup-nosearch/help-detail-popup-nosearch.component';
import { HelpDetailPopupComponent } from '../help-detail-popup/help-detail-popup.component';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {

  @Input()
  public helpTopicId: number;

  @Input()
  public helpId: string;

  public helpTopicMessage: Observable<string>;

  public helpTopic: HelpTopic;

  public user$: Observable<UserProfile>;

  private currentUserRole: string;

  constructor(
    private helpTopicService: HelpTopicsService,
    private dialog: MatDialog,
    private currentUserFacade: CurrentUserFacade
  ) {
  }

  public ngOnInit(): void {
    if (this.helpTopicId !== undefined) {
      this.helpTopicMessage = this.helpTopicService.getHelpTopicById(this.helpTopicId).pipe(
        tap(topic => this.helpTopic = topic),
        map(topic => topic.description)
      );
    } else if (!!this.helpId) {
      this.helpTopicMessage = this.helpTopicService.getHelpTopicByHelpId(this.helpId).pipe(
        tap(topic => this.helpTopic = topic),
        map(topic => topic.description)
      );
    }

    this.user$ = this.currentUserFacade.currentUser$;
    this.user$.subscribe(value => this.currentUserRole = value.roles[0].name);
  }

  public openHelpDetailPopup(): void {
    if (this.currentUserRole.toLowerCase() == 'Admin'.toLowerCase()) {
      this.dialog.open(HelpDetailPopupComponent, {
        width: '40%',
        data: this.helpTopic
      });
    } else {
      this.dialog.open(HelpDetailPopupNosearchComponent, {
        width: '40%',
        data: this.helpTopic
      });
    }
  }
}
