import { createAction, props } from '@ngrx/store';
import { ApplicationFieldXlat } from '../../../../core/models/application-field/application-field-xlat.model';
import { ApplicationFieldXlatItem } from '../../../../core/models/application-field/application-field-xlat-item.model';

export const loadApplicationFieldItemXlats = createAction(
    '[Application Field Item Xlats Facade] Load Application Field Item Xlats',
    props<{ tenantId: string }>()
);

export const loadApplicationFieldItemXlatsSuccess = createAction(
    '[Application Field Item Xlats Effects] Load Application Field Item Xlats Success',
    props<{ applicationFieldItemXlats: Array<ApplicationFieldXlat> }>()
);

export const createApplicationFieldItemXlat = createAction(
    '[Application Field Item Xlats Facade] Create Application Field Item Xlat',
    props<{ applicationFieldItemXlat: ApplicationFieldXlat; applicationFieldItemXlatItems: Array<ApplicationFieldXlatItem> }>()
);
export const createApplicationFieldItemXlatSuccess = createAction(
    '[Application Field Item Xlats Effects] Create Application Field Item Xlat Success',
    props<{ applicationFieldItemXlat: ApplicationFieldXlat }>()
);

export const updateApplicationFieldItemXlat = createAction(
    '[Application Field Item Xlats Facade] Update Application Field Item Xlat',
    props<{ applicationFieldItemXlat: ApplicationFieldXlat }>()
);
export const updateApplicationFieldItemXlatSuccess = createAction(
    '[Application Field Item Xlats Effects] Update Application Field Item Xlat Success',
    props<{ applicationFieldItemXlat: ApplicationFieldXlat }>()
);
