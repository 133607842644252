import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrentUserFacade } from '../../components/user-profile/state/current-user.facade';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private currentUserFacade: CurrentUserFacade, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): Observable<boolean> {
        return this.currentUserFacade.isLoggedIn$.pipe(
            tap((loggedIn) => {
                if (!loggedIn) {
                    // not logged in so redirect to login page with the return url
                    this.router.navigate(['/login'], { queryParams: { returnUrl: stateSnapshot.url } });
                }
            })
        );
    }
}
