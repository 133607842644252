import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogComponent } from '../../../shared/components/shared-dialog/shared-dialog.component';
import { SharedDialogType } from '../../../shared/components/shared-dialog/models/shared-dialog-type';

@Injectable({
    providedIn: 'root'
})
export class DiscardDataService {
    constructor(private dialog: MatDialog) {}

    openConfirmationDialog(): MatDialogRef<SharedDialogComponent> {
        return this.dialog.open(SharedDialogComponent, {
            width: '25%',
            data: {
                title: 'Discard all changes?',
                sharedDialogType: SharedDialogType.Confirmation
            }
        });
    }
}
