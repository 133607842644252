import { createAction, props } from '@ngrx/store';
import { AutoSignOffDuration } from '../../../../core/models/admin-panel/auto-sign-off.model';

export const loadAutoSignOffDurations = createAction('[Auto Sign Off Durations Facade] Load Auto Sign Off Durations');
export const loadAutoSignOffDurationsSuccess = createAction(
    '[Auto Sign Off Durations Effects] Load Auto Sign Off Durations Success',
    props<{ autoSignOffDurations: Array<AutoSignOffDuration> }>()
);

export const updateAutoSignOffDuration = createAction(
    '[Auto Sign Off Durations Facade] Update Auto Sign Off Duration',
    props<{ autoSignOffDuration: AutoSignOffDuration }>()
);
export const updateAutoSignOffDurationSuccess = createAction(
    '[Auto Sign Off Durations Effect] Update Auto Sign Off Duration Success',
    props<{ autoSignOffDuration: AutoSignOffDuration }>()
);

export const createAutoSignOffDuration = createAction(
    '[Auto Sign Off Durations Facade] Create Auto Sign Off Duration',
    props<{ autoSignOffDuration: AutoSignOffDuration }>()
);
export const createAutoSignOffDurationSuccess = createAction(
    '[Auto Sign Off Durations Effect] Create Auto Sign Off Duration Success',
    props<{ autoSignOffDuration: AutoSignOffDuration }>()
);

export const deleteAutoSignOffDuration = createAction(
    '[Auto Sign Off Durations Facade] Delete Auto Sign Off Duration',
    props<{ id: number }>()
);
export const deleteAutoSignOffDurationSuccess = createAction(
    '[Auto Sign Off Durations Effect] Delete Auto Sign Off Duration Success',
    props<{ id: number }>()
);
