import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from '../../../shared/components/shared-dialog/shared-dialog.component';
import { SharedDialogType } from '../../../shared/components/shared-dialog/models/shared-dialog-type';
import { ApplicationFieldsService } from '../../../core/services/application-fields/application-fields.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import differenceWith from 'lodash-es/differenceWith';
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import differenceBy from 'lodash-es/differenceBy';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { AppConstants } from '../../../core/app-constants';
import { ApplicationFieldCompareItem } from 'src/app/core/models/application-field/application-field-compare-item.model';
import { ApplicationFieldItemCompareItemsFacade } from '../state/application-field-item-compare-items/application-field-item-compare-items.facade';
import { ApplicationFieldCompare } from '../../../core/models/application-field/application-field-сompare.model';
import { ApplicationFieldItemComparesFacade } from '../state/application-field-item-compares/application-field-item-compares.facade';
import { DiscardDataService } from '../../../core/services/discard-data/discard-data.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-application-field-compare-items',
    templateUrl: './application-field-compare-items.component.html',
    styleUrls: ['./application-field-compare-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class ApplicationFieldCompareItemsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();

    public dataSource = new MatTableDataSource<ApplicationFieldCompareItem>();
    public displayedColumns: string[] = ['selected', 'valueInput', 'valueOperat', 'valueCompare'];
    public displayedColumnsWithExpand = [...this.displayedColumns, 'expand'];
    public expandedElement: ApplicationFieldCompareItem | null;
    public originalApplicationFieldCompareItems = new Array<ApplicationFieldCompareItem>();
    public selection = new SelectionModel<ApplicationFieldCompareItem>(true);
    public inputPlaceholder = AppConstants.INPUT_PLACEHOLDER;
    public maxLength = 128;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;
    public originApplicationFieldItemCompare: ApplicationFieldCompare;

    @Input() public readonlySection: boolean;
    @Input() public selectedApplicationFieldCompare$: BehaviorSubject<ApplicationFieldCompare>;
    @Input() public sidenav: MatSidenav;

    @Output() public deleteAllClick = new EventEmitter();
    @Output() public itemsChanged = new EventEmitter<boolean>();
    @Output() public closeSidenavClick = new EventEmitter();

    @ViewChild('table') table;

    constructor(
        private applicationFieldsService: ApplicationFieldsService,
        private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private applicationFieldItemCompareItemsFacade: ApplicationFieldItemCompareItemsFacade,
        private applicationFieldItemComparesFacade: ApplicationFieldItemComparesFacade,
        private discardDataService: DiscardDataService
    ) {}

    ngOnInit(): void {
        this.loading$ = this.applicationFieldItemCompareItemsFacade.loading$;
        this.loaded$ = this.applicationFieldItemCompareItemsFacade.loaded$;
        this.getApplicationFieldCompareItems();
        this.sidenav.closedStart.pipe(tap(() => this.resetValues(), takeUntil(this.destroy$))).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getApplicationFieldCompareItems(): void {
        this.selectedApplicationFieldCompare$
            .pipe(
                switchMap((applicationFieldCompare) => {
                    if (applicationFieldCompare) {
                        this.originApplicationFieldItemCompare = cloneDeep(applicationFieldCompare);
                        this.applicationFieldItemCompareItemsFacade.loadApplicationFieldItemCompareItems(
                            applicationFieldCompare.id
                        );

                        return this.applicationFieldItemCompareItemsFacade.applicationFieldItemCompareItems$.pipe(
                            tap((applicationFieldCompareItems) => {
                                this.dataSource.data = cloneDeep(applicationFieldCompareItems);
                                this.originalApplicationFieldCompareItems = cloneDeep(applicationFieldCompareItems);
                                this.changeDetector.detectChanges();
                            })
                        );
                    }

                    return of();
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    public saveApplicationFieldCompareItems(): void {
        const itemsToDelete = differenceBy(
            this.originalApplicationFieldCompareItems,
            this.dataSource.data.filter((item) => item.id),
            'id'
        );
        this.deleteApplicationFieldCompareItems(itemsToDelete);

        if (
            this.dataSource.data.some(
                (item) =>
                    // !item.valueElse ||
                    !item.valueThen || item.valueElse.length > this.maxLength || item.valueThen.length > this.maxLength
            )
        ) {
            return;
        }

        const itemsToUpsert = differenceWith(this.dataSource.data, this.originalApplicationFieldCompareItems, isEqual);
        this.upsertApplicationFieldCompareItems(itemsToUpsert);

        this.sidenav.close();
    }

    public upsertApplicationFieldCompareItems(itemsToUpsert: Array<ApplicationFieldCompareItem>): void {
        if (this.applicationFieldCompareHasChanged()) {
            this.applicationFieldItemComparesFacade.updateApplicationFieldItemCompare(
                this.selectedApplicationFieldCompare$.value
            );
        }
        this.applicationFieldItemCompareItemsFacade.upsertApplicationFieldItemCompareItems(itemsToUpsert);
    }

    public deleteApplicationFieldCompareItems(itemsToDelete: Array<ApplicationFieldCompareItem>): void {
        itemsToDelete.map((item) => this.applicationFieldItemCompareItemsFacade.deleteApplicationFieldItemCompareItems(item.id));
    }

    public deleteAllCompareRows(): void {
        const firstDialog = this.dialog.open(SharedDialogComponent, {
            width: '33%',
            data: {
                title: 'Are you sure you want to delete all?',
                confirmButtonText: 'ACCEPT',
                sharedDialogType: SharedDialogType.Confirmation
            }
        });

        firstDialog
            .afterClosed()
            .pipe(
                filter((firstResult) => firstResult),
                switchMap(() => {
                    const secondDialog = this.dialog.open(SharedDialogComponent, {
                        width: '33%',
                        data: {
                            title: 'Press delete to confirm',
                            confirmButtonText: 'DELETE',
                            sharedDialogType: SharedDialogType.Confirmation
                        }
                    });

                    return secondDialog.afterClosed().pipe(
                        filter((secondResult) => secondResult),
                        tap(() => {
                            this.deleteAllClick.emit();
                            this.sidenav.close();
                        })
                    );
                })
            )
            .subscribe();
    }

    public deleteCompareRows(): void {
        this.dataSource.data = this.dataSource.data.filter((x) => !this.selection.isSelected(x));
        this.selection.clear();
        this.itemsChanged.emit(this.itemsHasChanged());
    }

    public addApplicationFieldCompareItem(): void {
        const newRow = {
            sequence: 1,
            valueOperat: 'eq',
            status: 'A',
            valueThen: '',
            valueInput: '',
            valueElse: '',
            tenantId: AppConstants.DEFAULT_TENANT_ID,
            applicationFieldCompare: this.selectedApplicationFieldCompare$.value
        } as ApplicationFieldCompareItem;

        this.dataSource.data.push(newRow);
        this.dataSource._updateChangeSubscription();
        this.itemsChanged.emit(this.itemsHasChanged());
        this.focusLastRow();
    }

    public itemsHasChanged(): boolean {
        return (
            differenceWith(this.dataSource.data, this.originalApplicationFieldCompareItems, isEqual).length ||
            this.dataSource.data.length !== this.originalApplicationFieldCompareItems.length ||
            this.applicationFieldCompareHasChanged()
        );
    }

    public applicationFieldCompareHasChanged(): boolean {
        return (
            this.originApplicationFieldItemCompare.name !== this.selectedApplicationFieldCompare$.value.name ||
            this.originApplicationFieldItemCompare.valueCase !== this.selectedApplicationFieldCompare$.value.valueCase ||
            this.originApplicationFieldItemCompare.valueDefault !== this.selectedApplicationFieldCompare$.value.valueDefault
        );
    }

    private focusLastRow(): void {
        this.table?._elementRef.nativeElement
            .getElementsByTagName('tr')
            [this.dataSource.data.length].getElementsByTagName('input')[1]
            .focus();
    }

    private resetValues(): void {
        this.dataSource.data = [];
        this.originalApplicationFieldCompareItems = [];
        this.selection.clear();
    }

    public closeSidenav(): void {
        this.itemsHasChanged() ? this.showDiscardDialog() : this.closeSidenavClick.emit();
    }

    private showDiscardDialog(): void {
        const dialogRef = this.discardDataService.openConfirmationDialog();

        dialogRef
            .afterClosed()
            .pipe(
                tap((res) => {
                    if (res) {
                        this.closeSidenavClick.emit();
                    }
                })
            )
            .subscribe();
    }
}
