import { QuestionField } from '../../models/question-field/question-field.model';
import { createAction, props } from '@ngrx/store';

export const loadQuestionFields = createAction('[Question Field Facade] Load Question Fields');
export const loadQuestionFieldsSuccess = createAction(
    '[Question Field Effects] Load Question Fields Success',
    props<{ questionFields: Array<QuestionField> }>()
);

export const loadQuestionFieldById = createAction('[Question Fields Facade] Load Question Field By Id', props<{ id: number }>());
export const loadQuestionFieldByIdSuccess = createAction(
    '[Question Field Effect] Load Question Field By Id Success',
    props<{ questionField: QuestionField }>()
);
