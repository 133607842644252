<div mat-dialog-title class="flex justify-between items-center">
  <h2 class="grow">Client Integrations</h2>
  <button
    mat-icon-button
    [mat-dialog-close]="false"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <table *ngLet="user$ | async as user" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 main-table mt-0">
    <ng-container matColumnDef="integrationName">
      <th class="main-table__head main-table__head--transparent" mat-header-cell *matHeaderCellDef>INTEGRATION NAME</th>
      <td class="main-table__cell main-table__cell--black" mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="currentPhase">
      <th class="main-table__head main-table__head--transparent" mat-header-cell *matHeaderCellDef>CURRENT PHASE</th>
      <td class="main-table__cell main-table__cell--black" mat-cell *matCellDef="let element">
        {{ element.phase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th class="main-table__head main-table__head--transparent" mat-header-cell *matHeaderCellDef>STATUS</th>
      <td
        class="main-table__cell"
        [ngClass]="{ 'main-table__cell--orange': element.status === status.Active }"
        mat-cell
        *matCellDef="let element"
        [ngSwitch]="element.status"
      >
        <ng-container *ngSwitchCase="status.Active">Active</ng-container>
        <ng-container *ngSwitchCase="status.Inactive">Inactive</ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr (click)="selectIntegration(user.id, row.id)" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
