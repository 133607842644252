import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Injectable } from '@angular/core';
import {
    selectApplicationFieldItemCompareItems,
    selectLoaded,
    selectLoading
} from './application-field-item-compare-items.selector';
import { ApplicationFieldCompareItem } from '../../../../core/models/application-field/application-field-compare-item.model';
import { ApplicationFieldItemCompareItemsActions } from '../../../../core/state/action-types/action-types';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFieldItemCompareItemsFacade {
    public applicationFieldItemCompareItems$: Observable<Array<ApplicationFieldCompareItem>>;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;

    constructor(private store: Store<AppState>) {
        this.loading$ = this.store.select(selectLoading);
        this.loaded$ = this.store.select(selectLoaded);
    }

    public loadApplicationFieldItemCompareItems(id: number): void {
        this.applicationFieldItemCompareItems$ = this.store.select(selectApplicationFieldItemCompareItems(id));
        this.store.dispatch(ApplicationFieldItemCompareItemsActions.loadApplicationFieldItemCompareItems({ id }));
    }

    public upsertApplicationFieldItemCompareItems(applicationFieldItemCompareItems: Array<ApplicationFieldCompareItem>): void {
        this.store.dispatch(
            ApplicationFieldItemCompareItemsActions.upsertApplicationFieldItemCompareItems({
                applicationFieldItemCompareItems
            })
        );
    }

    public deleteApplicationFieldItemCompareItems(id: number): void {
        this.store.dispatch(ApplicationFieldItemCompareItemsActions.deleteApplicationFieldItemCompareItems({ id }));
    }
}
