<div mat-dialog-title class="flex justify-between items-center">
  <h2 *ngIf="data.heading" class="grow">{{ data.heading }}</h2>
  <span class="flex-auto"></span>
  <button
    mat-icon-button
    [mat-dialog-close]="false"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p class="text-center">{{ data.title }}</p>
</mat-dialog-content>

<mat-dialog-actions class="mt-5" [ngSwitch]="data.sharedDialogType" align="end">
  <button
    *ngSwitchCase="sharedDialogType.Continue"
    mat-button
    color="primary"
    [mat-dialog-close]="true"
  >
    {{ data.confirmButtonText }}
  </button>

  <ng-container *ngSwitchCase="sharedDialogType.Confirmation">
    <button mat-button color="accent" [mat-dialog-close]="false">
      {{ data.cancelButtonText ? data.cancelButtonText : 'CANCEL' }}
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true">
      {{ data.confirmButtonText }}
    </button>
  </ng-container>
</mat-dialog-actions>
