import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationFieldItem } from '../../models/application-field-item/application-field-item.model';
import { ApplicationFieldXlat } from '../../models/application-field/application-field-xlat.model';
import { ApplicationFieldCompare } from '../../models/application-field/application-field-сompare.model';
import { ApplicationFieldQuestion } from '../../models/application-field/application-field-question.model';
import { ApplicationField } from '../../models/application-field/application-field.model';
import { ApplicationFieldXlatItem } from '../../models/application-field/application-field-xlat-item.model';
import { ApplicationFieldCompareItem } from '../../models/application-field/application-field-compare-item.model';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFieldsService {
    uri = environment.apiUrl + '/applicationFields';

    constructor(private readonly http: HttpClient) {}

    public getApplicationFieldsByFilter(filterQuery: string): Observable<Array<ApplicationField>> {
        return this.http.get<Array<ApplicationField>>(`${this.uri}?filterQuery=${filterQuery}`);
    }

    public getApplicationFieldById(id: number): Observable<ApplicationField> {
        return this.http.get<ApplicationField>(`${this.uri}/${id}`);
    }

    public getApplicationFieldByName(name: string, excludeId?: number): Observable<ApplicationField> {
        name = encodeURIComponent(name);
        let url = `${this.uri}/${name}/`;
        if (excludeId) {
            url += excludeId;
        }
        return this.http.get<ApplicationField>(url);
    }

    public getApplicationFieldItemsByFilter(
        filterQuery: string,
        applicationFieldId: number
    ): Observable<Array<ApplicationFieldItem>> {
        return this.http.get<Array<ApplicationFieldItem>>(
            `${this.uri}/items/?filterQuery=${filterQuery}&applicationFieldId=${applicationFieldId}`
        );
    }

    public getApplicationFieldItemById(id: number): Observable<ApplicationFieldItem> {
        return this.http.get<ApplicationFieldItem>(`${this.uri}/items/${id}`);
    }

    public getApplicationFieldItemByName(
        applicationFieldId: number,
        name: string,
        excludeId?: number
    ): Observable<ApplicationFieldItem> {
        name = encodeURIComponent(name);
        let url = `${this.uri}/items/${applicationFieldId}/${name}/`;
        if (excludeId) {
            url += excludeId;
        }
        return this.http.get<ApplicationFieldItem>(url);
    }

    public getApplicationFieldQuestionsById(applicationFieldId: number): Observable<Array<ApplicationFieldQuestion>> {
        return this.http.get<Array<ApplicationFieldQuestion>>(`${this.uri}/${applicationFieldId}/questions`);
    }

    public getApplicationFieldXlatItemByName(name: string, tenantId: string): Observable<ApplicationFieldXlat> {
        name = encodeURIComponent(name);
        return this.http.get<ApplicationFieldXlat>(`${this.uri}/xlat-items/${tenantId}/${name}`);
    }

    public getApplicationFieldCompareItemByName(name: string, tenantId: string): Observable<ApplicationFieldCompare> {
        name = encodeURIComponent(name);
        return this.http.get<ApplicationFieldCompare>(`${this.uri}/compare-items/${tenantId}/${name}`);
    }

    public getApplicationFieldXlats(tenantId: string): Observable<Array<ApplicationFieldXlat>> {
        return this.http.get<Array<ApplicationFieldXlat>>(`${this.uri}/xlats?tenantId='${tenantId}'`);
    }

    public getApplicationFieldXlatItems(applicationFieldXlatId: number): Observable<Array<ApplicationFieldXlatItem>> {
        return this.http.get<Array<ApplicationFieldXlatItem>>(`${this.uri}/xlat-items/${applicationFieldXlatId}`);
    }

    public getCompares(): Observable<Array<ApplicationFieldCompare>> {
        return this.http.get<Array<ApplicationFieldCompare>>(`${this.uri}/compares`);
    }

    public getApplicationFieldCompareItems(applicationFieldCompareId: number): Observable<Array<ApplicationFieldCompareItem>> {
        return this.http.get<Array<ApplicationFieldCompareItem>>(`${this.uri}/compare-items/${applicationFieldCompareId}`);
    }

    public upsertApplicationField(items: Array<ApplicationField>): Observable<Array<ApplicationField>> {
        return this.http.put<Array<ApplicationField>>(`${this.uri}`, { items });
    }

    public cloneApplicationField(applicationField: ApplicationField): Observable<ApplicationField> {
        return this.http.put<ApplicationField>(`${this.uri}/clone`, { applicationField });
    }

    public upsertApplicationFieldItems(items: Array<ApplicationFieldItem>): Observable<Array<ApplicationFieldItem>> {
        return this.http.put<Array<ApplicationFieldItem>>(`${this.uri}/items`, { items });
    }

    public upsertApplicationFieldQuestion(items: Array<ApplicationFieldQuestion>): Observable<Array<ApplicationFieldQuestion>> {
        return this.http.put<Array<ApplicationFieldQuestion>>(`${this.uri}/questions`, { items });
    }

    public upsertApplicationFieldXlatItems(items: Array<ApplicationFieldXlatItem>): Observable<Array<ApplicationFieldXlatItem>> {
        return this.http.put<Array<ApplicationFieldXlatItem>>(`${this.uri}/xlat-items`, { items });
    }

    public createApplicationFieldItemXlats(items: Array<ApplicationFieldXlat>): Observable<Array<ApplicationFieldXlat>> {
        return this.http.put<Array<ApplicationFieldXlat>>(`${this.uri}/xlats`, { items });
    }

    public createApplicationFieldItemCompares(items: Array<ApplicationFieldCompare>): Observable<Array<ApplicationFieldCompare>> {
        return this.http.put<Array<ApplicationFieldCompare>>(`${this.uri}/compares`, { items });
    }

    public updateApplicationFieldItemXlats(items: Array<ApplicationFieldXlat>): Observable<Array<ApplicationFieldXlat>> {
        return this.http.patch<Array<ApplicationFieldXlat>>(`${this.uri}/xlats`, { items });
    }

    public updateApplicationFieldItemCompares(items: Array<ApplicationFieldCompare>): Observable<Array<ApplicationFieldCompare>> {
        return this.http.patch<Array<ApplicationFieldCompare>>(`${this.uri}/compares`, { items });
    }

    public upsertApplicationFieldCompareItems(
        items: Array<ApplicationFieldCompareItem>
    ): Observable<Array<ApplicationFieldCompareItem>> {
        return this.http.put<Array<ApplicationFieldCompareItem>>(`${this.uri}/compare-items`, { items });
    }

    public deleteApplicationField(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/${id}`);
    }

    public deleteApplicationFieldItem(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/item/${id}`);
    }

    public deleteApplicationFieldXlatItem(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/xlat-items/${id}`);
    }

    public deleteApplicationFieldCompareItem(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/compare-item/${id}`);
    }
}
