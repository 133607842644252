<button
    mat-icon-button
    *ngLet="helpTopicMessage | async as message"
    color="accent"
    [disabled]="!message"
    [matTooltip]="message"
    (click)="openHelpDetailPopup()"
>
    <mat-icon>info</mat-icon>
</button>
