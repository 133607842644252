<div *ngIf="loading | async" class="jp-table-loader">
  <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
</div>

<mat-table
  class="jp-table"
  matSort
  [matSortActive]="sortByField"
  matSortDirection="asc"
  [dataSource]="dataSource"
  [multiTemplateDataRows]="expandable"
  [trackBy]="this.trackBy"
>
  <ng-container *ngFor="let col of columns" [matColumnDef]="col.field">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!col.sort?.enabled"
      [disableClear]="col.sort?.disableClear ?? false"
      [start]="col.sort?.start ?? $any('')"
      [ngClass]="col.cssClass"
      class="pr-3"
    >
      {{ col.title }}
      <ng-container
        *ngIf="col.headerTemplate"
        [ngTemplateOutlet]="col.headerTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      [class.cell--expandable]="expandable"
      [class.cell--required]="col.required && !getItemValue(item, col.field)"
      [ngClass]="col.cssClass"
      (click)="onCellClick(item, col)"
      class="pr-3"
    >
      <ng-container
        *ngIf="col.component"
        [ngComponentOutlet]="col.component"
        [ngComponentOutletInjector]="injector | createCellInjector:item:col:col.componentData"
      ></ng-container>
      <ng-container
        *ngIf="col.template"
        [ngTemplateOutlet]="col.template"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
      <ng-container *ngIf="col.displayFn && !col.template && !col.component">{{ col.displayFn(item) }}</ng-container>
      <ng-container *ngIf="!col.displayFn && !col.template && !col.component">{{ item | get :col.field }}</ng-container>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="SELECT_COLUMN">
    <mat-header-cell *matHeaderCellDef class="cell--min-content pr-3">
      <mat-checkbox
        (change)="onSelectHeaderClick()"
        [checked]="allRowsSelected()"
        [indeterminate]="selectedItems.hasValue() && !allRowsSelected()"
      ></mat-checkbox>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="cell--min-content"
      [class.cell--expandable]="expandable"
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event && selectedItems.toggle(item)"
        [checked]="selectedItems.isSelected(item)"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="EXPANDER_COLUMN">
    <mat-header-cell *matHeaderCellDef class="cell--min-content pr-3">
      <button
        mat-icon-button
        (click)="onExpanderHeaderClick()"
      >
        <mat-icon *ngIf="expandedItems.isEmpty()">unfold_more</mat-icon>
        <mat-icon *ngIf="!expandedItems.isEmpty()">unfold_less</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="cell--min-content"
      [class.cell--expandable]="expandable"
    >
      <button
        mat-icon-button
        (click)="expandedItems.toggle(item); $event.stopPropagation()">
        <mat-icon *ngIf="expandedItems.isSelected(item)">keyboard_arrow_up</mat-icon>
        <mat-icon *ngIf="!expandedItems.isSelected(item)">keyboard_arrow_down</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="EXPANDED_CONTENT_COLUMN">
    <mat-cell *matCellDef="let item">
      <div
        class="cell--expanded"
        [@contentExpand]="expandedItems.isSelected(item) ? 'expanded' : 'collapsed'"
      >
        <ng-container
          *ngIf="expandedContent?.ref as expandedRef"
          [ngTemplateOutlet]="expandedRef"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <mat-row mat-header-row *matHeaderRowDef="visibleColumns"></mat-row>

  <mat-row
    *matRowDef="let row; columns: visibleColumns;"
    class="row--default"
    [class]="rowClassFn(row)"
    [class.row--selected]="selectedItems.isSelected(row)"
    (click)="onRowClick(row)"
  ></mat-row>

  <ng-container *ngIf="expandable">
    <mat-row *matRowDef="let row; columns: [EXPANDED_CONTENT_COLUMN]" class="row--expanded"></mat-row>
  </ng-container>

  <ng-container [matColumnDef]="GROUP_BY_HEADER_COLUMN">
    <td colspan="999" class="w-full" mat-cell *matCellDef="let group">
      {{group.groupName}}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="GROUP_BY_REDUCER_COLUMN">
    <td colspan="1" mat-cell *matCellDef="let group">
      <div>
        <button
        mat-icon-button>
        <mat-icon *ngIf="!group.reduced">expand_less</mat-icon>
        <mat-icon *ngIf="group.reduced">expand_more</mat-icon>
      </button>
      </div>
    </td>
  </ng-container>

  <ng-container>
    <tr mat-row class="grouped-header"
      *matRowDef="let row; columns: [GROUP_BY_REDUCER_COLUMN, GROUP_BY_HEADER_COLUMN]; when: isGroup"
      (click)="reduceGroup(row)">
    </tr>
  </ng-container>

  <div class="no-data-row" *matNoDataRow>
    <ng-container
      *ngIf="noDataTemplate?.ref as noDataRef else noDataDefault"
      [ngTemplateOutlet]="noDataRef"
    ></ng-container>

    <ng-template #noDataDefault>
      {{ noDataMessage }}
    </ng-template>
  </div>
</mat-table>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons>
</mat-paginator>
