import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-autocomplete-list-item',
    templateUrl: './autocomplete-list-item.component.html',
    styleUrls: ['./autocomplete-list-item.component.scss']
})
export class AutocompleteListItemComponent<T> implements OnInit {
    @Input() selectPlaceholder: string = 'Select Item';
    @Input() fieldName: string;
    @Input() items: T[] = [];
    @Input() displayField: string = 'name';
    @Input() selectedItemControl = new FormControl(null);
    @Input() isSideNav: boolean = false;
    @Input() displayFn: (item: T) => string = (item: T) => item ? item[this.displayField] : item;

    @Output() itemChanged = new EventEmitter<T | null>();

    filteredItems$: Observable<T[]> = of([]);
    private initialValue: T | null = null;

    public ngOnInit(): void {
        this.filteredItems$ = this.selectedItemControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filterItems(value))
        );
        this.initialValue = this.items.find(i => i[this.displayField] == this.selectedItemControl.value);
        this.selectedItemControl.setValue(this.initialValue);
    }

    public clearValue(control: FormControl): void {
        control.setValue(null);
    }

    public resetValue(): void {
        if (!this.selectedItemControl.value) {
            this.selectedItemControl.setValue(this.initialValue);
        }
    }

    public optionLabel(item: T): string {
        return this.displayFn(item);
    }

    private filterItems(value: string | T): T[] {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        return this.items.filter(option =>
            this.optionLabel(option).toLowerCase().includes(filterValue)
        );
    }
}
