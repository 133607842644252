import { createAction, props } from '@ngrx/store';
import { FieldFormat } from '../../models/field-format/field-format.model';

export const loadFieldFormats = createAction('[Field Format Facade] Load Field Formats');
export const loadFieldFormatsSuccess = createAction(
    '[Field Format Effects] Load Field Formats Success',
    props<{ fieldFormats: Array<FieldFormat> }>()
);

export const loadFieldFormatById = createAction('[Field Formats Facade] Load Field Format By Id', props<{ id: number }>());
export const loadFieldFormatByIdSuccess = createAction(
    '[Field Format Effect] Load Field Format By Id Success',
    props<{ fieldFormat: FieldFormat }>()
);

export const upsertFieldFormat = createAction(
    '[Field Formats Facade] Upsert Field Format',
    props<{ fieldFormat: FieldFormat }>()
);
export const upsertFieldFormatSuccess = createAction(
    '[Field Formats Effect] Upsert Field Format Success',
    props<{ fieldFormat: FieldFormat }>()
);

export const deleteFieldFormat = createAction('[Field Formats Facade] Delete Field Format', props<{ id: number }>());
export const deleteFieldFormatSuccess = createAction(
    '[Field Formats Effect] Delete Field Format Success',
    props<{ id: number }>()
);

export const updateFieldFormatStatus = createAction('[Field Formats Effects] Change status', props<{ fieldFormatId: number }>());
