<header class="header header--sidebar">
  <div class="header__items mr-auto">
    <div class="header__title">COMPARE</div>
  </div>
  <div class="flex items-center gap-x-4">
    <button
      *ngIf="!readonlySection"
      mat-stroked-button
      color="accent"
      (click)="deleteAllCompareRows()"
    >
      Delete All
    </button>
    <button
      *ngIf="!readonlySection"
      mat-stroked-button
      color="accent"
      (click)="deleteCompareRows()">
      Delete
    </button>
    <button mat-icon-button (click)="closeSidenav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</header>

<div class="main-sidenav-container">
  <div class="jp-form" *ngIf="selectedApplicationFieldCompare$ | async as selectedApplicationFieldCompare">
    <mat-form-field appearance="fill">
      <mat-label>Compare name</mat-label>
      <input
        [disabled]="readonlySection"
        matInput
        [(ngModel)]="selectedApplicationFieldCompare.name"
        (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
      />
    </mat-form-field>

    <div class="form-section__group grid-cols-2">
      <mat-form-field appearance="fill">
        <mat-label>Value Case</mat-label>
        <input
          [disabled]="readonlySection"
          matInput
          [(ngModel)]="selectedApplicationFieldCompare.valueCase"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Value Default</mat-label>
        <input
          [disabled]="readonlySection"
          matInput
          [(ngModel)]="selectedApplicationFieldCompare.valueDefault"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </mat-form-field>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    *ngIf="dataSource.data.length"
    multiTemplateDataRows
    class="mat-elevation-z8 main-table"
    #table
  >
    <ng-container matColumnDef="selected">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef></th>
      <td class="main-table__cell text-center" mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="readonlySection"
          (change)="selection.toggle(element)"
          [checked]="selection.isSelected(element)"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="valueInput">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>INPUT VALUE</th>
      <td
        class="main-table__cell main-table__cell--black"
        appRequiredField
        appFieldLengthLimit
        [maxLength]="maxLength"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueInput"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="valueOperat">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>OP</th>
      <td
        class="main-table__cell main-table__cell--black"
        appRequiredField
        appFieldLengthLimit
        [maxLength]="maxLength"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueOperat"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="valueCompare">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>VALUE COMPARE</th>
      <td
        class="main-table__cell main-table__cell--black"
        appRequiredField
        appFieldLengthLimit
        [maxLength]="maxLength"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueCompare"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="sequence">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>SEQ</th>
      <td
        class="main-table__cell main-table__cell--black"
        appRequiredField
        appFieldLengthLimit
        [maxLength]="maxLength"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.sequence"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          aria-label="expand row"
          (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
        >
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsWithExpand.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div>
            <span class="main-table__cell"> DO VALUE </span>
            <input
              [disabled]="readonlySection"
              [placeholder]="inputPlaceholder"
              class="orange-cursor"
              matInput
              [(ngModel)]="element.valueThen"
              (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
            />
          </div>
          <div>
            <span class="main-table__cell"> ELSE VALUE </span>
            <input
              [disabled]="readonlySection"
              [placeholder]="inputPlaceholder"
              class="orange-cursor"
              matInput
              [(ngModel)]="element.valueElse"
              (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
            />
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumnsWithExpand"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <button
    *ngIf="!readonlySection && loaded$ | async"
    mat-button
    color="primary"
    (click)="addApplicationFieldCompareItem()"
    class="mx-auto mt-5"
  >
    Add New
    <mat-icon>add</mat-icon>
  </button>

  <app-save-panel
    *ngIf="itemsHasChanged()"
    (cancel)="closeSidenav()"
    (save)="saveApplicationFieldCompareItems()"
  ></app-save-panel>

  <div class="progress-spinner progress-spinner--main" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
</div>
