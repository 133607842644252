import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { CurrentUserFacade } from '../../components/user-profile/state/current-user.facade';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { AppConstants } from '../app-constants';
import { SnackbarActions } from '../state/action-types/action-types';
import { UserProfile } from '../models/user/user-profile.model';
import { Router } from '@angular/router';
import { HealthCheckService } from '../services/health-check/health-check.service';

interface ApiError {
  status: number;
  title: string;
  detail: string;
  extensions: Record<string, any>;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private excludedURIs: string[] = ["cloudNormals"]

  constructor(
    private currentUserFacade: CurrentUserFacade,
    private store: Store<AppState>,
    private router: Router,
    private healthCheckService: HealthCheckService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        return this.currentUserFacade.currentUser$.pipe(
          take(1),
          mergeMap((user) => {
            if (response.error != null && !this.excludedURIs.some(i => request.url.includes(i))) {
              this.handleError(response.error, user);
            }
            return throwError(response);
          }),
        );
      }),
    );
  }

  private handleError(err: ApiError, user?: UserProfile): void {
    if (err.status === HttpStatusCode.Unauthorized || err.status === HttpStatusCode.Forbidden) {
      if (user != null) {
        this.currentUserFacade.logout();
      }
      return;
    }

    if (
      err.status === HttpStatusCode.BadRequest ||
      err.status === HttpStatusCode.UnprocessableEntity ||
      err.status === HttpStatusCode.InternalServerError) {
      this.store.dispatch(
        SnackbarActions.showSnackbar({
          iconName: 'highlight_off',
          text: `${err.detail ?? err.title}`,
          iconColor: AppConstants.ERROR_COLOR
        })
      );

      // this.healthCheckService.checkHealth().subscribe((result) => {
      //   if (result !== "Healthy") {
      //     this.router.navigate([`/service-unavailable`]);
      //   }
      // })
    }

    if (!err.status || err.status === HttpStatusCode.ServiceUnavailable) {
      this.router.navigate([`/service-unavailable`]);
    }
  }
}
