import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export class RecipientCodeValidator {
    static createValidator(manualInput: BehaviorSubject<boolean>): ValidatorFn {
        return (fb: FormGroup): ValidationErrors => {
            const valid = fb.controls.slackNotification.value || (manualInput.value ? fb.controls.recipientInput.value :
                fb.controls.recipientSelect.value && fb.controls.recipientSelect.value.length)
            if (valid) {
                fb.controls.recipientInput.setErrors(null)
                fb.controls.recipientSelect.setErrors(null)
                return null;
            }
            fb.controls.recipientInput.setErrors({ recipientRequired: true })
            fb.controls.recipientSelect.setErrors({ recipientRequired: true })
            return { recipientRequired: true } as ValidationErrors
        };
    }
}
