import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFrame } from './i-frame.model';

@Component({
    selector: 'app-i-frame-dialog',
    templateUrl: './i-frame-dialog.component.html',
    styleUrls: ['./i-frame-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IFrameDialogComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<IFrameDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IFrame) {}

    ngOnInit(): void {}

    close(): void {
        this.dialogRef.close();
    }
}
