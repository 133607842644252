import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';
import { CellData, ColumnDef, ColumnProps, ComponentColumn } from '../../../table/models';
import { getPropertyByPath } from '../../../table/pipes';
import { CELL_DATA } from '../../../table/tokens';

interface EditableInputData<T> {
  validators?: ValidatorFn | ValidatorFn[];
  asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[];
  onSave: (updatedValue: any, item: T) => void;
}

export class EditableInputColumn<T> extends ComponentColumn<T, EditableInputData<T>> {
  constructor(
    field: string,
    componentData: EditableInputData<T>,
    props?: ColumnProps<T>
  ) {
    super(field, EditableInputColumnComponent, componentData, props);
  }
}

@Component({
  selector: 'app-editable-input-column',
  templateUrl: './editable-input-column.component.html',
  styleUrls: ['./editable-input-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableInputColumnComponent<T> {

  public control: FormControl<any>;

  public overlayOpen = false;
  public editIconVisible = false;

  public data: EditableInputData<T>;
  public item: T;
  public columnDef: ColumnDef<T>;

  constructor() {
    const { data, item, columnDef } = inject<CellData<T, EditableInputData<T>>>(CELL_DATA);
    this.data = data;
    this.item = item;
    this.columnDef = columnDef;

    this.initFormControl();
  }

  private initFormControl(): void {
    const value = getPropertyByPath(this.item, this.columnDef.field);

    this.control = new FormControl(value, { nonNullable: true });
    this.control.setValidators(this.data.validators ?? []);
    this.control.setAsyncValidators(this.data.asyncValidators ?? []);
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.editIconVisible = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.editIconVisible = false;
  }

  public openOverlay(): void {
    this.overlayOpen = true;
  }

  public closeOverlay(): void {
    this.overlayOpen = false;
    this.control.reset();
  }

  public handleSave(): void {
    this.control.markAsDirty();

    if (this.control.pristine) {
      this.closeOverlay();
      return;
    }

    if (this.control.invalid || this.control.pending) {
      return;
    }

    this.data.onSave(this.control.value, this.item);
    this.closeOverlay();
  }
}
