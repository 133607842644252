<div [ngClass]="{ 'field__list-row': !isSideNav, 'sidenav__list-row': isSideNav }">
    <div [ngClass]="{ 'field__list-col field__list-col--key': !isSideNav,
                      'sidenav__list-col sidenav__list-col--key': isSideNav }">
        {{ fieldName }}</div>
    <div [ngClass]="{ 'field__list-col w-full': !isSideNav, 'sidenav__list-col': isSideNav }">
        <input #selectInput [matTooltip]="selectInput.value" matInput [placeholder]="selectPlaceholder"
            (click)="clearValue(selectedItemControl)" [matAutocomplete]="auto" class="cursor-pointer"
            [formControl]="selectedItemControl" (blur)="resetValue()">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredItems$ | async | orderBy: displayField" [value]="option">
                {{ optionLabel(option) }}
            </mat-option>
        </mat-autocomplete>
        <div [ngClass]="{ 'field__list-errors': !isSideNav, 'sidenav__list-errors': isSideNav }">
            <div *ngIf="selectedItemControl?.errors?.required"
                [ngClass]="{ 'field__list-error': !isSideNav, 'sidenav__list-error': isSideNav }">This field is required
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>