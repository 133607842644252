import { Injector, Pipe, PipeTransform } from '@angular/core';
import { ColumnDef } from '../models';
import { CELL_DATA } from '../tokens';


@Pipe({
  name: 'createCellInjector',
  pure: true
})
export class CreateCellInjectorPipe<T> implements PipeTransform {

  public transform(
    parent: Injector,
    item: T,
    columnDef: ColumnDef<T>,
    data?: Record<string, any>
  ): Injector {
    return Injector.create({
      providers: [
        { provide: CELL_DATA, useValue: { item, columnDef, data } }
      ],
      parent
    });
  }
}
