import { createAction, props } from '@ngrx/store';
import { IntegrationQuestion } from '../../../../core/models/integration/integration-question.model';

export const loadIntegrationQuestions = createAction('[Integration Questions Facade] Load Integration Questions');
export const loadIntegrationQuestionsSuccess = createAction(
    '[Integration Question Effects] Load Integration Questions Success',
    props<{ integrationQuestions: Array<IntegrationQuestion> }>()
);

export const updateIntegrationQuestions = createAction(
    '[Integration Questions Facade] Update Integration Questions',
    props<{ integrationQuestions: Array<IntegrationQuestion> }>()
);
export const updateIntegrationQuestionsSuccess = createAction(
    '[Integration Question Effect] Update Integration Questions Success',
    props<{ integrationQuestions: Array<IntegrationQuestion> }>()
);

export const createIntegrationQuestions = createAction(
    '[Integration Questions Facade] Create Integration Questions',
    props<{ integrationQuestions: Array<IntegrationQuestion>; dashboardRedirect: boolean }>()
);
export const createIntegrationQuestionsSuccess = createAction(
    '[Integration Question Effect] Create Integration Questions Success',
    props<{ integrationQuestions: Array<IntegrationQuestion>; dashboardRedirect: boolean }>()
);
