import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdminControlsActions } from '../../../../core/state/action-types/action-types';
import { AdminControl } from '../../../../core/models/admin-control/admin-control.model';

export const adminControlFeatureKey = 'adminControl';

export interface AdminControlsState extends EntityState<AdminControl> {
    loading: boolean;
    loaded: boolean;
}

export const adapter = createEntityAdapter<AdminControl>();

export const adminControlsInitialState: AdminControlsState = adapter.getInitialState({
    loading: false,
    loaded: false
});

export const adminControlsReducer = createReducer(
    adminControlsInitialState,
    on(AdminControlsActions.loadAdminControls, (state) => {
        if (state.loaded) {
            return { ...state, loading: false };
        }
        return { ...state, loading: true };
    }),
    on(AdminControlsActions.loadAdminControlsSuccess, (state, action) => {
        return adapter.setAll(action.adminControls, { ...state, loading: false, loaded: true });
    }),
    on(AdminControlsActions.upsertAdminControlSuccess, (state, action) => {
        return adapter.upsertOne(action.adminControl, state);
    })
);

export const { selectAll } = adapter.getSelectors();
