import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApplicationFieldItemCompares from './application-field-item-compares.reducer';
import {
    applicationFieldItemCompareFeatureKey,
    ApplicationFieldItemComparesState
} from './application-field-item-compares.reducer';

const selectApplicationFieldItemComparesState = createFeatureSelector<ApplicationFieldItemComparesState>(
    applicationFieldItemCompareFeatureKey
);

export const selectApplicationFieldItemCompares = createSelector(
    selectApplicationFieldItemComparesState,
    fromApplicationFieldItemCompares.selectAll
);

export const selectApplicationFieldItemCompareById = (id: number) =>
    createSelector(selectApplicationFieldItemComparesState, (state) => state.entities[id]);
