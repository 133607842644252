import { createAction, props } from '@ngrx/store';
import { AdminControl } from '../../../../core/models/admin-control/admin-control.model';

export const loadAdminControls = createAction('[Admin Control Facade] Load Admin Controls');

export const loadAdminControlsSuccess = createAction(
    '[Admin Control Effects] Load Admin Controls Success',
    props<{ adminControls: Array<AdminControl> }>()
);

export const upsertAdminControl = createAction(
    '[Admin Controls Facade] Upsert Admin Control',
    props<{ adminControl: AdminControl; showSnackbar: boolean }>()
);
export const upsertAdminControlSuccess = createAction(
    '[Admin Controls Effect] Upsert Admin Control Success',
    props<{ adminControl: AdminControl }>()
);
