import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelpTopic } from '../../models/help-topic/help-topic.model';

@Injectable({
    providedIn: 'root'
})
export class HelpTopicsService {
    uri = environment.apiUrl + '/helpTopics';

    constructor(private readonly http: HttpClient) {}

    public getHelpTopicsByFilter(filterQuery: string, sections: Array<string>): Observable<Array<HelpTopic>> {
        const formattedSections = sections.map((section) => `sections=${encodeURIComponent(section)}`).join('&');
        return this.http.get<Array<HelpTopic>>(`${this.uri}?filterQuery=${filterQuery}&${formattedSections}`);
    }

    public getHelpTopicById(id: number): Observable<HelpTopic> {
        return this.http.get<HelpTopic>(`${this.uri}/${id}`);
    }

    public getHelpTopicByName(name: string, excludeId?: number): Observable<HelpTopic> {
        name = encodeURIComponent(name);
        let url = `${this.uri}/name/${name}/`;
        if (excludeId) {
            url += excludeId;
        }
        return this.http.get<HelpTopic>(url);
    }

    public getHelpTopicByHelpId(helpId: string, excludeId?: number): Observable<HelpTopic> {
        let url = `${this.uri}/helpId/${helpId}/`;
        if (excludeId) {
            url += excludeId;
        }
        return this.http.get<HelpTopic>(url);
    }

    public upsertHelpTopics(items: Array<HelpTopic>): Observable<Array<HelpTopic>> {
        return this.http.put<Array<HelpTopic>>(this.uri, { items });
    }

    public deleteHelpTopic(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/${id}`);
    }
}
