import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  public transform<T>(array: T[], fieldName: keyof T): T[] {
    if (!Array.isArray(array)) {
      return;
    }

    if (fieldName == null) {
      return array;
    }

    array.sort((a: any, b: any) => {
      const firstItem = a[fieldName]?.toString().toUpperCase().trim();
      const secondItem = b[fieldName]?.toString().toUpperCase().trim();
      if (firstItem < secondItem) {
        return -1;
      } else if (firstItem > secondItem) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
