import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApplicationFieldItemXlats from './application-field-item-xlats.reducer';
import { applicationFieldItemXlatFeatureKey, ApplicationFieldItemXlatsState } from './application-field-item-xlats.reducer';

const selectApplicationFieldItemXlatsState = createFeatureSelector<ApplicationFieldItemXlatsState>(
    applicationFieldItemXlatFeatureKey
);

export const selectApplicationFieldItemXlats = createSelector(
    selectApplicationFieldItemXlatsState,
    fromApplicationFieldItemXlats.selectAll
);

export const selectApplicationFieldItemXlatById = (id: number) =>
    createSelector(selectApplicationFieldItemXlatsState, (state) => state.entities[id]);
