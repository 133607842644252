import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumArray'
})
export class EnumArrayPipe implements PipeTransform {
    public transform<T>(transformedEnum: T): string[] {
        return Object.keys(transformedEnum).filter((item) => {
            return isNaN(Number(item));
        });
    }
}
