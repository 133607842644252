import { createAction, props } from '@ngrx/store';
import { FieldFormatType } from '../../models/field-format/field-format-type.model';

export const loadFieldFormatTypes = createAction('[Field Format Types Facade] Load Field Format Types');
export const loadFieldFormatTypesSuccess = createAction(
    '[Field Format Types Effect] Load Field Format Types Success',
    props<{ fieldFormatTypes: Array<FieldFormatType> }>()
);

export const loadFieldFormatTypeByCode = createAction(
    '[Field Format Types Facade] Load Field Format Types By Code',
    props<{ code: string }>()
);
export const loadFieldFormatTypeByCodeSuccess = createAction(
    '[Field Format Types Effect] Load Field Format Types By Code Success',
    props<{ fieldFormatType: FieldFormatType }>()
);

export const updateFieldFormatType = createAction(
    '[Field Format Types Facade] Update Field Format Type',
    props<{ fieldFormatType: FieldFormatType }>()
);
export const updateFieldFormatTypeSuccess = createAction(
    '[Field Format Types Effect] Update Field Format Type Success',
    props<{ fieldFormatType: FieldFormatType }>()
);

export const createFieldFormatType = createAction(
    '[Field Format Types Facade] Create Field Format Type',
    props<{ fieldFormatType: FieldFormatType }>()
);
export const createFieldFormatTypeSuccess = createAction(
    '[Field Format Types Effect] Create Field Format Type Success',
    props<{ fieldFormatType: FieldFormatType }>()
);

export const deleteFieldFormatType = createAction(
    '[Field Format Types Facade] Delete Field Format Type',
    props<{ code: string }>()
);
export const deleteFieldFormatTypeSuccess = createAction(
    '[Field Format Types Effect] Delete Field Format Type Success',
    props<{ code: string }>()
);

export const updateFieldFormatTypeStatus = createAction(
    '[Field Format Types Effects] Change status',
    props<{ fieldFormatTypeCode: string }>()
);
