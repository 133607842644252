import { createFeatureSelector, createSelector } from '@ngrx/store';
import { currentUserFeatureKey, CurrentUserState } from './current-user.reducer';

const selectCurrentUserState = createFeatureSelector<CurrentUserState>(currentUserFeatureKey);

export const selectCurrentUser = createSelector(selectCurrentUserState, (userState) => userState.user);

export const isLoggedIn = createSelector(selectCurrentUserState, (userState) => !!userState.user);

export const selectRefreshTokenRequestCompleted = createSelector(
    selectCurrentUserState,
    (userState) => userState.refreshTokenRequestCompleted
);

export const selectPasswordResetSent = createSelector(selectCurrentUserState, (userState) => userState.passwordResetSent);

export const selectPasswordReset = createSelector(selectCurrentUserState, (userState) => userState.passwordReset);
