import { routerReducer } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer, Action } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { CurrentUserActions } from '../core/state/action-types/action-types';

export interface AppState { }

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logoutMetaReducer] : [logoutMetaReducer];


export function logoutMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action: Action) {
        if (action.type === CurrentUserActions.logoutSuccess.type) {
            state = undefined;
        }
        return reducer(state, action);
    };
}