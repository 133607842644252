import { createAction, props } from '@ngrx/store';
import { User } from '../../../../core/models/user/user.model';

export const loadUsers = createAction('[Users Facade] Load Users');
export const loadUsersSuccess = createAction('[Users Effects] Load Users Success', props<{ users: Array<User> }>());

export const updateUser = createAction('[Users Facade] Update User', props<{ user: User }>());
export const updateUserSuccess = createAction('[Users Effects] Update User Success', props<{ user: User }>());

export const inviteUser = createAction('[Users Facade] Invite User', props<{ user: User }>());
export const inviteUserSuccess = createAction('[Users Effects] Invite User Success', props<{ user: User }>());
