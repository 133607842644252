import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile } from '../../../core/models/user/user-profile.model';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import { CurrentUserActions } from '../../../core/state/action-types/action-types';
import {
    isLoggedIn,
    selectCurrentUser,
    selectPasswordReset,
    selectPasswordResetSent
} from './current-user.selectors';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserFacade {
    public currentUser$: Observable<UserProfile>;
    public isLoggedIn$: Observable<boolean>;
    public passwordResetSent$: Observable<boolean>;
    public passwordReset$: Observable<boolean>;

    constructor(private store: Store<AppState>) {
        this.currentUser$ = store.select(selectCurrentUser);
        this.isLoggedIn$ = store.select(isLoggedIn);
        this.passwordResetSent$ = store.select(selectPasswordResetSent);
        this.passwordReset$ = store.select(selectPasswordReset);
    }

    public login(email: string, password: string, returnUrl: string): void {
        this.store.dispatch(CurrentUserActions.login({ email, password, returnUrl }));
    }

    public logout(): void {
        this.store.dispatch(CurrentUserActions.logout());
    }

    public updateUser(updatedUser: UserProfile): void {
        this.store.dispatch(CurrentUserActions.updateUser({ user: updatedUser }));
    }

    public changeEmail(currentEmail: string, newEmail: string, currentPassword: string): void {
        this.store.dispatch(CurrentUserActions.changeEmail({ currentEmail, newEmail, currentPassword }));
    }

    public changePassword(currentEmail: string, currentPassword: string, newPassword: string): void {
        this.store.dispatch(CurrentUserActions.changePassword({ currentEmail, currentPassword, newPassword }));
    }

    public uploadUserImage(formFile: FormData, fileName: string, userId: string): void {
        this.store.dispatch(CurrentUserActions.uploadUserImage({ formFile, fileName, userId }));
    }

    public refreshToken(): void {
        this.store.dispatch(CurrentUserActions.refreshToken());
    }

    public sendResetPasswordByEmail(email: string): void {
        this.store.dispatch(CurrentUserActions.sendResetPasswordByEmail({ email }));
    }

    public resetPassword(email: string, newPassword: string, token: string): void {
        this.store.dispatch(CurrentUserActions.resetPassword({ email, newPassword, token }));
    }
}
