import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { DataGetter, GroupBy } from './data-getter';
import { PagedList } from './paged-list';
import { map, startWith, switchMap, tap } from 'rxjs/operators';


export class TableDataSource<T> implements DataSource<T> {

  public data: Array<T>;

  private _snapshot: PagedList<T> = { items: [], totalCount: 0 };
  get snapshot(): PagedList<T> {
    return this._snapshot;
  }

  private _dataFetched = new Subject<void>();
  public dataFetched = this._dataFetched.asObservable();

  constructor(
    private dataGetter: DataGetter<T>,
    private paginator: MatPaginator,
    private sort: MatSort,
    private groupBy: BehaviorSubject<GroupBy>) {
  }

  public connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return merge(
      this.paginator.page.asObservable(),
      this.sort.sortChange.asObservable(),
      this.groupBy.asObservable()
    ).pipe(
      startWith(null),
      switchMap(() => {
        const pagination = {
          pageSize: this.paginator.pageSize,
          pageIndex: this.paginator.pageIndex
        };

        const sort = this.sort.direction === '' ? undefined : {
          field: this.sort.active,
          direction: this.sort.direction
        };

        const groupBy = this.groupBy.getValue();

        return this.dataGetter({ pagination, sort, groupBy });
      }),
      tap(list => {
        this._snapshot = list;
        this._dataFetched.next();
      }),
      map(list => {
        this.paginator.length = list.totalCount;
        this.data = list.items;
        return list.items;
      })
    );
  }

  public disconnect(collectionViewer: CollectionViewer): void {
  }
}
