import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-simple-autocomplete-column',
  templateUrl: './simple-autocomplete-column.component.html',
  styleUrls: ['./simple-autocomplete-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleAutocompleteColumnComponent<T> implements OnInit {
  @Input() options: T[];
  @Input() selectedValue: T;
  @Input() key: string;
  @Input() unselectOption: boolean = false;
  @Output() selectedValueChanged = new EventEmitter<T>();

  defaultValue: T;
  inputValue: string;
  filteredOptions: T[];

  constructor() {
  }

  ngOnInit(): void {
    this.filteredOptions = this.options?.sort((a, b) => a[this.key].localeCompare(b[this.key])).slice();
    this.defaultValue = this.selectedValue;
    if (this.key) {
      this.inputValue = this.getInputValue(this.selectedValue);
    }
  }

  public filter(filterValue: string): void {
    this.filteredOptions = this.options.filter(o => o[this.key].toLowerCase().includes(filterValue.toLowerCase()));
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = this.options.find(option => option[this.key] === event.option.value);
    this.selectedValueChanged.emit(selectedOption);
  }

  public clear(): void {
    this.inputValue = null;
    this.selectedValue = null;
    this.filter("");
  }

  public getLastSelected(): void {
    if (!this.selectedValue) {
      this.selectedValue = this.defaultValue;
      this.inputValue = this.getInputValue(this.selectedValue);
    }
  }

  private getInputValue(selectedValue: any): string {
    return selectedValue ? (selectedValue[this.key] ? selectedValue[this.key] : selectedValue) : "";
  }
}
