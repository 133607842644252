import { createAction, props } from '@ngrx/store';
import { TeamMember } from '../../models/team-member/team-member.model';

export const loadTeamMembers = createAction('[Team Members Facade] Load Team Members');

export const loadTeamMembersSuccess = createAction(
    '[Team Members Effect] Load Team Members Success',
    props<{ teamMembers: Array<TeamMember> }>()
);

export const loadTeamMemberById = createAction('[Team Members Facade] Load Team Member By Id', props<{ id: number }>());
export const loadTeamMemberByIdSuccess = createAction(
    '[Team Members Effect] Load Team Member By Id Success',
    props<{ teamMember: TeamMember }>()
);

export const updateTeamMember = createAction('[Team Members Facade] Update Team Member', props<{ teamMember: TeamMember }>());
export const updateTeamMemberSuccess = createAction(
    '[Team Members Effect] Update Team Member Success',
    props<{ teamMember: TeamMember }>()
);

export const createTeamMember = createAction('[Team Members Facade] Create Team Member', props<{ teamMember: TeamMember }>());
export const createTeamMemberSuccess = createAction(
    '[Team Members Effect] Create Team Member Success',
    props<{ teamMember: TeamMember }>()
);

export const deleteTeamMember = createAction('[Team Members Facade] Delete Team Member', props<{ id: number }>());
export const deleteTeamMemberSuccess = createAction('[Team Members Effect] Delete Team Member Success', props<{ id: number }>());

export const updateTeamMemberStatus = createAction('[Team Members Effects] Change status', props<{ teamMemberId: number }>());
