import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-mat-select-with-unselect',
  templateUrl: './mat-select-with-unselect.component.html',
  styleUrls: ['./mat-select-with-unselect.component.scss']
})
export class MatSelectWithUnselectComponent<T> implements OnInit {
  @Input()
  public options: T[]; 
  @Input()
  public label: string; 
  @Input()
  public displayProperty: string; 
  @Input()
  public placeholder: string;  
  @Input()
  public formControl: FormControl; 
  
  @Input() 
  public addNewItem: boolean; 
  @Output()
  public onAddNewItem = new EventEmitter<void>();
  
  public addNewOption: string;
  constructor() { }

  ngOnInit(): void {
  }

  public clearSelection() {
    this.formControl.setValue(null);
  }
}
