import { createAction, props } from '@ngrx/store';
import { Application } from '../../models/application/application.model';

export const loadApplications = createAction('[Applications Facade] Load Applications');
export const loadApplicationsSuccess = createAction(
    '[Applications Effect] Load Applications Success',
    props<{ applications: Array<Application> }>()
);

export const loadApplicationById = createAction('[Applications Facade] Load Application By Id', props<{ id: number }>());
export const loadApplicationByIdSuccess = createAction(
    '[Application Effect] Load Application By Id Success',
    props<{ application: Application }>()
);

export const upsertApplication = createAction('[Applications Facade] Upsert Application', props<{ application: Application }>());
export const upsertApplicationSuccess = createAction(
    '[Applications Effect] Upsert Application Success',
    props<{ application: Application }>()
);

export const deleteApplication = createAction('[Applications Facade] Delete Application', props<{ id: number }>());
export const deleteApplicationSuccess = createAction('[Applications Effect] Delete Application Success', props<{ id: number }>());

export const updateApplicationStatus = createAction('[Applications Effects] Change status', props<{ applicationId: number }>());
