import { createAction, props } from '@ngrx/store';
import { HelpTopicSection } from '../../../../core/models/help-topic/help-topic-section.model';

export const loadHelpTopicSections = createAction('[Help Topic Sections Facade] Load Help Topic Sections');

export const loadHelpTopicSectionsSuccess = createAction(
    '[Help Topic Sections Effect] Help Topic Sections Loaded',
    props<{ helpTopicSections: Array<HelpTopicSection> }>()
);

export const loadHelpTopicSectionByCode = createAction(
    '[Help Topic Sections Facade] Load Help Topic Section By Code',
    props<{ code: string }>()
);
export const loadHelpTopicSectionByCodeSuccess = createAction(
    '[Help Topic Sections Effect] Load Help Topic Section By Code Success',
    props<{ helpTopicSection: HelpTopicSection }>()
);

export const updateHelpTopicSection = createAction(
    '[Help Topic Sections Facade] Update Help Topic Section',
    props<{ helpTopicSection: HelpTopicSection }>()
);
export const updateHelpTopicSectionSuccess = createAction(
    '[Help Topic Sections Effect] Update Help Topic Section Success',
    props<{ helpTopicSection: HelpTopicSection }>()
);

export const createHelpTopicSection = createAction(
    '[Help Topic Sections Facade] Create Help Topic Section',
    props<{ helpTopicSection: HelpTopicSection }>()
);
export const createHelpTopicSectionSuccess = createAction(
    '[Help Topic Sections Effect] Create Help Topic Section Success',
    props<{ helpTopicSection: HelpTopicSection }>()
);

export const deleteHelpTopicSection = createAction(
    '[Help Topic Sections Facade] Delete Help Topic Section',
    props<{ code: string }>()
);
export const deleteHelpTopicSectionSuccess = createAction(
    '[Help Topic Sections Effect] Delete Help Topic Section Success',
    props<{ code: string }>()
);

export const updateHelpTopicSectionStatus = createAction(
    '[Help Topic Sections Effects] Change status',
    props<{ helpTopicSectiondCode: string }>()
);
