import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-save-panel',
    templateUrl: './save-panel.component.html',
    styleUrls: ['./save-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavePanelComponent implements OnInit {
    @Output() readonly cancel = new EventEmitter<void>();
    @Output() readonly save = new EventEmitter<void>();

    @Input() public componentWidth: string = "35rem";

    constructor() {}

    ngOnInit(): void {}

    public emitCancel(): void {
        this.cancel.emit();
    }

    public emitSave(): void {
        this.save.emit();
    }
}
