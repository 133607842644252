import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PartnerEngagementPage } from '../../models/partner-engagement-page/partner-engagement-page.model';

@Injectable({
  providedIn: 'root'
})
export class PartnerEngagementPagesService {

  uri = environment.apiUrl + '/partnerEngagementPages';

  constructor(private readonly http: HttpClient) { }

  public getPartnerEngagementPageById(id: number): Observable<Text> {
    const httpOptions = {
      responseType: 'text' as 'json'
    };

    return this.http.get<Text>(`${this.uri}/${id}`, httpOptions);
  }

  public getPartnerEngagementPages(): Observable<Array<PartnerEngagementPage>> {
    return this.http.get<Array<PartnerEngagementPage>>(this.uri);
  }

  public getAvailablePartnerEngagementPages(): Observable<Array<PartnerEngagementPage>> {
    return this.http.get<Array<PartnerEngagementPage>>(this.uri + '/self');
  }

  public createPartnerEngagementPage(page: PartnerEngagementPage, file: FormData): Observable<PartnerEngagementPage> {
    return this.http.post<PartnerEngagementPage>(`${this.uri}?name=${page.name}`, file);
  }

  public updatePartnerEngagementPage(page: PartnerEngagementPage, file: FormData): Observable<PartnerEngagementPage> {
    return this.http.put<PartnerEngagementPage>(`${this.uri}?id=${page.id}&name=${page.name}`, file);
  }

  public deletePartnerEngagementPage(id: number): Observable<void> {
    return this.http.delete<void>(`${this.uri}/${id}`);
  }
}
