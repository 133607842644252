import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromIntegrations from './integrations.reducer';
import { integrationFeatureKey, IntegrationsState } from './integrations.reducer';
import { Status } from '../../../core/enums/status';

const selectIntegrationsState = createFeatureSelector<IntegrationsState>(integrationFeatureKey);

export const selectLoading = createSelector(selectIntegrationsState, (state) => state.loading);

export const selectLoaded = createSelector(selectIntegrationsState, (state) => state.loaded);

export const selectIntegrations = createSelector(selectIntegrationsState, fromIntegrations.selectAll);

export const selectActiveIntegrations = createSelector(selectIntegrationsState, selectIntegrations, (state, integrations) =>
    integrations.filter((item) => item.status === Status.Active)
);

export const selectIntegrationById = (id: number) =>
    createSelector(selectIntegrationsState, selectIntegrations, (state, integrations) => integrations.find((x) => x.id === id));

export const selectCurrentIntegration = createSelector(selectIntegrationsState, (state) => state?.currentIntegration);

export const selectClientIntegrations = createSelector(
    selectIntegrationsState,
    selectIntegrations,
    selectCurrentIntegration,
    (state, integrations, currentIntegration) => integrations.filter((item) => item.tenantId === currentIntegration?.tenantId)
);

export const selectMatchingResults = createSelector(selectIntegrationsState, (state) => state.matchingResults);

export const selectMatchingInProgress = createSelector(selectIntegrationsState, (state) => state.matchingInProgress);

export const selectMatchingFinished = createSelector(selectIntegrationsState, (state) => state.matchingFinished);

export const selectCreatedIntegration = createSelector(selectIntegrationsState, (state) => state.createdIntegration);
