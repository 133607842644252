import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { SnackbarActions } from '../action-types/action-types';
import { SnackBarDataService } from '../../services/snack-bar-data/snack-bar-data.service';

@Injectable()
export class SnackbarEffects {
    constructor(private store: Store<AppState>, private actions$: Actions, private snackBarDataService: SnackBarDataService) {}

    showSnackbar$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SnackbarActions.showSnackbar),
                tap((action) => this.snackBarDataService.showSnackBar(action.iconName, action.text, action.iconColor))
            ),
        { dispatch: false }
    );
}
