import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { Permissions } from 'src/app/core/authorization/permissions';
import { AppConstants } from '../../../core/app-constants';
import { AuthService } from '../../../core/authentication/auth.service';
import { UserProfileService } from '../../../core/services/user-profile/user-profile.service';
import { CurrentUserActions, SnackbarActions, UsersActions } from '../../../core/state/action-types/action-types';
import { AppState } from '../../../store';

@Injectable()
export class CurrentUserEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private authService: AuthService,
    private userProfileService: UserProfileService,
    private router: Router
  ) {
  }

  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.updateUser),
      concatMap((action) =>
        this.userProfileService.updateUser(action.user).pipe(
          concatMap(() => [
            CurrentUserActions.updateUserSuccess({ user: action.user }),
            UsersActions.updateUserSuccess({ user: action.user }),
            SnackbarActions.showSnackbar({
              iconName: 'check_circle',
              text: 'Your data successfully changed!',
              iconColor: AppConstants.SUCCESS_COLOR
            })
          ])
        )
      )
    )
  );

  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.changeEmail),
      concatMap((action) =>
        this.userProfileService.updateEmail(action.currentEmail, action.newEmail, action.currentPassword).pipe(
          concatMap(() => [
            CurrentUserActions.changeEmailSuccess({ email: action.newEmail }),
            SnackbarActions.showSnackbar({
              iconName: 'check_circle',
              text: 'Your email successfully changed!',
              iconColor: AppConstants.SUCCESS_COLOR
            })
          ])
        )
      )
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.changePassword),
      concatMap((action) =>
        this.userProfileService.updatePassword(action.currentEmail, action.currentPassword, action.newPassword).pipe(
          concatMap(() => [
            CurrentUserActions.changePasswordSuccess(),
            SnackbarActions.showSnackbar({
              iconName: 'check_circle',
              text: 'Your password successfully changed!',
              iconColor: AppConstants.SUCCESS_COLOR
            })
          ])
        )
      )
    )
  );

  uploadUserImage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentUserActions.uploadUserImage),
        concatMap((action) => this.userProfileService.uploadImage(action.formFile, action.fileName, action.userId))
      ),
    { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.login),
      switchMap((action) =>
        this.authService.login(action.email, action.password).pipe(
          switchMap((user) => [
            CurrentUserActions.loginSuccess({ user, returnUrl: action.returnUrl }),
            CurrentUserActions.startRefreshTokenTimer({ user })
          ]),
          catchError(({ error }) =>
            of(SnackbarActions.showSnackbar({
              iconName: 'highlight_off',
              text: `${error.detail ?? error.title}`,
              iconColor: AppConstants.ERROR_COLOR
            }))
          )
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentUserActions.loginSuccess),
        tap((action) => {
          const partnerPagePermission = Permissions.PartnerEngagementPagesView;
          const integrationQuickStartPermission = Permissions.IntegrationQuickStartView;
          const partnerPermissions = [Permissions.PartnerIntegrationUserView, Permissions.PartnerIntegrationClientView];
          if (!action.user.roles.some(role => role.permissions
            .filter(i => i !== partnerPagePermission).some(permission => !permission.includes(`${partnerPagePermission}.`)))) {
            const permissionWithPageId = action.user.roles
              .flatMap(role => role.permissions)
              .find(permission => permission.includes(`${partnerPagePermission}.`));
            const pagePermissionId = Permissions.getPartnerPageIdFromPermission(permissionWithPageId)
            this.router.navigate([`/partner-page/${pagePermissionId}`])
          }
          else if (action.user.roles.some(role => role.permissions.some(p => partnerPermissions.some(i => p.includes(i))))) {
            this.router.navigate([`/integration-search`],{
              queryParams: { integrationId: action.user.selectedIntegrationId }
            });
          }
          else if (action.user.roles.some(role => role.permissions.some(p => p.includes(integrationQuickStartPermission)))) {
            this.router.navigate([`/quick-start`], {
              queryParams: { integrationId: action.user.selectedIntegrationId }
            })
          }
          else if (action.user.selectedIntegrationId) {
            this.router.navigate(['/dashboards'], {
              queryParams: { integrationId: action.user.selectedIntegrationId }
            });
          } else {
            this.router.navigate(['/integration-search']);
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.logout),
      switchMap(() =>
        this.authService
          .logout()
          .pipe(switchMap(() => [CurrentUserActions.logoutSuccess(), CurrentUserActions.stopRefreshTokenTimer()]))
      )
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.logoutSuccess),
      tap(() => {
        // Used to clear store state. Should be refactored to meta-reducers
        window.location.reload();
      })
    ),
    { dispatch: false });

  sendResetPasswordByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.sendResetPasswordByEmail),
      switchMap((action) =>
        this.userProfileService.sendResetPasswordByEmail(action.email).pipe(
          map(() => CurrentUserActions.sendResetPasswordByEmailSuccess()),
          catchError((error) => {
            if (error.status === HttpStatusCode.NotFound) {
              return of(
                SnackbarActions.showSnackbar({
                  iconName: 'check_circle',
                  text: 'User with this email does not exist',
                  iconColor: AppConstants.ERROR_COLOR
                })
              );
            }
            return of(
              SnackbarActions.showSnackbar({
                iconName: 'check_circle',
                text: 'Invalid email, please try again',
                iconColor: AppConstants.ERROR_COLOR
              })
            );
          })
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.resetPassword),
      concatMap((action) =>
        this.userProfileService
          .resetPassword(action.email, action.newPassword, action.token)
          .pipe(map(() => CurrentUserActions.resetPasswordSuccess()))
      )
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentUserActions.refreshToken),
      concatMap(() =>
        this.authService.refreshToken().pipe(
          concatMap((user) => [
            CurrentUserActions.refreshTokenSuccess({ user }),
            CurrentUserActions.startRefreshTokenTimer({ user })
          ]),
          catchError(() => of(CurrentUserActions.refreshTokenError()))
        )
      )
    )
  );

  startRefreshTokenTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentUserActions.startRefreshTokenTimer),
        tap((action) => this.authService.startRefreshTokenTimer(action.user))
      ),
    { dispatch: false }
  );

  stopRefreshTokenTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentUserActions.stopRefreshTokenTimer),
        tap(() => {
          this.authService.stopRefreshTokenTimer();
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );
}
