import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDataMapFields from '../../data-map-fields/state/data-map-fields.reducer';
import { dataMapFieldsFeatureKey, DataMapFieldsState } from './data-map-fields.reducer';

const selectDataMapFieldsState = createFeatureSelector<DataMapFieldsState>(dataMapFieldsFeatureKey);

export const selectDataMapFields = createSelector(selectDataMapFieldsState, fromDataMapFields.selectAll);

export const selectCurrentIntegrationDataMapFields = () =>
    createSelector(selectDataMapFieldsState, selectDataMapFields, (state, dataMapFields) =>
        dataMapFields
    );

export const selectFilteredDataMapFields = (filteredDataMapFieldsIds: Array<number>) =>
    createSelector(selectDataMapFieldsState, selectDataMapFields, (state, dataMapFields) =>
        dataMapFields.filter((item) => filteredDataMapFieldsIds.some((id) => item.id === id))
    );

export const selectDataMapFieldById = (id: number) =>
    createSelector(selectDataMapFieldsState, (state) => {
        if (state.loadedEntitiesById.some((item) => item === id)) {
            return state.entities[id];
        }

        return null;
    });

export const selectLoadedDataMapFieldsById = createSelector(selectDataMapFieldsState, (state) => state.loadedEntitiesById);

export const selectLoading = createSelector(selectDataMapFieldsState, (state) => state.loading);

export const selectLoaded = createSelector(selectDataMapFieldsState, (state) => state.loaded);
