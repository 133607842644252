import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { CloudNormal } from '../../../../core/models/cloud-normal/cloud-normal.model';

export const loadCloudNormals = createAction('[Cloud Normal Facade] Load Cloud Normals', props<{ searchQuery: string }>());
export const loadCloudNormalsSuccess = createAction(
    '[Cloud Normal Effects] Load Cloud Normals Success',
    props<{ cloudNormals: Array<CloudNormal> }>()
);

export const upsertCloudNormal = createAction('[Cloud Normal Facade] Add Cloud Normal', props<{ cloudNormal: CloudNormal }>());
export const upsertCloudNormalSuccess = createAction(
    '[Cloud Normal Effects] Add Cloud Normal Success',
    props<{ cloudNormal: CloudNormal }>()
);

export const updateCloudNormalFromTable = createAction(
    '[Cloud Normal Facade] Update Cloud Normal From Table',
    props<{ cloudNormal: CloudNormal }>()
);
export const updateCloudNormalFromTableSuccess = createAction(
    '[Cloud Normal Effects] Update Cloud Normal From Table Success',
    props<{ cloudNormal: Update<CloudNormal> }>()
);

export const loadCloudNormalById = createAction('[Cloud Normal Facade] Load Cloud Normal By Id', props<{ id: number }>());
export const loadCloudNormalByIdSuccess = createAction(
    '[Cloud Normal Effects] Load Cloud Normal By Id Success',
    props<{ update: Update<CloudNormal> }>()
);

export const deleteCloudNormalById = createAction('[Cloud Normal Facade] Delete Cloud Normal By Id', props<{ id: number }>());
export const deleteCloudNormalByIdSuccess = createAction(
    '[Cloud Normal Effects] Delete Cloud Normal By Id Success',
    props<{ id: number }>()
);

export const updateCloudNormalStatus = createAction('[CloudNormal Effects] Update Cloud Normal Status', props<{ id: number }>());
