import { createAction, props } from '@ngrx/store';
import { Trigger } from 'src/app/core/models/trigger/trigger.model';

export const loadTriggers = createAction('[Triggers Facade] Load Triggers');
export const loadTriggersSuccess = createAction(
    '[Triggers Effects] Load Triggers Success',
    props<{ triggers: Array<Trigger> }>()
);

export const upsertTrigger = createAction('[Triggers Facade] Upsert Trigger', props<{ trigger: Trigger }>());
export const upsertTriggerSuccess = createAction('[Triggers Effects] Upsert Trigger Success', props<{ trigger: Trigger }>());

export const deleteTrigger = createAction('[Triggers Facade] Delete Trigger', props<{ id: number }>());
export const deleteTriggerSuccess = createAction('[Triggers Effects] Delete Trigger Success', props<{ id: number }>());
