<mat-drawer-container *ngLet="isPartner$ | async as isPartner" autosize>
    <mat-drawer *ngIf="!(isSetupQuestionsRouteActivated$ | async)" mode="side" opened="true" class="z-0 overflow-visible">
        <app-main-menu *ngIf="!isPartner" [expandMainSidenav]="expandMainSidenav"></app-main-menu>
        <app-partner-menu *ngIf="isPartner" [expandMainSidenav]="expandMainSidenav"></app-partner-menu>
    </mat-drawer>

    <mat-drawer-content class="drawer-content">
        <div class="overflow-auto h-full">
            <router-outlet></router-outlet>
        </div>
        <button
            *ngIf="!(isSetupQuestionsRouteActivated$ | async)"
            class="menu-toggle-btn z-0"
            (click)="expandMainSidenav = !expandMainSidenav"
        >
            <mat-icon *ngIf="expandMainSidenav" class="toggle-icon">chevron_left</mat-icon>
            <mat-icon *ngIf="!expandMainSidenav" class="toggle-icon">chevron_right</mat-icon>
        </button>
    </mat-drawer-content>
</mat-drawer-container>
