import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShellModule } from './shell/shell.module';
import { CoreModule } from './core/core.module';
import { appInitializer } from './core/helpers/app.initializer';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { metaReducers, reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { CurrentUserFacade } from './components/user-profile/state/current-user.facade';
import { DateTimeProvider, SystemDateTimeProvider } from './core/helpers/date-time-provider';
import { MaterialImporterModule } from './core/material-importer.module';

@NgModule({
  imports: [
    MaterialImporterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShellModule,
    CoreModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([])
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [CurrentUserFacade, [new Inject(Store)]]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: DateTimeProvider, useClass: SystemDateTimeProvider }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
