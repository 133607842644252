import { NgModule } from '@angular/core';

import { ShellComponent } from './shell.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SharedModule } from '../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdminControlsEffects } from '../components/admin-panel/state/admin-controls/admin-controls.effects';
import { adminControlFeatureKey, adminControlsReducer } from '../components/admin-panel/state/admin-controls/admin-controls.reducer';
import { PartnerMenuComponent } from './partner-menu/partner-menu.component';

@NgModule({
    declarations: [ShellComponent, MainMenuComponent, PartnerMenuComponent],
    imports: [SharedModule,
        StoreModule.forFeature(adminControlFeatureKey, adminControlsReducer),
        EffectsModule.forFeature([
            AdminControlsEffects]),
    ]
})
export class ShellModule {}
