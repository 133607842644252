import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appFieldLengthLimit]'
})
export class FieldLengthLimitDirective {
    private readonly tooLongClassName = 'main-table__cell--too-long';

    @Input() maxLength: number;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    @HostListener('input', ['$event']) onInput($event): void {
        const length = $event.target.value.length;
        const element = this.element.nativeElement;

        if (length > this.maxLength) {
            this.renderer.addClass(element, this.tooLongClassName);
        } else {
            this.renderer.removeClass(element, this.tooLongClassName);
        }
    }
}
