import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,

} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { CellClick, ColumnDef, ColumnDefs, DataGetter, GroupBy, RowClassFn, TableDataSource, TableSelectionChange } from '../../models';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[expandedItem]'
})
export class ExpandedContentDirective<T> {
  constructor(public ref: TemplateRef<{ $implicit: T }>) {
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[noDataTemplate]'
})
export class NoDataTemplateDirective {
  constructor(public ref: TemplateRef<never>) {
  }
}

const DEFAULT_COMPARE = (a: any, b: any) => a === b;
const DEFAULT_TRACK_BY = (index: number, item: any): any => item;

@Component({
  selector: 'jp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('contentExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JpTableComponent<T> implements OnInit, OnDestroy {

  @ContentChild(ExpandedContentDirective)
  public expandedContent?: ExpandedContentDirective<T>;

  @ContentChild(NoDataTemplateDirective)
  public noDataTemplate?: NoDataTemplateDirective;

  @ViewChild(MatSort, { static: true })
  public sort!: MatSort;

  @ViewChild(MatPaginator, { static: true })
  public paginator!: MatPaginator;

  @Output()
  public cellClicked = new EventEmitter<CellClick<T>>();

  @Output()
  public rowClicked = new EventEmitter<T>();

  @Output()
  public selectionChange = new EventEmitter<TableSelectionChange<T>>();

  @Input()
  public set columnDefs(value: ColumnDef<T>[]) {
    this.columns = value;
    this.visibleColumns = this.columns.filter(c => !c.hidden).map(c => c.field);
  }

  @Input()
  public set dataGetter(getter: DataGetter<T>) {
    this.dataSource = new TableDataSource<T>(getter, this.paginator, this.sort, this.groupBySubject);
  }

  @Input()
  public sortByField: string = null;

  @Input()
  public groupBySubject = new BehaviorSubject<GroupBy>(null);

  @Input()
  public loading = of(false)

  @Input()
  public pageSize = 100;

  @Input()
  public pageSizeOptions: number[] = [100, 300, 500, 1000];

  @Input()
  public noDataMessage = 'No data to be displayed';

  @Input()
  public compareWith: (a?: T, b?: T) => boolean = DEFAULT_COMPARE;

  @Input()
  public trackBy: TrackByFunction<T> = DEFAULT_TRACK_BY;

  @Input()
  public rowClassFn: RowClassFn<T> = () => null;

  private _expandable = false;
  @Input()
  public get expandable(): boolean {
    return this._expandable;
  }

  public set expandable(value: BooleanInput) {
    this._expandable = coerceBooleanProperty(value);
  }

  private _selectable = false;

  @Input()
  public get selectable(): boolean {
    return this._selectable;
  }

  public set selectable(value: BooleanInput) {
    this._selectable = coerceBooleanProperty(value);
  }

  public getDataSourceData(): T[] {
    return this.dataSource.data;
  }

  public columns: ColumnDefs<T> = [];
  public visibleColumns: string[] = [];
  public dataSource!: TableDataSource<T>;

  public expandedItems = new SelectionModel<T>(true);
  public selectedItems = new SelectionModel<T>(true);

  public readonly EXPANDER_COLUMN = '__expander';
  public readonly EXPANDED_CONTENT_COLUMN = '__expandedContent';

  public readonly SELECT_COLUMN = '__select';

  public readonly GROUP_BY_HEADER_COLUMN = '__groupHeader';
  public readonly GROUP_BY_REDUCER_COLUMN = '__groupReducer';

  private destroyRef = new Subject<void>();
  public injector = inject(Injector);

  public ngOnInit(): void {
    if (this.expandable) {
      this._setupExpandableTable();
    }

    if (this.selectable) {
      this._setupSelectableTable();
    }
  }

  public ngOnDestroy(): void {
    this.destroyRef.next();
    this.destroyRef.complete();
  }

  public onCellClick(item: T, columnDef: ColumnDef<T>): void {
    this.cellClicked.emit({ item, columnDef });
  }

  public onRowClick(item: T): void {
    this.rowClicked.emit(item);
  }

  public allRowsSelected(): boolean {
    const selectedLength = this.selectedItems.selected.length;
    const allItemsLength = this.dataSource.snapshot.items.length;

    return selectedLength === allItemsLength;
  }

  public onSelectHeaderClick(): void {
    if (this.allRowsSelected()) {
      this.selectedItems.clear();
    } else {
      this.selectedItems.select(...this.dataSource.snapshot.items);
    }
  }

  public onExpanderHeaderClick(): void {
    if (this.expandedItems.isEmpty()) {
      this.expandedItems.select(...this.dataSource.snapshot.items);
    } else {
      this.expandedItems.clear();
    }
  }

  private _setupExpandableTable(): void {
    this.visibleColumns = [...this.visibleColumns, this.EXPANDER_COLUMN];

    const comparer = this.compareWith === DEFAULT_COMPARE ? undefined : this.compareWith;
    // TODO: add comparer upon v16 migration
    this.expandedItems = new SelectionModel<T>(true, [], false);

    this.dataSource.dataFetched.pipe(
      takeUntil(this.destroyRef)
    ).subscribe(() => this.expandedItems.clear());
  }

  private _setupSelectableTable(): void {
    this.visibleColumns = [this.SELECT_COLUMN, ...this.visibleColumns];

    const comparer = this.compareWith === DEFAULT_COMPARE ? undefined : this.compareWith;
    // TODO: add comparer upon v16 migration
    this.selectedItems = new SelectionModel<T>(true, [], true);

    this.dataSource.dataFetched.pipe(
      takeUntil(this.destroyRef)
    ).subscribe(() => this.selectedItems.clear());

    this.selectedItems.changed.pipe(
      takeUntil(this.destroyRef)
    ).subscribe(change => {
      const { added, removed, source } = change;
      this.selectionChange.emit({ added, removed, selected: source.selected });
    });
  }

  public isGroup(index: number, item: any): boolean {
    return item.isGroup;
  }

  public reduceGroup(row: any): void {
    row.reduced = !row.reduced;
    let reducedGroups = this.groupBySubject.value.reducedGroups;
    if (row.reduced)
      reducedGroups.push(row);
    else
      reducedGroups = this.groupBySubject.value.reducedGroups.filter((el) => el.value != row.value);
    this.groupBySubject.next({ field: this.groupBySubject.value.field, reducedGroups: reducedGroups })
  }

  
  public getItemValue(item: T, field: string) {
    return item[field];
  }
}
