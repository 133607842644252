import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { PagedList } from './paged-list';

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export interface Sort {
  field: string;
  direction: SortDirection;
}

export interface GroupBy{
  field: string;
  reducedGroups: any[],
  firstTimeReduced?: boolean;
}

export type DataGetter<T> = (options: {
  pagination: Pagination,
  sort?: Sort,
  groupBy: GroupBy
}) => Observable<PagedList<T>>;
