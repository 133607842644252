import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Injectable } from '@angular/core';
import { ApplicationFieldXlat } from '../../../../core/models/application-field/application-field-xlat.model';
import { selectApplicationFieldItemXlatById, selectApplicationFieldItemXlats } from './application-field-item-xlats.selector';
import { ApplicationFieldItemXlatsActions } from '../../../../core/state/action-types/action-types';
import { ApplicationFieldXlatItem } from '../../../../core/models/application-field/application-field-xlat-item.model';
import { AppConstants } from 'src/app/core/app-constants';
import { selectCurrentUser } from 'src/app/components/user-profile/state/current-user.selectors';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFieldItemXlatsFacade {
    public applicationFieldItemXlats$: Observable<Array<ApplicationFieldXlat>>;

    constructor(private store: Store<AppState>) {
        this.applicationFieldItemXlats$ = store.select(selectApplicationFieldItemXlats);
        this.loadApplicationFieldItemXlats();
    }

    public loadApplicationFieldItemXlats(tenantId: string = AppConstants.DEFAULT_TENANT_ID): void {
        this.store.dispatch(ApplicationFieldItemXlatsActions.loadApplicationFieldItemXlats({ tenantId }));
    }

    public createApplicationFieldItemXlat(
        applicationFieldItemXlat: ApplicationFieldXlat,
        applicationFieldItemXlatItems: Array<ApplicationFieldXlatItem>
    ): void {
        this.store.dispatch(
            ApplicationFieldItemXlatsActions.createApplicationFieldItemXlat({
                applicationFieldItemXlat,
                applicationFieldItemXlatItems
            })
        );
    }

    public updateApplicationFieldItemXlat(applicationFieldItemXlat: ApplicationFieldXlat): void {
        this.store.dispatch(ApplicationFieldItemXlatsActions.updateApplicationFieldItemXlat({ applicationFieldItemXlat }));
    }

    public selectApplicationFieldItemXlatsById(id: number): Observable<ApplicationFieldXlat> {
        return this.store.select(selectApplicationFieldItemXlatById(id));
    }
}
