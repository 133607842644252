import { Component, DoCheck, Host, Inject, InjectionToken, Input, OnInit, Optional } from '@angular/core';
import { EMPTY, merge, Observable, of, Subject } from 'rxjs';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';

export const DEFAULT_ERROR_MAP: Readonly<ErrorMap> = {
  min: ({ min }) => `Min value is ${min}`,
  max: ({ max }) => `Max value is ${max}`,
  required: 'This field is required',
  email: 'Email is invalid',
  minLength: ({ requiredLength }) => `Minimum length is ${requiredLength}`,
  maxLength: ({ requiredLength }) => `Maximum length is ${requiredLength}`,
  minlength: ({ requiredLength }) => `Minimum length is ${requiredLength}`,
  maxlength: ({ requiredLength }) => `Maximum length is ${requiredLength}`,
  pattern: ({ requiredPattern }) => `Value doesn't match pattern '${requiredPattern}'`,
  passwordStrength: msg => msg,
  alreadyExists: ({ field }) => `The field '${field}' already exists`,
  notFound: ({ field }) => `The field '${field}' doesn't exist`
};

export type ErrorFormatFn = (errorParams: any) => string;
export type ErrorMap = Record<string, string | ErrorFormatFn>;

export const ERROR_MAP = new InjectionToken<Readonly<ErrorMap>>('ERROR_MAP', {
  providedIn: 'root',
  factory: () => ({})
});

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mat-error[errorFor]',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent implements OnInit, DoCheck {

  // tslint:disable-next-line:no-input-rename
  @Input('errorFor')
  public nameOrControl!: string | FormControl;

  public errorMessage$: Observable<string | null> = of(null);
  private updateValidationMessage$ = new Subject<void>();

  constructor(
    @Host() @Optional() private form: FormGroupDirective | null,
    @Inject(ERROR_MAP) private errorMap: ErrorMap
  ) {
  }

  public ngDoCheck(): void {
    this.updateValidationMessage$.next();
  }

  public ngOnInit(): void {
    const formControl = typeof this.nameOrControl === 'string'
      ? this.form.directives.find(fc => fc.name === this.nameOrControl)
      : this.nameOrControl;
    if (formControl == null) {
      throw new Error(`FormControl with name '${this.nameOrControl}' doesn't exist`);
    }

    const validationMessageChange$ = merge(
      this.updateValidationMessage$,
      formControl.valueChanges,
      formControl.statusChanges,
      this.form?.ngSubmit ?? EMPTY);
    this.errorMessage$ = validationMessageChange$.pipe(
      distinctUntilChanged(),
      map(() => {
        if (formControl.valid || formControl.pending || formControl.disabled) {
          return null;
        }
        const [key, value] = Object.entries(formControl.errors)[0];
        const messageOrFormatFn = this.errorMap[key];

        if (messageOrFormatFn == null) {
          return `Validation error: ${key}`;
        }
        
        return typeof messageOrFormatFn === 'string'
          ? messageOrFormatFn
          : messageOrFormatFn(value);
      })
    );
  }
}
