import { AfterViewChecked, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditableTextareaData } from './models/editable-textarea-data';

@Component({
  selector: 'app-editable-textarea-dialog',
  templateUrl: './editable-textarea-dialog.component.html',
  styleUrls: ['./editable-textarea-dialog.component.scss']
})
export class EditableTextareaDialogComponent implements AfterViewChecked {

  constructor(
    public dialogRef: MatDialogRef<EditableTextareaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditableTextareaData) { }

  public save(): void {
    this.dialogRef.close(this.data.title);
  }

  @ViewChild('itemTextarea') textArea;

  ngAfterViewChecked() {
    this.textArea.nativeElement.focus();
  }
}
