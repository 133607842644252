import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAdminControls from './admin-controls.reducer';
import { adminControlFeatureKey, AdminControlsState } from './admin-controls.reducer';
import { getAdminControlsData, getAdminControlsValue } from '../../admin-panel.functions';
import { AdminControlCode } from '../../../../core/enums/admin-control-code.enum';

const selectAdminControlsState = createFeatureSelector<AdminControlsState>(adminControlFeatureKey);

export const selectAdminControls = createSelector(selectAdminControlsState, fromAdminControls.selectAll);

export const selectAdminControlDataByCode = (adminControlCode: AdminControlCode) =>
    createSelector(selectAdminControls, (adminControls) => getAdminControlsData(adminControls, adminControlCode));

export const selectAdminControlByCode = (adminControlCode: AdminControlCode) =>
    createSelector(selectAdminControls, (adminControls) => adminControls.find((item) => item.code === adminControlCode));

export const selectAdminControlValueByCode = (adminControlCode: AdminControlCode) =>
    createSelector(selectAdminControls, (adminControls) => getAdminControlsValue(adminControls, adminControlCode));

export const selectLoading = createSelector(selectAdminControlsState, (state) => state.loading);

export const selectLoaded = createSelector(selectAdminControlsState, (state) => state.loaded);
