<div mat-dialog-title class="flex justify-between items-center">
    <h2 *ngIf="data.heading" class="grow">{{ data.heading }}</h2>
    <span class="flex-auto"></span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div class="flex flex-col">
        <mat-form-field>
            <textarea #itemTextarea cdkTextareaAutosize matInput appHotkeyComment cdkAutosizeMaxRows="7" class="mb-4 text-left"
                [(ngModel)]="data.title"></textarea>
        </mat-form-field>
        <div class="flex justify-end">
            <button mat-flat-button color="primary" type="button" class="inline-block align-bottom w-20"
                (click)="save()">
                Save
            </button>
        </div>
    </div>
</mat-dialog-content>