<ng-container *ngLet="readonlySection$ | async as readonlySection">

  <div mat-dialog-title class="flex justify-between items-center">
    <h2 class="grow">Review</h2>
    <button mat-icon-button color="accent" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <table
      mat-table
      class="mat-elevation-z8 main-table mb-3"
      [dataSource]="dataSource"
      [hidden]="!dataSource?.data.length">
      <ng-container matColumnDef="reviewType">
        <th *matHeaderCellDef class="main-table__head main-table__head--transparent" mat-header-cell>REVIEW TYPE</th>
        <td *matCellDef="let element" class="main-table__cell main-table__cell--black" mat-cell>
          <mat-select [(ngModel)]="element.reviews" [disabled]="readonlySection" class="list-select" multiple>
            <mat-select-trigger> {{ element.reviews.join(',') }} </mat-select-trigger>
            <mat-option *ngFor="let option of reviewPhases$ | async | orderBy: 'name'" [value]="option.name">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </td>
      </ng-container>
      <ng-container *ngLet="user$ | async as user" matColumnDef="field">
        <th *matHeaderCellDef class="main-table__head main-table__head--transparent" mat-header-cell>TARGET FIELD</th>
        <td
          (click)="navigateToDataMapFields(user.selectedIntegrationId, element.id)"
          *matCellDef="let element"
          mat-cell
          class="main-table__cell main-table__cell--black"
        >
          {{ element.targetScreenName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th *matHeaderCellDef class="main-table__head main-table__head--transparent" mat-header-cell>COMMENT</th>
        <td *matCellDef="let element" class="main-table__cell main-table__cell--black" mat-cell>
          <textarea
            matInput
            [(ngModel)]="element.notation"
            cdkTextareaAutosize
            appHotkeyComment
            cdkAutosizeMaxRows="20"
            class="main-table__textarea"
          ></textarea>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>

    <div *ngIf="!dataSource?.data.length">
      <div class="main-text-large text-center my-8">No results found</div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="false">CANCEL</button>
    <button mat-button color="primary" (click)="saveDataMapFields()">SAVE</button>
  </mat-dialog-actions>
</ng-container>
