import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ApplicationFieldCompare } from '../../../../core/models/application-field/application-field-сompare.model';
import { ApplicationFieldItemComparesActions } from '../../../../core/state/action-types/action-types';

export const applicationFieldItemCompareFeatureKey = 'applicationFieldItemCompare';

export interface ApplicationFieldItemComparesState extends EntityState<ApplicationFieldCompare> {}

export const adapter = createEntityAdapter<ApplicationFieldCompare>({
    selectId: (applicationFieldItemCompare: ApplicationFieldCompare) => applicationFieldItemCompare.id
});

export const initialApplicationFieldItemComparesState = adapter.getInitialState({});

export const applicationFieldItemComparesReducer = createReducer(
    initialApplicationFieldItemComparesState,
    on(ApplicationFieldItemComparesActions.loadApplicationFieldItemComparesSuccess, (state, action) =>
        adapter.upsertMany(action.applicationFieldItemCompares, {
            ...state
        })
    ),
    on(ApplicationFieldItemComparesActions.createApplicationFieldItemCompareSuccess, (state, action) => {
        return adapter.upsertOne(action.applicationFieldItemCompare, state);
    }),
    on(ApplicationFieldItemComparesActions.updateApplicationFieldItemCompareSuccess, (state, action) => {
        return adapter.upsertOne(action.applicationFieldItemCompare, state);
    })
);

export const { selectAll } = adapter.getSelectors();
