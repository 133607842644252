import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { DataMapField } from '../../models/data-map-field/data-map-field.model';
import { first, map } from 'rxjs/operators';
import { AdminControlsFacade } from '../../../components/admin-panel/state/admin-controls/admin-controls.facade';
import { AdminControlCode } from '../../enums/admin-control-code.enum';

@Injectable({
    providedIn: 'root'
})
export class DataMapFieldsService {
    uri = environment.apiUrl + '/dataMapFields';

    constructor(private readonly http: HttpClient, private adminControlsFacade: AdminControlsFacade) { }

    public getDataMapFieldsByFilter(
        integrationId: number = 0,
        filterQuery: string = '',
        xlatCompareOnly: boolean = false,
        activeOnly: boolean = false,
        customFieldOnly: boolean = false,
        reviews: Array<string> = [],
        workflows: Array<string> = []
    ): Observable<Array<DataMapField>> {
        const formattedReviews = reviews.map((review) => `reviews=${encodeURIComponent(review)}`).join('&');
        const formattedWorkflows = workflows.map((workflow) => `workflows=${encodeURIComponent(workflow)}`).join('&');
        const dataMapFields$ = this.http.get<Array<DataMapField>>(
            `${this.uri}?integrationId=${integrationId}&filterQuery=${filterQuery}&xlatCompareOnly=${xlatCompareOnly}&activeOnly=${activeOnly}&customFieldsOnly=${customFieldOnly}&${formattedReviews}&${formattedWorkflows}`
        );
        const reviewPhases$ = this.adminControlsFacade.selectAdminControlDataByCode(AdminControlCode.ReviewPhase);
        const actions$ = this.adminControlsFacade.selectAdminControlDataByCode(AdminControlCode.Action);
        return combineLatest([dataMapFields$, reviewPhases$, actions$]).pipe(
            first(([, reviewPhases, actions]) => !!reviewPhases?.length && !!actions?.length),
            map(([dataMapFields, reviewPhases, actions]) => {
                const reviewPhaseNames = reviewPhases.map((reviewPhase) => reviewPhase.name);
                const actionsNames = actions.map((action) => action.name);
                dataMapFields.forEach((dataMapField) => {
                    dataMapField.reviews = dataMapField.reviews.filter((review) => reviewPhaseNames.includes(review));
                    dataMapField.action = actionsNames.includes(dataMapField.action) ? dataMapField.action : '';
                });
                return dataMapFields.filter((dataMapField) => dataMapField.action !== 'Blank' && dataMapField.action !== 'No');
            })
        );
    }

    public getDataMapFieldById(id: number): Observable<DataMapField> {
        return this.http.get<DataMapField>(`${this.uri}/${id}`);
    }

    public getDataMapFieldByCloudNormalId(integrationId: number, id: number, excludeId?: number): Observable<DataMapField> {
        let url = `${this.uri}/${integrationId}/${id}/`;
        if (excludeId) {
            url += excludeId;
        }
        return this.http.get<DataMapField>(url);
    }

    public createDataMapFields(items: Array<DataMapField>): Observable<Array<DataMapField>> {
        return this.http.put<Array<DataMapField>>(this.uri, { items });
    }

    public updateDataMapFields(items: Array<DataMapField>): Observable<Array<DataMapField>> {
        return this.http.patch<Array<DataMapField>>(this.uri, { items });
    }

    public deleteDataMapField(id: number): Observable<void> {
        return this.http.delete<void>(`${this.uri}/${id}`);
    }
}
