import { createAction, props } from '@ngrx/store';
import { Role } from '../../../../core/models/role/role.model';

export const loadRoles = createAction('[Roles Facade] Load Roles');
export const loadRolesSuccess = createAction('[Roles Effects] Load Roles Success', props<{ roles: Array<Role> }>());

export const updateRole = createAction('[Roles Facade] Update Role', props<{ role: Role }>());
export const updateRoleSuccess = createAction('[Roles Effect] Update Role Success', props<{ role: Role }>());

export const createRole = createAction('[Roles Facade] Create Role', props<{ role: Role }>());
export const createRoleSuccess = createAction('[Roles Effect] Create Role Success', props<{ role: Role }>());

export const deleteRole = createAction('[Roles Facade] Delete Role', props<{ id: string }>());
export const deleteRoleSuccess = createAction('[Roles Effect] Delete Role Success', props<{ id: string }>());
