import { createAction, props } from '@ngrx/store';
import { ApplicationFieldXlatItem } from '../../../../core/models/application-field/application-field-xlat-item.model';

export const loadApplicationFieldItemXlatItems = createAction(
    '[Application Field Item Xlat Items Facade] Load Application Field Item Xlat Items',
    props<{ id: number }>()
);

export const loadApplicationFieldItemXlatItemsSuccess = createAction(
    '[Application Field Item Xlats Effects] Load Application Field Item Xlat Items Success',
    props<{ applicationFieldItemXlatItems: Array<ApplicationFieldXlatItem>; id: number }>()
);

export const upsertApplicationFieldItemXlatItems = createAction(
    '[Application Field Item Xlat Items Facade] Upsert Application Field Item Xlat Items',
    props<{ applicationFieldItemXlatItems: Array<ApplicationFieldXlatItem> }>()
);
export const upsertApplicationFieldItemXlatItemsSuccess = createAction(
    '[Application Field Item Xlat Items Effects] Upsert Application Field Item Xlat Items Success',
    props<{ applicationFieldItemXlatItems: Array<ApplicationFieldXlatItem> }>()
);

export const deleteApplicationFieldItemXlatItems = createAction(
    '[Application Field Item Xlat Items Facade] Delete Application Field Item Xlat Items',
    props<{ id: number }>()
);
export const deleteApplicationFieldItemXlatItemsSuccess = createAction(
    '[Application Field Item Xlat Items Facade] Delete Application Field Item Xlat Items Success',
    props<{ id: number }>()
);

export const updateApplicationFieldItemXlatItemStatus = createAction(
    '[Application Field Item Xlat Items Effects] Change status',
    props<{ id: number }>()
);
