import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Integration } from '../../../core/models/integration/integration.model';
import { ClientIntegrationsData } from './client-integrations-data.model';
import { Status } from '../../../core/enums/status';
import { IntegrationsFacade } from '../state/integrations.facade';
import { CurrentUserFacade } from '../../user-profile/state/current-user.facade';
import { Observable } from 'rxjs';
import { UserProfile } from '../../../core/models/user/user-profile.model';

@Component({
    selector: 'app-client-integrations-dialog',
    templateUrl: './client-integrations-dialog.component.html',
    styleUrls: ['./client-integrations-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientIntegrationsDialogComponent implements OnInit {
    public user$: Observable<UserProfile>;
    public dataSource = new MatTableDataSource<Integration>();
    public displayedColumns: string[] = ['integrationName', 'currentPhase', 'status'];

    public get status(): typeof Status {
        return Status;
    }

    constructor(
        public integrationsFacade: IntegrationsFacade,
        public currentUserFacade: CurrentUserFacade,
        public dialogRef: MatDialogRef<ClientIntegrationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ClientIntegrationsData
    ) {}

    ngOnInit(): void {
        this.user$ = this.currentUserFacade.currentUser$;
        this.dataSource.data = this.data.clientIntegrations;
    }

    public selectIntegration(userId: string, integrationId: number): void {
        this.integrationsFacade.selectIntegration(userId, integrationId, true);
        this.close();
    }

    public close(): void {
        this.dialogRef.close();
    }
}
