<header class="header header--sidebar">
  <div class="header__items mr-auto">
    <div class="header__title">XLAT</div>
  </div>
  <div class="flex items-center gap-x-4">
    <input
      class="hidden"
      type="file"
      [accept]="acceptedFormats"
      (click)="hiddenFileInput.value = ''"
      (change)="importXlatFromExcelFile($event.target)"
      #hiddenFileInput
    />
    <button
      *ngIf="loaded$ && !(loading$ | async)"
      mat-icon-button
      color="primary"
      matTooltip="Import from Excel file"
      (click)="hiddenFileInput.click()"
    >
      <mat-icon>
        upload
      </mat-icon>
    </button>
    <button
      *ngIf="loaded$ && !(loading$ | async)"
      mat-icon-button
      color="primary"
      matTooltip="Export to Excel file"
      (click)="exportXlatToExcelFile()"
    >
      <mat-icon>download</mat-icon>
    </button>
    <button
      *ngIf="!readonlySection"
      mat-stroked-button
      color="accent"
      (click)="deleteAllXlatRows()">
      Delete All
    </button>
    <button
      *ngIf="!readonlySection"
      mat-stroked-button
      color="accent"
      (click)="deleteXlatRows()">
      Delete
    </button>
    <button mat-icon-button (click)="closeSidenav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</header>

<div class="main-sidenav-container" *ngIf="!(xlatLoading$ | async)">
  <div class="jp-form" >
    <mat-form-field appearance="fill">
      <mat-label>XLAT name</mat-label>
      <input
        [disabled]="readonlySection"
        matInput
        [(ngModel)]="applicationFieldPartialXlat.name"
        (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Source Screen Name</mat-label>
      <input
        [value]="sourceScreenName$ | async"
        [disabled]="true"
        matInput
      />
    </mat-form-field>
  </div>

  <table
    *ngIf="dataSource.data.length"
    #table
    class="mat-elevation-z8 main-table"
    mat-table
    [dataSource]="dataSource"
    matSort>
    <ng-container matColumnDef="selected">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef></th>
      <td class="main-table__cell text-center" mat-cell *matCellDef="let element">
        <mat-checkbox
          [disabled]="readonlySection"
          (change)="selection.toggle(element)"
          [checked]="selection.isSelected(element)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="valueSource">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>SOURCE VALUE</th>
      <td
        appFieldLengthLimit
        [maxLength]="maxLength"
        appRequiredField
        class="main-table__cell main-table__cell--black"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueSource"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="valueTarget">
      <th class="main-table__head" mat-header-cell *matHeaderCellDef>TARGET VALUE</th>
      <td
        appFieldLengthLimit
        [maxLength]="maxLength"
        appRequiredField
        class="main-table__cell main-table__cell--black"
        mat-cell
        *matCellDef="let element"
      >
        <input
          [disabled]="readonlySection"
          [placeholder]="inputPlaceholder"
          class="orange-cursor"
          matInput
          [(ngModel)]="element.valueTarget"
          (ngModelChange)="this.itemsChanged.emit(this.itemsHasChanged())"
        />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.highlight-row]="selection.isSelected(row)"></tr>
  </table>

  <button
    mat-button
    color="primary"
    class="mx-auto mt-5"
    (click)="addApplicationFieldXlatItem()"
  >
    Add New
    <mat-icon>add</mat-icon>
  </button>

  <app-save-panel
    *ngIf="itemsHasChanged()"
    (cancel)="closeSidenav()"
    (save)="saveApplicationFieldXlatItems()"
  ></app-save-panel>
</div>

<div class="progress-spinner progress-spinner--main" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>
