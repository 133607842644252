import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { selectCurrentUser } from '../../user-profile/state/current-user.selectors';
import { map } from 'rxjs/operators';
import { Permissions } from '../../../core/authorization/permissions';
import { IntegrationsActions } from '../../../core/state/action-types/action-types';
import { Integration } from '../../../core/models/integration/integration.model';
import {
    selectActiveIntegrations,
    selectClientIntegrations,
    selectCreatedIntegration,
    selectCurrentIntegration,
    selectLoaded,
    selectLoading,
    selectMatchingFinished,
    selectMatchingInProgress,
    selectMatchingResults
} from './integrations.selectors';
import { MatchingResult } from '../../create-new-integration/matching-results/matching-result';
import { UserProfileService } from '../../../core/services/user-profile/user-profile.service';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsFacade {
    public activeIntegrations$: Observable<Array<Integration>>;
    public clientIntegrations$: Observable<Array<Integration>>;
    public currentIntegration$: Observable<Integration>;
    public readonlySection$: Observable<boolean>;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;

    public createdIntegration$: Observable<Integration>;
    public matchingResults$: Observable<Array<MatchingResult>>;
    public matchingInProgress$: Observable<boolean>;
    public matchingFinished$: Observable<boolean>;
    public selectedIntegration$: Observable<Integration>;

    constructor(
      private store: Store<AppState>,
      private userProfileService: UserProfileService,
    ) {
        this.readonlySection$ = this.store.pipe(
            select(selectCurrentUser),
            map(
                (user) =>
                    !user.roles.some((role) => role.permissions.some((permission) => permission === Permissions.IntegrationsEdit))
            )
        );
        this.activeIntegrations$ = store.select(selectActiveIntegrations);
        this.currentIntegration$ = this.store.select(selectCurrentIntegration);
        this.selectedIntegration$ = this.userProfileService.getSelectedIntegration();
        this.loading$ = store.select(selectLoading);
        this.loaded$ = store.select(selectLoaded);
        this.clientIntegrations$ = store.select(selectClientIntegrations);

        this.createdIntegration$ = store.select(selectCreatedIntegration);
        this.matchingResults$ = this.store.select(selectMatchingResults);
        this.matchingInProgress$ = this.store.select(selectMatchingInProgress);
        this.matchingFinished$ = this.store.select(selectMatchingFinished);

        this.loadMatchingResults();
        this.loadIntegrations();
    }

    private loadMatchingResults(): void {
        this.store.dispatch(IntegrationsActions.loadMatchingResults());
    }

    private loadIntegrations(): void {
        this.store.dispatch(IntegrationsActions.loadIntegrations());
    }

    public selectIntegration(userId: string, integrationId: number, dashboardRedirect: boolean): void {
        this.store.dispatch(IntegrationsActions.selectIntegration({ userId, integrationId, dashboardRedirect }));
    }

    public updateIntegration(integration: Integration, fillMissingMembers: boolean = true): void {
        this.store.dispatch(IntegrationsActions.updateIntegration({ integration, fillMissingMembers }));
    }

    public createIntegration(integration: Integration, matchingResults: Array<MatchingResult>): void {
        this.store.dispatch(
            IntegrationsActions.createIntegration({
                integration,
                matchingResults
            })
        );
    }

    public deleteIntegration(id: number): void {
        this.store.dispatch(IntegrationsActions.deleteIntegration({ id }));
    }

    public runCloudNormalMatching(
        createdIntegration: Integration,
        sourceApplicationFieldId: number,
        targetApplicationFieldId: number
    ): void {
        this.store.dispatch(
            IntegrationsActions.runCloudNormalMatching({ createdIntegration, sourceApplicationFieldId, targetApplicationFieldId })
        );
    }
}
