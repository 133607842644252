import { createAction, props } from '@ngrx/store';
import { ApplicationField } from '../../../core/models/application-field/application-field.model';
import { ApplicationFieldItem } from '../../../core/models/application-field-item/application-field-item.model';

export const loadApplicationFields = createAction('[Application Fields Facade] Load Application Fields');
export const loadApplicationFieldsSuccess = createAction(
    '[Application Fields Effects] Load Application Fields Success',
    props<{ applicationFields: Array<ApplicationField> }>()
);

export const upsertApplicationField = createAction(
    '[Application Fields Facade] Upsert Application Field',
    props<{ applicationField: ApplicationField }>()
);
export const upsertApplicationFieldSuccess = createAction(
    '[Application Fields Effects] Upsert Application Field Success',
    props<{ applicationField: ApplicationField }>()
);

export const createApplicationFieldWithItems = createAction(
    '[Application Fields Facade] Create Application Field With Items',
    props<{ applicationField: ApplicationField; applicationFieldItems: Array<ApplicationFieldItem> }>()
);

export const deleteApplicationField = createAction(
    '[Application Fields Facade] Delete Application Field',
    props<{ applicationFieldId: number }>()
);
export const deleteApplicationFieldSuccess = createAction(
    '[Application Fields Effects] Delete Application Field Success',
    props<{ applicationFieldId: number }>()
);

export const updateApplicationFieldStatus = createAction(
    '[Application Fields Effects] Change status',
    props<{ applicationFieldId: number }>()
);
