import { Component, Input, OnDestroy, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnDestroy, ControlValueAccessor {

  @Input()
  public placeholder = 'Search';

  public control = new FormControl<string | null>('', { nonNullable: true });

  private destroyed$ = new Subject();

  constructor(
    @Self() private ngControl: NgControl,
  ) {
    this.ngControl.valueAccessor = this;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public writeValue(obj: any): void {
    if (obj === null) {
      this.control.reset();
    } else if (obj !== undefined) {
      this.control.setValue(obj);
    }
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable({emitEvent: false}) : this.control.enable({emitEvent: false});
  }

  public clearInput(): void {
    this.control.reset();
  }

}
