import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import isNumber from 'lodash-es/isNumber';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { SnackBarDataService } from 'src/app/core/services/snack-bar-data/snack-bar-data.service';
import { SharedDialogData } from 'src/app/shared/components/shared-dialog/models/shared-dialog-data';
import { SharedDialogType } from 'src/app/shared/components/shared-dialog/models/shared-dialog-type';
import { AppConstants } from '../../../core/app-constants';
import { UserStatus } from '../../../core/enums/user-status';
import { Role } from '../../../core/models/role/role.model';
import { Tenant } from '../../../core/models/tenant/tenant.model';
import { User } from '../../../core/models/user/user.model';
import { getSelectTriggerText } from '../../../core/pure-functions/get-select-trigger-text.fn';
import { DiscardDataService } from '../../../core/services/discard-data/discard-data.service';
import { mapFormToUser } from '../users.functions';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
	selector: 'app-update-user',
	templateUrl: './update-user.component.html',
	styleUrls: ['./update-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateUserComponent implements OnDestroy, OnInit {
	public inputPlaceholder = AppConstants.INPUT_PLACEHOLDER;
	public selectPlaceholder = AppConstants.SELECT_PLACEHOLDER;

	public filteredTenants$: Observable<Array<Tenant>>;

	@Input()
	public form: FormGroup;
	@Input()
	public selectedRecord: User;
	@Input()
	public roles: Array<Role>;
	@Input()
	public tenants: Tenant[] = [];
	@Input()
	public isPartnerUpdate: boolean = false;
	@Input()
	public sidenav: MatSidenav;

	@Output()
	public closeSidenavClick = new EventEmitter();
	@Output()
	public saveClick = new EventEmitter<User>();
	@Output()
	public sendResetPasswordClick = new EventEmitter();

	private destroy$ = new Subject();

	public get userStatus(): typeof UserStatus {
		return UserStatus;
	}

	constructor(
		private discardDataService: DiscardDataService,
		private snackBarDataService: SnackBarDataService,
		private dialogService: DialogService) {
	}

	public ngOnInit(): void {
		this.sidenav.openedStart.pipe((
			tap(() =>
				this.filteredTenants$ = this.form.controls.tenantId?.valueChanges.pipe(
					map(value => {
						if (isNumber(value)) {
							return this.tenants.filter(option => option.tenantId === value);
						}
						const filterValue = value?.tenantId ? value.tenantId : value?.toLowerCase();
						return this.tenants.filter(option => option.tenantId.toLowerCase().includes(filterValue || ''));
					}),
				))
		)).subscribe();
	}

	public clearValue(control: AbstractControl): void {
		control.setValue(null);
	}

	public displayFn = (record: any): string => {
		return record?.name ? record?.name : record;
	}

	public emitSave(): void {
		if (!this.form.valid) {
			return;
		}

		const user = mapFormToUser(this.form);
		this.saveClick.emit(user);
	}

	public sendResetPassword(): void {
		const email = this.form.controls.email.value;
		this.sendResetPasswordClick.emit(email);
		this.snackBarDataService.showSnackBar(
			'check_circle',
			'Your letter for reset password has been sent',
			AppConstants.SUCCESS_COLOR
		);
	}

	public closeSidenav(): void {
		this.form.dirty
			? this.showDiscardDialog()
			: this.closeSidenavClick.emit();
	}

	private showDiscardDialog(): void {
		this.discardDataService.openConfirmationDialog()
			.afterClosed()
			.subscribe(res => {
				if (res) {
					this.closeSidenavClick.emit();
				}
			});
	}

	public getSelectTriggerText(text: string): string {
		return getSelectTriggerText(text);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onRoleSelectCheckEmail(roleName: string, isSelected: boolean): void {
		const adminRoleName = "Admin";
		const message = 'You have selected “Admin Role” are you sure you want to give this Role to this User?';

		if (roleName !== adminRoleName || !isSelected) {
			return;
		}

		if (!this.form.controls.email.value?.match(AppConstants.JOYND_DOMAIN_NAME)) {
			const warningMessage = "Admin email should match Joynd domain: '@joynd.io'"
			let data = {
				title: warningMessage,
				sharedDialogType: SharedDialogType.Info
			} as SharedDialogData;

			this.dialogService
				.openConfirmationDialog(data)
				.afterClosed()
				.pipe(takeUntil(this.destroy$))
				.subscribe();
			return this.form.controls.roles.setValue(this.form.controls.roles.value.filter((role: string) => role !== adminRoleName))
		}

		let data = {
			confirmButtonText: "Yes",
			title: message,
			sharedDialogType: SharedDialogType.Confirmation
		} as SharedDialogData;

		this.dialogService
			.openConfirmationDialog(data)
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result: boolean) => {
				if (!result) {
					this.form.controls.roles.setValue(this.form.controls.roles.value.filter((role: string) => role !== adminRoleName))
				}
			});
	}
}
