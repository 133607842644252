<div mat-dialog-title class="flex justify-between items-center">
  <h2 class="grow">{{ data.title }}</h2>
  <button mat-icon-button color="accent" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="form">
    <app-autocomplete [dataGetter]="filterItemsData$" label="Application Code" inputDisplay="name"
      formControlName="itemsControl">
      <ng-template appAutocompleteOption let-option>
        {{ option.name }}
      </ng-template>
    </app-autocomplete>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" [mat-dialog-close]="null">
    CANCEL
  </button>
  <button mat-button color="primary" [mat-dialog-close]="form.controls.itemsControl.value">
    {{ data.confirmButtonText }}
  </button>
</mat-dialog-actions>
