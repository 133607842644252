import { Directive, ElementRef, HostListener, OnDestroy, Optional } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CurrentUserFacade } from 'src/app/components/user-profile/state/current-user.facade';
import { User } from '../../models/user/user.model';
import { formatDate } from '@angular/common';
import { NgControl } from '@angular/forms';
import { DateTimeProvider } from '../../helpers/date-time-provider';

@Directive({
  selector: 'input[appHotkeyComment][type="text"], input[appHotkeyComment]:not([type]), textarea[appHotkeyComment]'
})
export class HoyKeyCommentDirective implements OnDestroy {
  private currentUser$: Observable<User>;
  private destroy$ = new Subject<void>();

  constructor(
    private elRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    @Optional() private controlDir: NgControl,
    private currentUserFacade: CurrentUserFacade,
    private dateTimeProvider: DateTimeProvider
  ) {
    this.currentUser$ = currentUserFacade.currentUser$;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('keydown.alt.i', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    event.preventDefault();
    this.currentUser$.pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe(user => this.handleHotkey(user));
  }

  private handleHotkey(user: User): void {
    const textToInsert = this.createText(user);

    const element = this.elRef.nativeElement;

    const currentCursorPosition = element.selectionStart;
    const currentValue = element.value;

    const newValue =
      currentValue.slice(0, currentCursorPosition) +
      textToInsert +
      currentValue.slice(currentCursorPosition);

    // If there are reactive/template driven forms - use them to update input value
    // instead of interacting with input element
    if (this.controlDir != null) {
      this.controlDir.control.setValue(newValue);
    } else {
      element.value = newValue;
    }

    const newCursorPosition = currentCursorPosition + textToInsert.length;
    element.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  private createText({ firstName, lastName }: User): string {
    const formattedDate = formatDate(this.dateTimeProvider.now(), 'MM/dd', 'en-US');
    const userInitials = `${firstName?.[0]}${lastName?.[0]}`;
    return `${formattedDate} ${userInitials}`;
  }
}
