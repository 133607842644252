import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ApplicationFieldItem } from '../../../../core/models/application-field-item/application-field-item.model';

export const loadApplicationFieldItems = createAction(
    '[Application Field Items Facade] Load Application Field Items',
    props<{ id: number }>()
);
export const loadApplicationFieldItemsSuccess = createAction(
    '[Application Field Items Effects] Load Application Field Items Success',
    props<{ applicationFieldItems: Array<ApplicationFieldItem>; applicationFieldId: number }>()
);

export const loadApplicationFieldItemById = createAction(
    '[Application Field Items Facade] Load Application Field Item By Id',
    props<{ id: number }>()
);
export const loadApplicationFieldItemByIdSuccess = createAction(
    '[Application Field Items Effect] Load Application Field Item By Id Success',
    props<{ applicationFieldItem: ApplicationFieldItem }>()
);

export const upsertApplicationFieldItem = createAction(
    '[Application Field Items Facade] Upsert Application Field Item',
    props<{ applicationFieldItem: ApplicationFieldItem }>()
);
export const upsertApplicationFieldItemSuccess = createAction(
    '[Application Field Items Effects] Upsert Application Field Item Success',
    props<{ applicationFieldItem: ApplicationFieldItem }>()
);

export const createApplicationFieldItems = createAction(
    '[Application Field Items Facade] Create Application Field Items',
    props<{ applicationFieldItems: Array<ApplicationFieldItem> }>()
);

export const updateApplicationFieldItemFromTable = createAction(
    '[Application Fields Facade] Update Application Field Item From Table',
    props<{ applicationFieldItem: ApplicationFieldItem }>()
);
export const updateApplicationFieldItemFromTableSuccess = createAction(
    '[Application Fields Effects] Update Application Field Item From Table Success',
    props<{ applicationFieldItem: Update<ApplicationFieldItem> }>()
);

export const deleteApplicationFieldItem = createAction(
    '[Application Field Items Facade] Delete Application Field Item',
    props<{ applicationFieldItemId: number }>()
);
export const deleteApplicationFieldItemSuccess = createAction(
    '[Application Field Items Effects] Delete Application Field Item Success',
    props<{ applicationFieldItemId: number }>()
);

export const updateApplicationFieldItemStatus = createAction(
    '[Application Field Items Effects] Change status',
    props<{ applicationFieldItemId: number }>()
);

export const removeXlatAssociation = createAction(
    '[Application Field Items Facade] Remove Xlat Association',
    props<{ applicationFieldItemId: number }>()
);

export const removeCompareAssociation = createAction(
    '[Application Field Items Facade] Remove Compare Association',
    props<{ applicationFieldItemId: number }>()
);
