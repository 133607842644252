import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IntegrationsActions } from '../../../core/state/action-types/action-types';
import { Integration } from '../../../core/models/integration/integration.model';
import { MatchingResult } from '../../create-new-integration/matching-results/matching-result';
import { Status } from '../../../core/enums/status';

export const integrationFeatureKey = 'integration';

export interface IntegrationsState extends EntityState<Integration> {
    currentIntegration: Integration;
    loading: boolean;
    loaded: boolean;
    matchingResults: Array<MatchingResult>;
    matchingInProgress: boolean;
    matchingFinished: boolean;
    createdIntegration: Integration;
}

export const adapter = createEntityAdapter<Integration>();

export const initialIntegrationsState = adapter.getInitialState({
    currentIntegration: null,
    loading: false,
    loaded: false,
    createdIntegration: null,
    matchingResults: [],
    matchingInProgress: false,
    matchingFinished: false
});

export const integrationsReducer = createReducer(
    initialIntegrationsState,
    on(IntegrationsActions.loadMatchingResultsSuccess, (state, action) => {
        if (action.matchingResults && action.matchingResults.length) {
            return { ...state, matchingResults: action.matchingResults, matchingInProgress: false, matchingFinished: true };
        }
        return { ...state };
    }),
    on(IntegrationsActions.loadIntegrations, (state) => {
        if (state.loaded) {
            return { ...state, loading: false };
        }
        return { ...state, loading: true };
    }),
    on(IntegrationsActions.loadIntegrationsSuccess, (state, action) =>
        adapter.setAll(action.integrations, {
            ...state,
            currentIntegration: action.integrations.find((item) => item.id === action.currentIntegrationId),
            loading: false,
            loaded: true
        })
    ),
    on(IntegrationsActions.selectIntegrationSuccess, (state, action) => {
        return { ...state, currentIntegration: state.entities[action.integrationId] };
    }),
    on(IntegrationsActions.updateIntegrationSuccess, (state, action) => {
        if (action.integration.status === Status.Inactive) {
            return adapter.removeOne(action.integration.id, state);
        }
        return adapter.upsertOne(action.integration, { ...state, currentIntegration: action.integration });
    }),
    on(IntegrationsActions.createIntegration, (state) => {
        return { ...state, loading: true };
    }),
    on(IntegrationsActions.createIntegrationSuccess, (state, action) =>
        adapter.upsertOne(action.integration, { ...state, currentIntegration: action.integration, loading: false })
    ),
    on(IntegrationsActions.runCloudNormalMatching, (state, action) => {
        return {
            ...state,
            matchingInProgress: true,
            createdIntegration: action.createdIntegration
        };
    }),
    on(IntegrationsActions.cloudNormalMatchingSuccess, (state, action) => {
        return { ...state, matchingResults: action.matchingResults, matchingInProgress: false, matchingFinished: true };
    })
);

export const { selectAll } = adapter.getSelectors();
