import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { map, takeUntil, tap } from 'rxjs/operators';
import { selectCurrentUser } from '../../user-profile/state/current-user.selectors';
import { Permissions } from '../../../core/authorization/permissions';
import { AppConstants } from '../../../core/app-constants';
import { DataMapFieldsFacade } from '../../data-map-fields/state/data-map-fields.facade';
import { DataMapField } from '../../../core/models/data-map-field/data-map-field.model';
import cloneDeep from 'lodash-es/cloneDeep';
import differenceWith from 'lodash-es/differenceWith';
import isEqual from 'lodash-es/isEqual';
import { ReviewPhase } from '../../../core/models/admin-control/review-phase';
import { AdminControlCode } from '../../../core/enums/admin-control-code.enum';
import { AdminControlsFacade } from '../../admin-panel/state/admin-controls/admin-controls.facade';
import { Router } from '@angular/router';
import { CurrentUserFacade } from '../../user-profile/state/current-user.facade';
import { UserProfile } from '../../../core/models/user/user-profile.model';
import { DataMapFieldsService } from '../../../core/services/data-map-fields/data-map-fields.service';

@Component({
    selector: 'app-review-table-dialog',
    templateUrl: './review-table-dialog.component.html',
    styleUrls: ['./review-table-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewTableDialogComponent implements OnInit {
    private destroy$ = new Subject();

    public readonlySection$: Observable<boolean>;
    public user$: Observable<UserProfile>;
    public reviewPhases$: Observable<Array<ReviewPhase>>;
    public dataMapFields$: Observable<Array<DataMapField>>;
    public originalDataMapFields: Array<DataMapField>;
    public dataSource = new MatTableDataSource<DataMapField>();
    public displayedColumns = ['reviewType', 'field', 'comment'];
    public inputPlaceholder = AppConstants.INPUT_PLACEHOLDER;

    constructor(
        private store: Store<AppState>,
        private currentUserFacade: CurrentUserFacade,
        private dataMapFieldsFacade: DataMapFieldsFacade,
        private adminControlsFacade: AdminControlsFacade,
        private dataMapFieldsService: DataMapFieldsService,
        private router: Router,
        public dialogRef: MatDialogRef<ReviewTableDialogComponent>
    ) {}

    ngOnInit(): void {
        this.readonlySection$ = this.store.pipe(
            select(selectCurrentUser),
            map(
                (user) =>
                    !user.roles.some((role) =>
                        role.permissions.some((permission) => permission === Permissions.ReviewsAndQuestionsEdit)
                    )
            )
        );
        this.user$ = this.currentUserFacade.currentUser$;
        this.reviewPhases$ = this.adminControlsFacade.selectAdminControlDataByCode(AdminControlCode.ReviewPhase);
        this.dataMapFields$ = this.dataMapFieldsFacade.dataMapFields$;

        combineLatest([this.dataMapFields$, this.reviewPhases$])
            .pipe(
                tap(([dataMapFields, reviewPhases]) => {
                    this.dataSource.data = cloneDeep(
                        dataMapFields
                            .filter(
                                (item) =>
                                    item.reviews.length &&
                                    item.reviews.some((review) =>
                                        reviewPhases.some((reviewPhase) => reviewPhase.name === review)
                                    ) &&
                                    item.action !== 'No' &&
                                    item.action !== 'Blank'
                            )
                            .sort((a: DataMapField, b: DataMapField) => {
                                const reviewsA = a.reviews.join();
                                const reviewsB = b.reviews.join();
                                if (reviewsA < reviewsB) {
                                    return -1;
                                } else if (reviewsA > reviewsB) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            })
                    );
                    this.originalDataMapFields = cloneDeep(this.dataSource.filteredData);
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    public saveDataMapFields(): void {
        const itemsToUpdate = differenceWith(this.dataSource.data, this.originalDataMapFields, isEqual);
        this.dataMapFieldsFacade.updateDataMapFields(itemsToUpdate);
        this.dialogRef.close();
    }

    public navigateToDataMapFields(currentIntegrationId: number, dataMapFieldId: number): void {
        this.router.navigate(['/data-map-fields'], { queryParams: { integrationId: currentIntegrationId, dataMapFieldId } });
        this.close();
    }

    public close(): void {
        this.dialogRef.close();
    }
}
