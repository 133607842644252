import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { AdminControlsActions } from '../../../../core/state/action-types/action-types';
import { AdminControl } from '../../../../core/models/admin-control/admin-control.model';
import { AdminControlCode } from '../../../../core/enums/admin-control-code.enum';
import { AdminControlTypes } from '../../admin-panel.functions';
import {
    selectAdminControlByCode,
    selectAdminControlDataByCode,
    selectAdminControls,
    selectAdminControlValueByCode,
    selectLoaded,
    selectLoading
} from './admin-controls.selectors';

@Injectable({
    providedIn: 'root'
})
export class AdminControlsFacade {
    public adminControls$: Observable<Array<AdminControl>>;
    public loading$: Observable<boolean>;
    public loaded$: Observable<boolean>;

    constructor(private store: Store<AppState>) {
        this.adminControls$ = store.select(selectAdminControls);
        this.loading$ = this.store.select(selectLoading);
        this.loaded$ = this.store.select(selectLoaded);
        this.store.dispatch(AdminControlsActions.loadAdminControls());
    }

    public selectAdminControlDataByCode(adminControlCode: AdminControlCode): Observable<Array<AdminControlTypes>> {
        return this.store.select(selectAdminControlDataByCode(adminControlCode));
    }

    public selectAdminControlByCode(adminControlCode: AdminControlCode): Observable<AdminControl> {
        return this.store.select(selectAdminControlByCode(adminControlCode));
    }

    public selectAdminControlValueByCode(adminControlCode: AdminControlCode): Observable<string> {
        return this.store.select(selectAdminControlValueByCode(adminControlCode));
    }

    public upsertAdminControl(adminControl: AdminControl, showSnackbar: boolean = false): void {
        this.store.dispatch(AdminControlsActions.upsertAdminControl({ adminControl, showSnackbar }));
    }
}
