import { createAction, props } from '@ngrx/store';
import { Pattern } from '../../models/pattern/pattern.model';

export const loadPatterns = createAction('[Patterns Facade] Load Patterns');
export const loadPatternsSuccess = createAction('[Load Patterns Effect] Patterns Loaded', props<{ patterns: Array<Pattern> }>());

export const loadPatternByCode = createAction('[Patterns Facade] Load Pattern By Code', props<{ code: string }>());
export const loadPatternByCodeSuccess = createAction(
    '[Patterns Effect] Load Pattern By Code Success',
    props<{ pattern: Pattern }>()
);

export const updatePattern = createAction('[Patterns Facade] Update Pattern', props<{ pattern: Pattern }>());
export const updatePatternSuccess = createAction('[Patterns Effect] Update Pattern Success', props<{ pattern: Pattern }>());

export const createPattern = createAction('[Patterns Facade] Create Pattern', props<{ pattern: Pattern }>());
export const createPatternSuccess = createAction('[Patterns Effect] Create Pattern Success', props<{ pattern: Pattern }>());

export const deletePattern = createAction('[Patterns Facade] Delete Pattern', props<{ code: string }>());
export const deletePatternSuccess = createAction('[Patterns Effect] Delete Pattern Success', props<{ code: string }>());

export const updatePatternStatus = createAction('[Patterns Effects] Change status', props<{ patternCode: string }>());
