import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TeamMembersActions } from '../action-types/action-types';
import { TeamMember } from '../../models/team-member/team-member.model';

export const teamMembersFeatureKey = 'teamMembers';

export interface TeamMembersState extends EntityState<TeamMember> {
    loading: boolean;
    loaded: boolean;
    loadingById: boolean;
    loadedEntitiesById: Array<number>;
}

export const adapter = createEntityAdapter<TeamMember>();

export const initialTeamMembersState: TeamMembersState = adapter.getInitialState({
    loading: false,
    loaded: false,
    loadingById: false,
    loadedEntitiesById: []
});

export const teamMembersReducer = createReducer(
    initialTeamMembersState,
    on(TeamMembersActions.loadTeamMembers, (state) => {
        if (state.loaded) {
            return { ...state, loading: false };
        }
        return { ...state, loading: true };
    }),
    on(TeamMembersActions.loadTeamMembersSuccess, (state, action) => {
        return adapter.setAll(action.teamMembers, { ...state, loading: false, loaded: true });
    }),
    on(TeamMembersActions.loadTeamMemberById, (state, action) => {
        if (state.loadedEntitiesById.find((item) => item === action.id)) {
            return { ...state };
        }
        return { ...state, loadingById: true };
    }),
    on(TeamMembersActions.loadTeamMemberByIdSuccess, (state, action) =>
        adapter.setOne(action.teamMember, {
            ...state,
            loadedEntitiesById: [...state.loadedEntitiesById, action.teamMember.id],
            loadingById: false
        })
    ),
    on(TeamMembersActions.updateTeamMemberSuccess, (state, action) => {
        return adapter.upsertOne(action.teamMember, state);
    }),
    on(TeamMembersActions.createTeamMemberSuccess, (state, action) => {
        return adapter.upsertOne(action.teamMember, state);
    }),
    on(TeamMembersActions.deleteTeamMemberSuccess, (state, action) => adapter.removeOne(action.id, state))
);

export const { selectAll } = adapter.getSelectors();
