import { createAction, props } from '@ngrx/store';
import { QuestionMasterItem } from '../../../core/models/question-master/question-master-item.model';

export const loadQuestionMasterItems = createAction(
    '[Question Master Items Facade] Load Question Master Items',
    props<{ id: number }>()
);
export const loadQuestionMasterItemsSuccess = createAction(
    '[Question Master Items Effects] Load Question Master Items Success',
    props<{ questionMasterItems: Array<QuestionMasterItem>; questionMasterId: number }>()
);

export const loadQuestionMasterItemById = createAction(
    '[Question Master Items Facade] Load Question Master Item By Id',
    props<{ id: number }>()
);
export const loadQuestionMasterItemByIdSuccess = createAction(
    '[Question Master Items Effect] Load Question Master Item By Id Success',
    props<{ questionMasterItem: QuestionMasterItem }>()
);
