import { createAction, props } from '@ngrx/store';
import { ConnectorItem } from '../../../../core/models/connector/connector-item/connector-item.model';

export const loadConnectorItems = createAction('[Connector Items Facade] Load Connector Items', props<{ id: number }>());
export const loadConnectorItemsSuccess = createAction(
    '[Load Connector Items Effect] Load Connector Items Success',
    props<{ connectorItems: Array<ConnectorItem>; connectorId: number }>()
);

export const upsertConnectorItem = createAction(
    '[Connector Items Facade] Upsert Connector Item',
    props<{ connectorItem: ConnectorItem }>()
);
export const upsertConnectorItemSuccess = createAction(
    '[Connector Items Effect] Upsert Connector Item Success',
    props<{ connectorItem: ConnectorItem }>()
);

export const deleteConnectorItem = createAction(
    '[Connector Items Facade] Delete Connector Item',
    props<{ connectorItemId: number }>()
);
export const deleteConnectorItemSuccess = createAction(
    '[Connector items Effects] Delete Connector Item Success',
    props<{ connectorItemId: number }>()
);

export const updateConnectorItemStatus = createAction(
    '[Connector Items Effects] Change status',
    props<{ connectorItemId: number }>()
);
