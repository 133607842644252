import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appRequiredField]'
})
export class RequiredFieldDirective {
    private readonly requiredClassName = 'main-table__cell--required';

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    @HostListener('focusout', ['$event']) onFocusOut($event): void {
        const fieldValue = $event.target.value;
        const element = this.element.nativeElement;

        if (!fieldValue) {
            this.renderer.addClass(element, this.requiredClassName);
        } else {
            this.renderer.removeClass(element, this.requiredClassName);
        }
    }

    @HostListener('input', ['$event']) onInput($event): void {
        const fieldValue = $event.target.value;
        const element = this.element.nativeElement;

        if (fieldValue) {
            this.renderer.removeClass(element, this.requiredClassName);
        }
    }
}
