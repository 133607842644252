import { createAction, props } from '@ngrx/store';
import { Integration } from '../../../core/models/integration/integration.model';
import { MatchingResult } from '../../create-new-integration/matching-results/matching-result';

export const loadMatchingResults = createAction('[Integrations Facade] Load Matching Results');
export const loadMatchingResultsSuccess = createAction(
    '[Integrations Effect] Load Matching Results Success',
    props<{ matchingResults: Array<MatchingResult> }>()
);

export const loadIntegrations = createAction('[Integrations Facade] Load Integrations');
export const loadIntegrationsSuccess = createAction(
    '[Integrations Effect] Integrations Loaded',
    props<{ integrations: Array<Integration>; currentIntegrationId: number }>()
);

export const selectIntegration = createAction(
    '[Integrations Facade] Select Integration',
    props<{ userId: string; integrationId: number; dashboardRedirect: boolean }>()
);
export const selectIntegrationSuccess = createAction(
    '[Integrations Effect and User Effect] Select Integration Success',
    props<{ integrationId: number }>()
);

export const updateIntegration = createAction(
    '[Integrations Facade] Update Integration',
    props<{ integration: Integration; fillMissingMembers: boolean }>()
);
export const updateIntegrationSuccess = createAction(
    '[Integrations Effect] Update Integration Success',
    props<{ integration: Integration }>()
);

export const createIntegration = createAction(
    '[Integrations Facade] Create Integration',
    props<{
        integration: Integration;
        matchingResults: Array<MatchingResult>;
    }>()
);
export const createIntegrationSuccess = createAction(
    '[Integrations Effect] Create Integration Success',
    props<{ integration: Integration; matchingResults: Array<MatchingResult> }>()
);

export const deleteIntegration = createAction('[Integrations Facade] Delete Integration', props<{ id: number }>());
export const deleteIntegrationSuccess = createAction(
    '[Integrations Effect]  id: number  Integration Success',
    props<{ id: number }>()
);

export const updateIntegrationStatus = createAction('[Integrations Effects] Change status', props<{ integrationId: number }>());

export const runCloudNormalMatching = createAction(
    '[Integrations Facade] Run Cloud Normal Matching',
    props<{ createdIntegration: Integration; sourceApplicationFieldId: number; targetApplicationFieldId: number }>()
);

export const cloudNormalMatchingSuccess = createAction(
    '[Integrations Effect] Cloud Normal Matching Success',
    props<{ matchingResults: Array<MatchingResult> }>()
);
