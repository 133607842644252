import { createAction, props } from '@ngrx/store';
import { BusinessUnit } from '../../models/business-unit/business-unit.model';

export const loadBusinessUnits = createAction('[Business Units Facade] Load Business Units');

export const loadBusinessUnitsSuccess = createAction(
    '[Business Units Effect] Load Business Units Success',
    props<{ businessUnits: Array<BusinessUnit> }>()
);

export const loadBusinessUnitById = createAction('[Business Units Facade] Load Business Unit By Id', props<{ id: number }>());
export const loadBusinessUnitByIdSuccess = createAction(
    '[Business Units Effect] Load Business Unit By Id Success',
    props<{ businessUnit: BusinessUnit }>()
);

export const upsertBusinessUnit = createAction(
    '[Business Units Facade] Upsert Business Unit',
    props<{ businessUnit: BusinessUnit }>()
);
export const upsertBusinessUnitSuccess = createAction(
    '[Business Units Effect] Upsert Business Unit Success',
    props<{ businessUnit: BusinessUnit }>()
);

export const deleteBusinessUnit = createAction('[Business Units Facade] Delete Business Unit', props<{ id: number }>());
export const deleteBusinessUnitSuccess = createAction(
    '[Business Units Effect] Delete Business Unit Success',
    props<{ id: number }>()
);

export const updateBusinessUnitStatus = createAction(
    '[Business Units Effects] Change status',
    props<{ businessUnitId: number }>()
);
