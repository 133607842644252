import { createAction, props } from '@ngrx/store';
import { ConnectorItemCode } from 'src/app/core/models/connector/connector-item-code/connector-item-code.model';

export const loadConnectorItemCodes = createAction('[ConnectorItemCodes Facade] Load Connector Item Codes');
export const loadConnectorItemCodesSuccess = createAction(
    '[ConnectorItemCodes Effects] Load Connector Item Codes Success',
    props<{ connectorItemCodes: Array<ConnectorItemCode> }>()
);

export const createConnectorItemCode = createAction(
    '[ConnectorItemCodes Facade] Create Connector Item Code',
    props<{ connectorItemCode: ConnectorItemCode }>()
);
export const createConnectorItemCodeSuccess = createAction(
    '[ConnectorItemCodes Effects] Create Connector Item Code Success',
    props<{ connectorItemCode: ConnectorItemCode }>()
);

export const updateConnectorItemCode = createAction(
    '[ConnectorItemCodes Facade] Update Connector Item Code',
    props<{ connectorItemCode: ConnectorItemCode }>()
);
export const updateConnectorItemCodeSuccess = createAction(
    '[ConnectorItemCodes Effects] Update Connector Item Code Success',
    props<{ connectorItemCode: ConnectorItemCode }>()
);

export const deleteConnectorItemCode = createAction(
    '[ConnectorItemCodes Facade] Delete Connector Item Code',
    props<{ code: string }>()
);
export const deleteConnectorItemCodeSuccess = createAction(
    '[ConnectorItemCodes Effects] Delete Connector Item Code Success',
    props<{ code: string }>()
);

export const updateConnectorItemCodeStatus = createAction(
    '[ConnectorItemCodes Effects] Change Status',
    props<{ code: string }>()
);
